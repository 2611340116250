<template>
  <div class="wrapper">
    <div class="side-menu">
      <AppSideMenu />
    </div>
    <div class="page" id="page">
      <div class="top-menu">
        <AppTopMenu />
      </div>
      <div class="content">
        <router-view v-slot="{ Component }">
          <Transition name="d01" mode="out-in">
            <component :is="Component" />
          </Transition>
        </router-view>
      </div>
      <div class="player">
        <TheMusicPlayer />
      </div>

      <Transition name="slide-03">
        <TheChatCard />
      </Transition>
    </div>

    <Transition name="slide-left-03">
      <TheHelpCard />
    </Transition>
  </div>
</template>

<script>

import { initLk } from '@/modules/app/use/init-lk'
import { currency } from '@/modules/shared/utils/currency'
import { billingModel } from '@/modules/billing/model/billing'
import { userModel } from '@/modules/identity/model/user'
import { workspaceModel } from '@/modules/workspace/model/workspace'
import { WORKSPACE_LOADED } from '@/modules/workspace/event'
import { BILLING_LOADED } from '@/modules/billing/event'
import { useRouter } from 'vue-router'
import AppSideMenu from '@/modules/app/components/navigation/AppSideMenu'
import AppTopMenu from '@/modules/app/components/navigation/AppTopMenu'
import TheMusicPlayer from '@/modules/music/components/music/TheMusicPlayer'
import { useStore } from 'vuex'
import { useEventBus } from '@vueuse/core'
import { PLAYLIST_SELECTED } from '@/modules/playlist/event'
import { idsFromObject } from '@/modules/shared/utils/idsFromObject'
import TheHelpCard from '@/modules/help/components/card/TheHelpCard'
import TheChatCard from '@/modules/help/components/chat/TheChatCard'

export default {
  components: { TheChatCard, TheHelpCard, TheMusicPlayer, AppTopMenu, AppSideMenu },

  setup () {
    const store = useStore()
    const router = useRouter()
    const wsBus = useEventBus('workspace')
    const billingBus = useEventBus('billing')
    const plBus = useEventBus('playlist')
    initLk()

    wsBus.on((event) => {
      if (event.event === WORKSPACE_LOADED) {
        if (store.getters['music/selectedGenres'].length > 0 || store.getters['music/selectedTags'].length > 0) {
          store.dispatch('music/loadMusic', {
            isInit: true,
            genres: idsFromObject(store.getters['music/selectedGenres']),
            tags: idsFromObject(store.getters['music/selectedTags'])
          })
        }

        store.dispatch('video/loadVideo', { isInit: true })
        store.dispatch('video/loadVideoTags', {})

        store.dispatch('music/loadMusicTags', {})
        store.dispatch('music/loadMusicGenres', {})

        if (store.getters['sound/selectedCategories'].length > 0 || store.getters['sound/selectedTags'].length > 0) {
          store.dispatch('sound/loadSound', {
            categories: idsFromObject(store.getters['sound/selectedCategories']),
            tags: idsFromObject(store.getters['sound/selectedTags'])
          })
        }
        store.dispatch('sound/loadSoundTags', {})
        store.dispatch('sound/loadSoundCategories', {})

        store.dispatch('billing/loadDiscounts', {})

        store.dispatch('music/loadMusicArtists', {})
        store.dispatch('playlist/loadServiceMusicPlaylist', {})

        store.dispatch('playlist/loadFavoritePlaylist', {})
        store.dispatch('playlist/loadPlaylistGenres', {})
        // store.dispatch('playlist/loadPlaylistCategories', {})

        store.dispatch('playlist/loadServiceSoundPlaylist', {})
        store.dispatch('playlist/loadPlaylist')
        store.dispatch('playlist/loadPlaylistMusicTags', {})
        store.dispatch('playlist/loadPlaylistSoundTags', {})

        store.dispatch('music/loadMusicGenres', { isFavorite: true })
        store.dispatch('music/loadMusicTags', { isFavorite: true })

        store.dispatch('sound/loadSoundCategories', { isFavorite: true })
        store.dispatch('sound/loadSoundTags', { isFavorite: true })

        store.dispatch('help/loadTicketMessages', {})
        // store.dispatch('help/loadUserTickets', {})
      }
    })
    billingBus.on((event) => {
      if (event.event === BILLING_LOADED) {
        if (event.data.status === 'BLOCKED') {
          router.push({ name: 'subscriptionEnded' })
        }

        store.dispatch('billing/loadAccountPayments')
        store.dispatch('billing/loadAccountReferrals')
      }
    })
    plBus.on((event) => {
      if (event.event === PLAYLIST_SELECTED) {
        switch (event.data.playlist.audioType) {
          case 'MUSIC':
            store.dispatch('music/loadMusic', {
              isInit: false,
              playlistId: event.data.playlist.id,
              replace: event.data.replace
            })
            break
          case 'SOUND':
            store.dispatch('sound/loadSound', {
              playlistId: event.data.playlist.id, replace: event.data.replace
            })
            break
          case 'BOTH':
            store.dispatch('music/loadMusic', {
              isInit: false,
              playlistId: event.data.playlist.id,
              replace: event.data.replace
            })
            store.dispatch('sound/loadSound', {
              playlistId: event.data.playlist.id, replace: event.data.replace
            })
            break
        }
      }
    })

    return {
      currency,
      ...userModel(),
      ...workspaceModel(),
      ...billingModel()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.wrapper {
  display: grid;
  grid-template-columns: 24rem auto;
  overflow: hidden;
  max-height: 100vh;
  max-width: 100vw;
  height: 100vh;
  background-color: $dark-base-bg-2;
  .side-menu {
    z-index: 22;
    background-color: $side-menu;
  }
  .page {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 8.4rem 1fr 9rem;
    .top-menu {
      position: relative;
      grid-row: 1 / 1;
      z-index: 21;
    }
    .content {
      grid-row: 2 / 2;
      //padding: 0 4rem 0 4rem;
      //overflow: hidden;
      max-height: 82.6vh;
    }
    .player {
      position: relative;
      width: 100%;
      height: 9rem;
      z-index: 20;
      grid-row: 3 / 3;
    }
  }
}
</style>
