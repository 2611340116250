<template>
  <Transition name="d02">
    <div v-if="message" class="app-message">
      <div class="alert" :class="[message.type]">
        <img class="alert__icon" :src="defineIcon()" alt="">
        <p class="alert__content">{{ message.value}}</p>
      </div>
    </div>
  </Transition>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  setup () {
    const store = useStore()
    const TITLE_MAP = {
      success: 'Успешно',
      danger: 'Ошибка',
      warning: 'Внимание'
    }

    const message = computed(() => store.getters['notification/getMessage'])
    const title = computed(() => message.value.title ? message.value.title : TITLE_MAP[message.value.type])

    return {
      message,
      title,
      close: () => store.commit('notification/clearMessage'),
      defineIcon: () => {
        switch (message.value.type) {
          case 'danger': {
            return require('@/assets/img/icon/ic_close_danger.svg')
          }
          case 'success': {
            return require('@/assets/img/icon/ic-check-wh.svg')
          }
          case 'warning': {
            return require('@/assets/img/icon/ic-check-wh.svg')
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .alert {
    position: absolute;
    top: 8rem;
    right: 4rem;
    display: flex;
    align-items: center;
    border-radius: $radius;
    background-color: $selected_dd;
    padding: 1.5rem 2rem;
    max-width: 31.8rem;
    min-width: 22.9rem;
    box-shadow: $shadow;
    z-index: 101;
    &__icon {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1.5rem;
    }
    &__title {
      font-size: 1.6rem;
      font-weight: 700;
      color: $white-text;
    }
    &__content {
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: $white-text;
      font-weight: 500;
    }
    &__close {
      position: absolute;
      top: .5rem;
      right: 1.5rem;
      font-size: 2rem;
      cursor: pointer;
      font-weight: 700;

      &:hover {
        opacity: .8;
      }

      &:active {
        opacity: .6;
      }
    }
    &.success {
      .alert__title {
        color: $white-text;
      }
      .alert__content {
        color: $white-text;
      }
      .alert__close {
        color: $white-text;
      }
    }
    &.danger {
      .alert__title {
        color: $error;
      }
      .alert__content {
        color: $error;
      }
      .alert__close {
        color: $error;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .app-message {
      .alert {
        top: 3rem;
        right: 0;
        max-width: 90%;
        min-width: 90%;
        margin: 0 2rem;
      }
    }
  }

</style>
