<template>
  <div class="block-first">
    <div class="block-info">
      <div class="page-title"><span>Zenplace</span> - создай своё уютное звуковое пространство.
      </div>
      <div class="advantages">
        <div class="advantages__item">
          <img src="@/assets/img/icon/billing/ic_music.svg" alt="">
          <div class="text">Библиотека душевной музыки и уникальные плейлисты.</div>
        </div>
        <div class="advantages__item">
          <img src="@/assets/img/icon/billing/ic_sound.svg" alt="">
          <div class="text">Качественные звуковые эффекты - смешивай их с музыкой.</div>
        </div>
        <div class="advantages__item">
          <img src="@/assets/img/icon/billing/ic_scene.svg" alt="">
          <div class="text">Атмосферные аудио-визуальные сцены и незабываемый опыт.</div>
        </div>
      </div>
      <div class="call-to-action">
        Получите 3 дня бесплатного доступа без привязки карты.
      </div>
      <div class="btn-block flex mt-3">
        <app-button view="primary" textColor="white" @action="signUp">Зарегистрироваться</app-button>
        <app-button class="ml-2" view="outline" textColor="primary" @action="signIn">Войти в аккаунт</app-button>
      </div>
    </div>
    <div class="block-first__img">
      <div class="shape_first">
        <img src="../../../assets/img/landing/landing_first.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/ui/button/AppButton'
import { useRouter } from 'vue-router'
export default {
  components: { AppButton },

  setup () {
    const router = useRouter()

    return {
      signIn: () => router.push({ name: 'signIn' }),
      signUp: () => router.push({ name: 'signUp' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .block-first {
    background-color: $selected_dd;
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    .block-info {
      align-self: center;
      position: relative;
      width: 100%;
      margin-left: 15.3rem;
      & .page-title {
        width: 70rem;
        color: $white;
        font-size: 5.8rem;
        font-weight: 700;
        line-height: 7rem;
        span {
          color: $primary;
        }
      }
      & .sub-title {
        width: 66.3rem;
        margin-top: 5rem;
        color: $white;
        font-size: 2.2rem;
        font-weight: 600;
      }
      & .advantages {
        margin-top: 3.5rem;
        display: flex;
        flex-direction: column;
        gap: 2.2rem;
        max-width: 64rem;
        &__item {
          display: flex;
          gap: 1rem;
          align-items: center;
          img {
            width: 3.2rem;
            height: 3.2rem;
          }
          & .text {
            color: $white-80;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .call-to-action {
        margin-top: 5rem;
        color: $white-40;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: normal;
      }
    }
    .block-first__img {
      position: relative;
      width: 100%;
      z-index: 2;
      .shape_first {
        position: absolute;
        top: -13rem;
        right: -30rem;
        //transform: translateX(64.2rem);
        z-index: 2;
        background-color: transparent;
        border-radius: 50%;
        width: 128.5rem;
        height: 128.5rem;
        overflow: hidden;
        img {
          position: absolute;
          left: 0;
          top: 63rem;
          transform: translate(0, -50%);
          width: 102rem;
          height: 102rem;
          object-fit: cover;
          z-index: 1;
          //margin-left: 14.3rem;
        }
      }
    }
  }

</style>
