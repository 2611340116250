import ApiClient from '@/modules/shared/utils/api'
import { useEventBus } from '@vueuse/core'
import { VIDEO_LOADED, VIDEO_RELOAD, VIDEO_SELECTED } from '@/modules/video/event'
import { video } from '@/modules/video/repository/video'
import { shuffle } from '@/modules/shared/utils/shuffle'

const bus = useEventBus('video')

const VIDEO_QUALITY = 'video-quality'

export default {
  namespaced: true,
  state: {
    activeVideo: {
      video: null,
      isPlaying: false
    },
    videos: [
    ],
    videoTags: [
    ],
    selectedTags: [],
    quality: JSON.parse(localStorage.getItem(VIDEO_QUALITY)) || 1080,
    availableQuality: [
      { id: 2, quality: 1080 },
      { id: 1, quality: 720 },
      { id: 0, quality: 480 }
    ]
  },
  getters: {
    videos (state) {
      return shuffle(state.videos)
    },
    activeVideo (state) {
      return state.activeVideo
    },
    videoTags (state) {
      return state.videoTags
    },
    selectedTags (state) {
      return state.selectedTags
    },
    videoQuality (state) {
      return state.quality
    },
    availableQuality (state) {
      return state.availableQuality
    }
  },
  mutations: {
    setVideos (state, payload) {
      state.videos = payload
    },
    setVideoTags (state, payload) {
      state.videoTags = payload
    },
    setActiveVideo (state, payload) {
      state.activeVideo.video = payload.video
      if (payload.isInit) {
        state.activeVideo.video.isPlaying = false
        bus.emit({ event: VIDEO_RELOAD })
      } else {
        state.activeVideo.video.isPlaying = true
        bus.emit({ event: VIDEO_SELECTED })
      }
    },
    setActiveVideoPlaying (state) {
      state.activeVideo.video.isPlaying = true
    },
    setActiveVideoStop (state) {
      state.activeVideo.video.isPlaying = false
    },
    addTag (state, tag) {
      if (state.selectedTags.find((item) => item.id === tag.id)) {
        state.selectedTags.splice(state.selectedTags.indexOf(tag), 1)
        return
      }
      state.selectedTags.push(tag)
    },
    setVideoQuality (state, quality) {
      state.quality = quality.quality
      localStorage.setItem(VIDEO_QUALITY, quality.quality)
    }
  },
  actions: {
    async loadVideo ({ state, commit, dispatch }, payload) {
      try {
        const params = {
          search: payload.search,
          sorting: 'name',
          direction: 'ASC',
          statuses: 'ACTIVE',
          tags: state.selectedTags.map(tag => tag.id).join(',') || null
        }
        const { data } = await ApiClient.get(video.videos(), { params })
        commit('setVideos', data.data)
        setTimeout(() => {
          if (payload.isInit) {
            commit('setActiveVideo', { video: data.data[0], isInit: true })
          }
        }, 100)
        bus.emit({ event: VIDEO_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadVideoTags ({ commit, dispatch }, payload) {
      try {
        const params = {
          locale: 'ru_RU'
        }
        const { data } = await ApiClient.get(video.tags(), { params })
        commit('setVideoTags', data.data)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadFavoriteVideo ({ commit, dispatch }, payload) {
      try {
        const params = {
          statuses: 'ACTIVE'
        }
        const { data } = await ApiClient.get(video.favorites(), { params })
        commit('setVideos', data.data)
        bus.emit({ event: VIDEO_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async addToFavorite ({ state, commit, dispatch }, payload) {
      try {
        const { data } = await ApiClient.post(video.addToFavorite(payload.video.id), payload)
        state.activeVideo.video.favoriteId = data.data.id
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async removeFromFavorite ({ state, commit, dispatch }, payload) {
      try {
        await ApiClient.delete(video.removeFromFavorite(payload.video.favoriteId))
        state.activeVideo.video.favoriteId = null
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async saveVideoHistory ({ commit, dispatch }, payload) {
      try {
        if (payload.duration <= 5 || payload.duration > 7200) {
          return
        }
        const body = {
          duration: payload.duration
        }
        await ApiClient.post(video.history(payload.video.id), body)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  }
}
