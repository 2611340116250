<template>
  <div class="rating" :class="{ selected: isSelected }">
    <div
      v-for="i in 5"
      :key="i"
      :data-id="i"
      class="star-icon"
      :class="[{ active: activeIndex >= i}, { selected: isSelected && activeIndex >= i }]"
      @mouseenter="defineIcon(i)"
      @mouseleave="clearStar()"
      @mousedown="selectRating()"
    ></div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['selected'],
  setup (_, { emit }) {
    const activeIndex = ref(null)
    const isSelected = ref(false)
    return {
      isSelected,
      activeIndex,
      defineIcon: (position) => {
        activeIndex.value = position
      },
      clearStar: () => {
        if (!isSelected.value) {
          activeIndex.value = null
        }
      },
      selectRating: () => {
        isSelected.value = true
        emit('selected', activeIndex.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .rating {
    display: flex;
    align-items: center;
    gap: 2rem;
    .star-icon {
      width: 3rem;
      height: 3rem;
      transition: all ease-out .2s;
      background-image: url("@/assets/img/icon/chat/ic_chat_star_empty.svg");
      background-repeat: no-repeat;
      background-size: cover;
      &:hover {
        cursor: pointer;
        background-image: url("@/assets/img/icon/chat/ic_chat_star_active.svg");
      }
      &.active {
        background-image: url("@/assets/img/icon/chat/ic_chat_star_active.svg");
      }
      &.selected {
        background-image: url("@/assets/img/icon/chat/ic_chat_star_full.svg");
      }
    }
    &.selected {
      pointer-events: none;
      user-select: none;
    }
  }
</style>
