<template>
  <div class="modal">
    <div
        ref="card"
        class="card"
        :style="{ width: mWidth+'rem', height: mHeight+'rem' }"
    >
      <div class="card__title">{{ mTitle }}
        <span @click="$emit('close')" style="height: 2.4rem; width: 2.4rem" class="card__close"/>
      </div>
      <div class="card__body">
        <video
          class="video"
          loop
          ref="video"
          controls
        >
          <source
            :src="mVideoUrl"
            type="video/mp4"
          >
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
  emits: ['close'],
  props: ['title', 'width', 'height', 'videoUrl'],
  setup (props, { emit }) {
    const mTitle = ref(props.title)
    const mWidth = ref(props.width)
    const mHeight = ref(props.height)
    const mVideoUrl = ref(props.videoUrl)

    const card = ref(null)
    const video = ref(null)

    setTimeout(() => {
      video.value.play()
      video.value.controls = true
    }, 200)

    onClickOutside(
      card,
      (event) => {
        setTimeout(() => {
          emit('close')
        }, 10)
      }
    )

    return {
      video,
      mVideoUrl,
      card,
      mTitle,
      mWidth,
      mHeight
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 39, 0.80);
    z-index: 100;
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: transparent;
      border-radius: $radius-20;
      box-shadow: $shadow;
      overflow: hidden;
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;
        font-size: 1.8rem;
        font-weight: 700;
      }
      &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background-image: url("@/assets/img/icon/ic_close_gray.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 1.8rem;
        height: 1.8rem;
        z-index: 103;
        @include default-hover-active;
      }
      & .video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 102;
      }
    }
  }
</style>
