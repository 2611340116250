<template>
  <div class="field">
    <label for="">{{ inputLabel }}</label>
    <div class="input-icon">
      <textarea
        ref="input"
        :placeholder="inputPlaceholder"
        v-model.trim="inputModel"
        :class="classNames"
        :disabled="inputDisabled"
        :rows="inputRows"
      ></textarea>
      <div v-if="isEdit">
        <img v-if="inputDisabled" @click.stop="edit()" src="@/assets/img/icon/ic_edit.svg" alt="">
        <img v-else @click.stop="save()" src="@/assets/img/icon/ic-check-wh.svg" alt="">
      </div>
    </div>
    <span
      v-if="inputError||inputErrorLocal"
      class="error"
      :style="{ top: inputErrorMt + 'rem' }"
    > {{ inputError||inputErrorLocal }}</span>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'

export default {
  emits: ['update:modelValue', 'edit', 'save'],
  props: ['modelValue', 'value', 'rows', 'placeholder', 'label', 'error', 'width', 'isDisabled', 'isEdit', 'errorMt'],
  setup (props, { emit }) {
    const inputModel = ref(props.modelValue)
    const inputPlaceholder = ref(props.placeholder || '')
    const inputLabel = ref(props.label || '')
    const inputDisabled = ref(props.isDisabled || false)
    const inputIsEdit = ref(props.isEdit || false)
    const inputValue = ref(props.value || '')
    const inputRows = ref(props.rows || '5')
    const inputError = computed(() => props.error || '')
    const inputErrorLocal = ref('')
    const inputWidth = ref(props.width || 'w34')
    const inputErrorMt = ref(props.errorMt || '8.5')
    const input = ref()

    watch(inputModel, (value) => {
      emit('update:modelValue', value.trim())
      if (value.length > 0) {
        inputErrorLocal.value = ''
      }
    })

    return {
      inputErrorMt,
      input,
      inputIsEdit,
      inputValue,
      inputDisabled,
      inputLabel,
      inputPlaceholder,
      inputRows,
      inputError,
      inputErrorLocal,
      inputModel,
      classNames: `${inputWidth.value}`,
      edit: () => {
        inputDisabled.value = false
        setTimeout(() => {
          input.value.focus()
        }, 50)
      },
      save: () => {
        if (inputModel.value !== '') {
          inputDisabled.value = true
          inputErrorLocal.value = ''
          emit('save')
        } else {
          setTimeout(() => {
            input.value.focus()
          }, 50)
          inputErrorLocal.value = 'Поле не может быть пустым'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.field {
  position: relative;
  label {
    color: $second-text-2;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.4rem;
  }
  .input-icon {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    textarea {
      resize: none;
      margin-top: .5rem;
      position: relative;
      min-height: 5rem;
      background-color: $card;
      border: none;
      width: 34rem;
      border-radius: $radius;
      font-size: 1.4rem;
      font-weight: 500;
      color: $white;
      letter-spacing: 0;
      outline: none;
      padding: 1rem 2rem;
      line-height: 2.4rem;
      transition: all .3s ease-out;
      &::placeholder {
        color: $second-text-2;
      }
      &:focus {
        //border: 0.1rem solid $input_active_border;
        box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.10);
        transition: all .3s ease-out;
      }
      &:active {
        //border: 0.2rem solid $input_active_border;
        box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.10);
        transition: all .3s ease-out;
      }
      &:disabled {
        opacity: .7;
      }
      &.w34 {
        width: 34rem;
      }
      &.w100 {
        width: 100%;
      }
    }
    img {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate(-50%, -50%);
      width: 1.8rem;
      height: 1.8rem;
      @include default-hover-active;
    }
  }
  .error {
    position: absolute;
    top: 8.5rem;
    left: .2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: $error-text;
  }
}

@media screen and (max-width: 480px) {
  input {
    width: 100%;
  }
}
</style>
