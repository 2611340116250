<template>
  <div @click.stop>
    <span @click.stop="isShow = !isShow">CC</span>
    <Transition name="d02">
      <div v-if="isShow" class="credits">
        <div class="credits__title">Информация о композиции</div>
        <div class="credits__content"> {{ audioCredits }}</div>
        <img @click.stop="isShow = false" class="credits__close" src="@/assets/img/icon/ic_close_gray.svg" alt=""/>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['action'],
  props: ['credits'],
  setup (props) {
    const isShow = ref(false)
    const audioCredits = ref(props.credits)

    return {
      audioCredits,
      isShow,
      defineIcon: () => {
        if (!isShow.value) {
          return require('@/assets/img/icon/player/ic_info.svg')
        } else {
          return require('@/assets/img/icon/player/ic_info_active.svg')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  span {
    background-color: $selected_dd;
    padding: .7rem .6rem .5rem .6rem;
    border-radius: .4rem;
    margin-left: 0.4rem;
    font-size: .8rem;
    color: $white-90;
    font-weight: 800;
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.7;
    }
  }
  .credits {
    position: absolute;
    bottom: 10rem;
    left: 4rem;
    padding: 2rem 2rem 1.7rem 2rem;
    width: 39.1rem;
    height: auto;
    background-color: $selected_dd;
    border-radius: $radius-20;
    box-shadow: $shadow;
    z-index: 1;
    &__title {
      color: $white-text;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    &__content {
      margin-top: 2rem;
      color: $white-80;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.8rem;
      white-space: break-spaces;
    }
    &__close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      &:hover {
        cursor: pointer;
        opacity: .7;
      }
      &:active {
        cursor: pointer;
        opacity: .5;
      }
    }
    &:hover {
      cursor: default;
      user-select: text;
    }
  }
</style>
