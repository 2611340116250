<template>
  <div class="wrapper">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .wrapper {
    min-width: 100%;
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      padding: 3rem;
    }
  }
</style>
