import moment from 'moment'

export function formatDate (date, format, timezone) {
  if (!format) {
    format = 'DD.MM.YYYY'
  }
  if (timezone) {
    return moment.utc(date).utcOffset(parseInt(timezone ?? 0)).format(format)
  } else {
    return moment(date).format(format)
  }
}
