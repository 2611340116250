<template>
  <div class="relative">
    <input
      :type="inputType"
      :placeholder="inputPlaceholder"
      v-model.trim="inputModel"
      :class="classNames"
    >
    <label class="float" for="">{{ inputPlaceholder }}</label>
    <img @click="$emit('approve')" src="@/assets/img/icon/ic_approve.svg" alt=""/>
    <span v-if="inputError" class="error"> {{ inputError }}</span>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'

export default {
  emits: ['update:modelValue', 'approve'],
  props: ['modelValue', 'type', 'placeholder', 'error', 'width', 'icon'],
  setup (props, { emit }) {
    const inputModel = ref()
    const inputPlaceholder = ref(props.placeholder || '')
    const inputType = ref(props.type || 'text')
    const inputError = computed(() => props.error || '')
    const inputWidth = ref(props.width || 'w34')

    watch(inputModel, (value) => {
      emit('update:modelValue', value.trim())
    })

    return {
      inputPlaceholder,
      inputType,
      inputError,
      inputModel,
      classNames: `${inputWidth.value}`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  label.float {
    opacity: 0;
    position: absolute;
    top: 0.3rem;
    left: 2rem;
    z-index: 2;
    font-size: .8rem;
    color: $light_gray;
    font-weight: 500;
    transition: all .3s ease-out;
  }
  input {
    position: relative;
    min-height: 4.5rem;
    background-color: $card;
    border: none;
    width: 34rem;
    border-radius: $radius;
    font-size: 1.6rem;
    font-weight: 500;
    color: $white;
    letter-spacing: 0;
    outline: none;
    padding: 0 5rem 0 2rem;
    line-height: 2.4rem;
    transition: all .3s ease-out;
    &::placeholder {
      color: $input_hint;
    }
    &:focus {
      border: none;
      transition: all .3s ease-out;
    }
    &:active {
      border: none;
      transition: all .3s ease-out;
    }
    &:placeholder-shown+label.float {
      opacity: 0;
      font-size: 14px;
      top: 3rem;
      transition: all .3s ease-out;
      z-index: 0;
    }
    &+label.float {
      opacity: 1;
      transition: all .3s ease-out;
      z-index: 2;
    }
    &:disabled {
      opacity: .7;
    }
    &.w34 {
      width: 34rem;
    }
    &.w20 {
      width: 20rem;
    }
    &.w100 {
      width: 100%;
    }
  }
  img {
    -webkit-user-drag: none;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    &:hover {
      cursor: pointer;
      opacity: .7;
    }
    &:active {
      cursor: pointer;
      opacity: .5;
    }
  }
  .error {
    position: absolute;
    top: 5.5rem;
    left: .2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: $error-text;
  }

  @media screen and (max-width: 480px) {
    input {
      width: 100%;
    }
  }
</style>
