import { useEventBus } from '@vueuse/core'
import {
  MASTER_VOLUME_CHANGED, TRACK_ADDED_TO_FAVORITE,
  TRACK_CHANGED, TRACK_INIT,
  TRACK_PAUSED,
  TRACK_PLAY, TRACK_REMOVED_FROM_FAVORITE, TRACK_RESUMED,
  TRACK_STOP,
  TRACK_VOLUME_CHANGED,
  TRACKS_LOADED
} from '@/modules/music/event'
import ApiClient from '@/modules/shared/utils/api'
import { music } from '@/modules/music/repository/music'
import { shuffle } from '@/modules/shared/utils/shuffle'
const bus = useEventBus('music')

const MASTER_VOLUME_KEY = 'master-volume'
const MUSIC_VOLUME_KEY = 'music-volume'
const MUSIC_LOOP_KEY = 'music-loop'
const MUSIC_SHUFFLE_KEY = 'music-shuffle'
const MUSIC_SELECTED_GENRES = 'music-selected-genres'
const MUSIC_SELECTED_TAGS = 'music-selected-tags'

export default {
  namespaced: true,
  state: {
    activeTrack: { track: null, isPlaying: false, isLoading: false },
    favoriteTracks: [],
    tracks: [],
    artists: [],
    tags: [],
    genres: [],
    favTags: [],
    favGenres: [],
    musicVolume: parseFloat(localStorage.getItem(MUSIC_VOLUME_KEY) || 0.5),
    masterVolume: parseFloat(localStorage.getItem(MASTER_VOLUME_KEY) || 0.5),
    loop: JSON.parse(localStorage.getItem(MUSIC_LOOP_KEY)) || false,
    shuffle: JSON.parse(localStorage.getItem(MUSIC_SHUFFLE_KEY)) || false,
    mute: false,
    selectedGenres: JSON.parse(localStorage.getItem(MUSIC_SELECTED_GENRES)) || [],
    selectedTags: JSON.parse(localStorage.getItem(MUSIC_SELECTED_TAGS)) || [],
    selectedFavGenres: [],
    selectedFavTags: [],
    lastGenre: null,
    lastMusicSoundsTab: null
  },
  getters: {
    isLoop (state) {
      return state.loop
    },
    isShuffle (state) {
      return state.shuffle
    },
    isMuted (state) {
      return state.mute
    },
    tracks (state) {
      return shuffle(state.tracks)
    },
    activeTrack (state) {
      return state.activeTrack
    },
    tags (state) {
      return state.tags
    },
    genres (state) {
      return state.genres
    },
    favTags (state) {
      return state.favTags
    },
    favGenres (state) {
      return state.favGenres
    },
    artists (state) {
      return shuffle(state.artists)
    },
    favoriteTracks (state) {
      return state.favoriteTracks
    },
    isFavorite (state) {
      return (track) => state.favoriteTracks.filter((item) => item.id === track.id).length > 0
    },
    musicLoop (state) {
      return state.loop
    },
    musicVolume (state) {
      return parseFloat(state.musicVolume)
    },
    masterVolume (state) {
      return parseFloat(state.masterVolume)
    },
    selectedGenres (state) {
      return state.selectedGenres
    },
    selectedTags (state) {
      return state.selectedTags
    },
    selectedFavGenres (state) {
      return state.selectedFavGenres
    },
    selectedFavTags (state) {
      return state.selectedFavTags
    },
    lastGenre (state) {
      return state.lastGenre
    },
    lastMusicSoundsTab (state) {
      return state.lastMusicSoundsTab
    }
  },
  mutations: {
    setMusicLoop (state) {
      state.loop = !state.loop
      localStorage.setItem(MUSIC_LOOP_KEY, state.loop ? 'true' : 'false')
    },
    setMusicShuffle (state) {
      state.shuffle = !state.shuffle
      localStorage.setItem(MUSIC_SHUFFLE_KEY, state.shuffle ? 'true' : 'false')
    },
    setMuted (state) {
      state.mute = !state.mute
    },
    setMusicTracks (state, payload) {
      state.tracks = payload
    },
    setMusicTags (state, payload) {
      state.tags = payload
    },
    setMusicFavoriteTags (state, payload) {
      state.favTags = payload
    },
    setMusicArtist (state, payload) {
      state.artists = payload
    },
    shuffleMusicArtists (state) {
      state.artists = shuffle(state.artists)
    },
    setMusicGenres (state, payload) {
      state.genres = payload
    },
    setMusicFavoriteGenres (state, payload) {
      state.favGenres = payload
    },
    setActiveTrack (state, payload) {
      const track = payload.track
      if (payload.isInit) {
        state.activeTrack.track = track
        bus.emit({ event: TRACK_INIT })
      } else {
        if (state.activeTrack.track) {
          if (state.activeTrack.track.id === track.id) {
            state.activeTrack.isPlaying = !state.activeTrack.isPlaying
            if (state.activeTrack.isPlaying) {
              bus.emit({ event: TRACK_PLAY })
            } else {
              bus.emit({ event: TRACK_PAUSED })
            }
            return
          }
        }
        state.activeTrack.track = track
        state.activeTrack.isPlaying = true
        bus.emit({ event: TRACK_CHANGED })
      }
    },
    setTrackVolume (state, volume) {
      state.musicVolume = volume
      bus.emit({ event: TRACK_VOLUME_CHANGED, data: volume })
      localStorage.setItem(MUSIC_VOLUME_KEY, volume)
    },
    setMasterVolume (state, volume) {
      state.masterVolume = volume
      if (state.masterVolume < 0) {
        state.masterVolume = 0
      }
      if (state.masterVolume > 1) {
        state.masterVolume = 1
      }
      bus.emit({ event: MASTER_VOLUME_CHANGED, data: state.masterVolume })
      localStorage.setItem(MASTER_VOLUME_KEY, state.masterVolume)
    },
    setActiveTrackResume (state) {
      state.activeTrack.isPlaying = true
      bus.emit({ event: TRACK_RESUMED })
    },
    setActiveTrackPlaying (state) {
      state.activeTrack.isPlaying = true
    },
    setActiveTrackPause (state) {
      state.activeTrack.isPlaying = false
      bus.emit({ event: TRACK_PAUSED })
    },
    setActiveTrackStop (state) {
      state.activeTrack.isPlaying = false
      bus.emit({ event: TRACK_STOP })
    },
    setActiveTrackUnload (state) {
      state.activeTrack = { track: null, isPlaying: false, isLoading: false }
    },
    setActiveTrackLoading (state) {
      state.activeTrack.isLoading = true
    },
    setActiveTrackLoaded (state) {
      state.activeTrack.isLoading = false
    },
    setSelectedGenres (state, payload) {
      state.selectedGenres = payload
      localStorage.setItem(MUSIC_SELECTED_GENRES, JSON.stringify(payload))
    },
    setSelectedTags (state, payload) {
      state.selectedTags = payload
      localStorage.setItem(MUSIC_SELECTED_TAGS, JSON.stringify(payload))
    },
    addGenre (state, genre) {
      if (state.selectedGenres.find((item) => item.id === genre.id)) {
        state.selectedGenres.splice(state.selectedGenres.indexOf(genre), 1)
        localStorage.setItem(MUSIC_SELECTED_GENRES, JSON.stringify(state.selectedGenres))
        return
      }
      state.selectedGenres.push(genre)
      localStorage.setItem(MUSIC_SELECTED_GENRES, JSON.stringify(state.selectedGenres))
    },
    addTag (state, tag) {
      if (state.selectedTags.find((item) => item.id === tag.id)) {
        state.selectedTags.splice(state.selectedTags.indexOf(tag), 1)
        localStorage.setItem(MUSIC_SELECTED_TAGS, JSON.stringify(state.selectedTags))
        return
      }
      state.selectedTags.push(tag)
      localStorage.setItem(MUSIC_SELECTED_TAGS, JSON.stringify(state.selectedTags))
    },
    addFavGenre (state, genre) {
      if (state.selectedFavGenres.find((item) => item.id === genre.id)) {
        state.selectedFavGenres.splice(state.selectedFavGenres.indexOf(genre), 1)
        return
      }
      state.selectedFavGenres.push(genre)
    },
    addFavTag (state, tag) {
      if (state.selectedFavTags.find((item) => item.id === tag.id)) {
        state.selectedFavTags.splice(state.selectedFavTags.indexOf(tag), 1)
        return
      }
      state.selectedFavTags.push(tag)
    },
    setSelectedFavGenres (state, payload) {
      state.selectedFavGenres = payload
    },
    setSelectedFavTags (state, payload) {
      state.selectedFavTags = payload
    },
    setLastGenre (state, payload) {
      state.lastGenre = payload
    },
    setLastMusicSoundsTab (state, payload) {
      state.lastMusicSoundsTab = payload
    }
  },
  actions: {
    async loadMusicArtists ({ state, commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          type: 'MUSIC',
          tags: payload.tags ? payload.tags.toString() : null,
          genres: payload.genres ? payload.genres.toString() : null
        }
        const { data } = await ApiClient.get(music.artists(), { params })
        commit('setMusicArtist', data.data)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadMusicTags ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          type: 'MUSIC',
          locale: 'ru_RU',
          genres: payload.genres ? payload.genres.toString() : null,
          playlistId: payload.playlistId ? payload.playlistId : null,
          isFavorite: payload.isFavorite
        }
        const { data } = await ApiClient.get(music.tags(), { params })
        if (payload.isFavorite) {
          commit('setMusicFavoriteTags', data.data)
        } else {
          commit('setMusicTags', data.data)
        }
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadMusicGenres ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          isFavorite: payload.isFavorite
        }
        const { data } = await ApiClient.get(music.genres(), { params })
        if (payload.isFavorite) {
          commit('setMusicFavoriteGenres', data.data)
        } else {
          commit('setMusicGenres', data.data)
        }
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadMusic ({ state, commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          statuses: 'ACTIVE',
          search: payload.search,
          tags: payload.tags ? payload.tags.toString() : null,
          genres: payload.genres ? payload.genres.toString() : null,
          artists: payload.artists ? payload.artists.toString() : null,
          playlistId: payload.playlistId ? payload.playlistId : null
        }
        const { data } = await ApiClient.get(music.music(), { params })
        if (payload.playlistId && !payload.replace) {
          if (data.data.length === 0) {
            return
          }
        }
        commit('setMusicTracks', data.data)
        setTimeout(() => {
          if (payload.isInit) {
            commit('setActiveTrack', { track: data.data[0], isInit: true })
          }
        }, 100)
        bus.emit({ event: TRACKS_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadFavoriteMusic ({ commit, dispatch }, payload) {
      try {
        const params = {
          statuses: 'ACTIVE',
          search: payload.search,
          tags: payload.tags ? payload.tags.toString() : null,
          genres: payload.genres ? payload.genres.toString() : null
        }
        const { data } = await ApiClient.get(music.favorites(), { params })
        commit('setMusicTracks', data.data)
        bus.emit({ event: TRACKS_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async addToFavorite ({ commit, dispatch }, track) {
      try {
        const payload = {
          audioType: 'MUSIC'
        }
        const { data } = await ApiClient.post(music.addToFavorite(track.id), payload)
        track.favoriteId = data.data.id
        bus.emit({ event: TRACK_ADDED_TO_FAVORITE })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async removeFromFavorite ({ commit, dispatch }, track) {
      try {
        await ApiClient.delete(music.removeFromFavorite(track.favoriteId))
        track.favoriteId = null
        bus.emit({ event: TRACK_REMOVED_FROM_FAVORITE })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async saveMusicHistory ({ commit, dispatch }, payload) {
      try {
        if (payload.duration <= 5 || payload.duration > 420) {
          return
        }
        const body = {
          audioType: 'MUSIC',
          duration: payload.duration
        }
        await ApiClient.post(music.history(payload.track.id), body)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  }
}
