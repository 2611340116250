<template>
  <div class="page-wrapper">
    <div class="top-menu">
      <div class="top-menu__list">
        <ul v-for="item in menuList" :key="item.id">
          <li
            @click="choose(item)"
            :class="{ active: item.active }"
            v-if="item.name !== 'Любимое'"
          ><span :class="{ active: item.active }"></span>{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div v-if="activeTab.id === 0">
      {{ activeTab.name }}
    </div>
    <div v-if="activeTab.id === 1">
      {{ activeTab.name }}
    </div>
    <div v-if="activeTab.id === 2">
      {{ activeTab.name }}
    </div>
    <div v-if="activeTab.id === 3">
      {{ activeTab.name }}
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'

export default {
  setup () {
    const menuList = [
      { id: 0, name: 'Видео', active: true, alias: 'video' },
      { id: 1, name: 'Музыка', active: false, alias: 'music' },
      { id: 2, name: 'Звуки', active: false, alias: 'sound' },
      { id: 3, name: 'Сцены', active: false, alias: 'scene' }
    ]
    const activeTab = ref(menuList[0])

    return {
      activeTab,
      menuList,
      choose: (item) => {
        activeTab.value.active = false
        item.active = true
        activeTab.value = item
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .page-wrapper {
    .top-menu {
      position: absolute;
      top: 5.2rem;
      left: 40rem;
      .top-menu__list {
        display: flex;
        list-style: none;
        gap: 2.5rem;
        margin-left: 8rem;
        //margin-top: .2rem;
        ul {
          list-style: none;
          li {
            position: relative;
            user-select: none;
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-weight: 600;
            color: $second-text-2;
            display: flex;
            align-items: center;
            transition: all ease-out .2s;
            span.active {
              position: absolute;
              top: 50%;
              left: -1.1rem;
              transform: translateY(-50%);
              display: inline-block;
              background-color: $white;
              border-radius: 50%;
              width: .5rem;
              height: .5rem;
              margin-right: .6rem;
            }
            &.active {
              color: $white-text;
            }
            &:hover {
              cursor: pointer;
              opacity: 0.7;
              transition: all ease-out .2s;
            }
            &:active {
              cursor: pointer;
              opacity: 0.5;
              transition: all ease-out .2s;
            }
          }
        }
      }
    }
  }
</style>
