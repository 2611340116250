import store from '@/modules/app/store'
import { useEventBus } from '@vueuse/core'
import { Howl } from 'howler'
import { SOUND_VOLUME_CHANGED } from '@/modules/sound/event'

class SoundPlayer {
  constructor () {
    this.index = 0
    this.sound = null
    this.ctx = self
    this.bus = useEventBus('sound')
    this.activeSounds = []
    this.startListen = 0
    this.lastTrack = null
    this.endListen = 0
    this.mode = 'sound'
  }

  addToPlay (sound) {
    const finded = this.activeSounds.find((item) => item.sound.id === sound.sound.id) || sound
    if (finded.howl === null) {
      finded.howl = new Howl({
        xhr: {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + store.getters['auth/token']
          },
          withCredentials: true
        },
        src: [
          `${process.env.VUE_APP_BASE_URL}${finded.sound.src}`
        ],
        html5: true,
        volume: finded.sound.volume / 100,
        loop: true,
        format: ['mp3', 'webm']
      })

      this.activeSounds.push(finded)

      finded.howl.on('play', () => {
        finded.startListen = Date.now()
      })
      finded.howl.on('pause', () => {
        this.saveHistory(finded)
      })
      finded.howl.on('end', () => {
        this.saveHistory(finded)
      })
    }
    this.bus.on((event) => {
      if (event.event === SOUND_VOLUME_CHANGED) {
        const finded = this.activeSounds.find((item) => item.sound.id === event.data.id)
        if (finded) {
          const volume = event.data.volume / 100
          finded.howl.volume(volume <= 0.01 ? 0 : volume)
          switch (this.mode) {
            case 'sound':
              store.commit('sound/putVolumeToStorage', { id: finded.sound.id, volume: volume <= 0.01 ? 0 : volume })
              break
            case 'scene':
              store.commit('scene/putSoundVolumeToSceneConfig', { id: finded.sound.id, volume: volume <= 0.01 ? 0 : volume })
              break
          }
        }
      }
    })

    if (finded.howl.playing()) {
      finded.howl.pause()
    } else {
      finded.howl.play()
    }
  }

  pause (sound) {
    this.activeSounds.forEach((item) => {
      if (item.sound.id === sound.sound.id) {
        item.howl.pause()
      }
    })
  }

  stopAll () {
    this.activeSounds.forEach((item) => {
      item.howl.stop()
    })
    this.activeSounds = []
  }

  pauseAll () {
    this.activeSounds.forEach((item) => {
      item.howl.pause()
      item.isPlaying = false
    })
  }

  playAll () {
    this.activeSounds.forEach((item) => {
      item.howl.play()
      item.isPlaying = true
    })
  }

  setMode (mode) {
    this.mode = mode
  }

  saveHistory (finded) {
    finded.endListen = Date.now()
    store.dispatch('sound/saveMusicHistory', {
      track: finded.sound,
      duration: Math.floor((finded.endListen - finded.startListen) / 1000)
    })
  }
}

const SoundPlayerFactory = (function () {
  let instance
  return {
    getInstance: function () {
      if (instance == null) {
        instance = new SoundPlayer()
        instance.constructor = null
      }
      return instance
    }
  }
})()

export default SoundPlayerFactory
