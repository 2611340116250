import { useEventBus, useMouseInElement } from '@vueuse/core'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { OPEN_PLAYLIST_MODAL, SCROLL_TO_TRACK, TRACK_CHANGED } from '@/modules/music/event'
import { formatTime } from '@/modules/shared/utils/formatTime'
import { capitalize } from '@/modules/shared/utils/capitalize'

export function useMusicWidget () {
  const bus = useEventBus('music')
  const store = useStore()

  const activeTrack = computed(() => store.getters['music/activeTrack'])
  const tags = computed(() => store.getters['music/tags'])
  const genres = computed(() => store.getters['music/genres'])

  const target = ref(null)
  const root = ref(null)
  const { isOutside } = useMouseInElement(root)

  bus.on((event) => {
    if ((event.event === SCROLL_TO_TRACK || event.event === TRACK_CHANGED) && target.value && isOutside.value) {
      setTimeout(() => { target.value.scrollIntoView({ block: 'end', behavior: 'smooth' }) }, 50)
    }
  })

  return {
    capitalize,
    genres,
    tags,
    formatTime,
    root,
    target,
    activeTrack,
    addToFavorite: (track) => {
      if (track.favoriteId === null) {
        store.dispatch('music/addToFavorite', track)
      } else {
        store.dispatch('music/removeFromFavorite', track)
      }
    },
    addToPlaylist: (track, type) => {
      bus.emit({ event: OPEN_PLAYLIST_MODAL, data: { track: track, type: type } })
    },
    isFavorite: (track) => {
      if (track.favoriteId !== null) {
        return require('@/assets/img/icon/player/ic_favorite_active.svg')
      }
      return require('@/assets/img/icon/player/ic_favorite_thin.svg')
    },
    playTrack: (track) => {
      store.commit('music/setActiveTrack', { track: track })
    },
    isPlaying (track) {
      if (!activeTrack.value.track) {
        return require('@/assets/img/icon/player/ic_play_music.svg')
      }
      if (track.id === activeTrack.value.track.id && activeTrack.value.isPlaying) {
        return require('@/assets/img/icon/player/ic_pause_music.svg')
      }
      return require('@/assets/img/icon/player/ic_play_music.svg')
    },
    setItemRef: (el) => {
      setTimeout(() => {
        if (el && el.classList.contains('active')) {
          target.value = el
        }
      }, 10)
    }
  }
}
