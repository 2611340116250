<template>
  <div class="chat-messages"
       v-if="messages.length > 0"
  >
    <AppChatMessage :message="defaultMessage"/>
    <div
      class="chat-messages__block"
      v-for="(message, index) in messages"
      :key="message.id"
    >
      <div
        v-if="defineBlockDate(message, messages[index-1])"
        class="chat-messages__block_date">{{ wordsDate(formatDate(message.createdAt, 'YYYY-MM-DD', user.timeZone)) }}
      </div>
      <AppChatMessage
        :ref="setItemRef"
        :message="message"
        :class="{ last: index === messages.length - 1}"
      />
      <div :ref="setItemRef" v-if="isShowClose(message)" class="chat-messages__ticket-closed">Оператор закрыл обращение</div>
      <div :ref="setItemRef" v-if="isShowRating(message, index)" class="chat-messages__ticket-rating">
        <div class="title">Оцените качество обслуживания</div>
        <div class="rating">
          <AppChatRating @selected="sendRating"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import AppChatMessage from '@/modules/help/components/chat/AppChatMessage'
import { useEventBus } from '@vueuse/core'
import { ALL_MESSAGES_LOADED, CHAT_SCROLL_TO_LAST } from '@/modules/help/event'
import AppChatRating from '@/modules/help/components/chat/AppChatRating'
import { formatDate } from '@/modules/shared/utils/formatDate'
import { wordsDate } from '@/modules/shared/utils/wordsDate'

export default {
  components: { AppChatRating, AppChatMessage },
  setup () {
    const store = useStore()
    const busHelp = useEventBus('help')
    const user = computed(() => store.getters['auth/user'])

    const messages = computed(() => store.getters['help/messages'])
    const defaultMessage = store.getters['help/defaultMessage']
    const isChatNewMessage = computed(() => store.getters['help/isChatNewMessage'])
    const itemRefs = ref([])

    busHelp.on((event) => {
      if (event.event === ALL_MESSAGES_LOADED && itemRefs.value.length > 0) {
        setTimeout(() => {
          const lastElement = itemRefs.value.slice(-1)[0]
          try {
            lastElement.scrollIntoView({ block: 'end', behavior: 'smooth' })
          } catch (e) {
            lastElement.$el.scrollIntoView({ block: 'end', behavior: 'smooth' })
          }
        }, 50)
      }
      if (event.event === CHAT_SCROLL_TO_LAST && isChatNewMessage.value) {
        setTimeout(() => {
          const lastElement = itemRefs.value.slice(-1)[0]
          try {
            lastElement.scrollIntoView({ block: 'end', behavior: 'smooth' })
          } catch (e) {
            lastElement.$el.scrollIntoView({ block: 'end', behavior: 'smooth' })
          }
          store.commit('help/setChatNewMessage', false)
        }, 300)
      }
    })

    return {
      user,
      defaultMessage,
      formatDate,
      wordsDate,
      itemRefs,
      messages,
      setItemRef: (el) => {
        if (el) {
          itemRefs.value.push(el)
        }
      },
      sendRating: (rating) => {
        if (rating) {
          store.dispatch('help/sendRating', {
            ticketId: messages.value[messages.value.length - 1].ticket.id,
            rating: rating
          })
        }
      },
      defineBlockDate: (message, preMessage) => {
        if (!preMessage) {
          return true
        }

        const preMessageDate = formatDate(preMessage.createdAt, 'YYYY-MM-DD', user.value.timeZone)
        const messageDate = formatDate(message.createdAt, 'YYYY-MM-DD', user.value.timeZone)

        if (preMessageDate < messageDate) {
          return true
        }

        return false
      },
      isShowClose: (message) => {
        return message.ticket.status === 'CLOSED' && message.isLast
      },
      isShowRating: (message, index) => {
        return message.ticket.status === 'CLOSED' &&
          message.ticket.rating === 0 &&
          message.isLast &&
          index === (messages.value.length - 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .chat-messages {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 2;
    margin-right: 1rem;
    //height: 100%;
    justify-content: end;
    &__ticket-closed {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      padding: 1rem 3.2rem;
      border: 1px solid rgba(44, 44, 44, 0.80);
      background: rgba(44, 44, 44, 0.40);
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem;
      color: $white-80;
      border-radius: $radius;
      width: auto;
    }
    &__ticket-rating {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .title {
        color: $white;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8rem;
      }
      & .rating {
        margin-top: .8rem;
      }
    }
    &__block {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      &_date {
        width: 100%;
        text-align: center;
        color: $white-80;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2.4rem;
      }
    }

  }
</style>
