<template>
  <button
    :type="typeBtn"
    class="btn"
    :class="classNames"
    @click="$emit('action')"
    :style="style"
  >
    <slot />
  </button>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['action'],
  props: ['color', 'size', 'type', 'textColor', 'view', 'fz'],
  setup (props) {
    const typeBtn = ref(props.type || 'button')
    const color = ref(props.color || '')
    const view = ref(props.view || '')
    const textColor = ref(props.textColor || '')
    const size = ref(props.size || 'md')

    return {
      typeBtn,
      classNames: `btn-${size.value} ${view.value} ${color.value} text-${textColor.value}`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    transition: all 0.3s ease-out;
    outline: none;
    &-md {
      height: 5rem;
      //padding: 1.7rem 5.6rem 1.7rem 5.6rem;
      font-size: 1.6rem;
      min-width: 20rem;
    }
    &-sm {
      height: 3.5rem;
      font-size: 1.4rem!important;
      min-width: 12rem;
      padding: 0 2.6rem;
    }
    img {
      margin-right: 1rem;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    &:active {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  .primary {
    background-color: $primary;
  }
  .flat {
    background-color: transparent;
    font-weight: 600;
  }
  .outline {
    background-color: transparent;
    border: 1px solid $primary;
    font-weight: 600;
  }
  .text {
    height: auto;
    background-color: transparent;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0;
    min-width: 0;
  }
  .text- {
    &white {
      color: $white;
    }
    &primary {
      color: $primary;
    }
    &gray {
      color: $light-text;
    }
    &accent {
      color: $accent-text;
    }
  }

  @media screen and (max-width: 480px) {
    .btn {
      min-width: 100%;
      width: 100%;
    }
  }

</style>
