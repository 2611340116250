<template>
  <div
    v-for="track in tracks" :key="track.id"
    class="item"
    @click="playSound(track)"
    :class="{ active: isActiveSound(track)}"
  >
    <div class="item__control">
      <img
        :src="isPlayingSound(track)" alt=""
      >
      <div class="track">
        <div class="song-name">{{ track.title }}</div>
        <div class="song-category">{{ track.category.trim() }}</div>
      </div>
    </div>
    <div class="item__actions">
      <div class="item__actions_volume">
        <img src="@/assets/img/icon/player/ic_volume.svg" alt="">
        <AppSlider
          @click.stop
          class="slider"
          :color="defineSliderStyle(track)"
          :id="track.id"
          v-model="track.volume"
        />
      </div>
      <img @click.stop="$emit('remove', { audioId: track.id, type: 'SOUND'})" v-if="removable" src="@/assets/img/icon/ic_remove_x.svg" alt="">
      <img @click.stop="addToPlaylist(track, 'SOUND')" src="@/assets/img/icon/player/ic_add.svg" alt="">
      <img
        @click.stop="addToFavorite(track)"
        :src="isFavorite(track)"
        alt=""
      >
      <img @click.stop="soundCreditsClick(track)" src="@/assets/img/icon/player/ic_info.svg" alt="">
      <div class="duration">{{ formatTime(track.duration) }}</div>
    </div>
  </div>
  <AppMusicCredits
    v-if="isShowCredits"
    :credits="soundCredits"
    :click-x="lastClickX"
    :click-y="lastClickY"
    :translate-x="translateX"
    :translate-y="translateY"
    @action="closeSoundCredits()"
  />
</template>

<script>
import { useSoundWidget } from '@/modules/sound/use/useSoundWidget'
import { computed, ref } from 'vue'
import AppSlider from '@/components/ui/slider/AppSlider'
import { OPEN_PLAYLIST_MODAL } from '@/modules/music/event'
import { useEventBus } from '@vueuse/core'
import { useStore } from 'vuex'
import { formatTime } from '@/modules/shared/utils/formatTime'
import AppMusicCredits from '@/modules/music/components/music/AppMusicCredits'

export default {
  components: { AppMusicCredits, AppSlider },
  emits: ['remove'],
  props: ['_tracks', 'translateX', 'translateY', '_removable'],
  setup (props) {
    const store = useStore()
    const bus = useEventBus('sound')
    const tracks = computed(() => props._tracks)
    const removable = ref(props._removable || false)

    return {
      removable,
      formatTime,
      tracks,
      ...useSoundWidget(),
      addToPlaylist: (track, type) => {
        bus.emit({ event: OPEN_PLAYLIST_MODAL, data: { track: track, type: type } })
      },
      addToFavorite: (track) => {
        if (track.favoriteId === null) {
          store.dispatch('sound/addToFavorite', track)
        } else {
          store.dispatch('sound/removeFromFavorite', track)
        }
      },
      isFavorite: (track) => {
        if (track.favoriteId !== null) {
          return require('@/assets/img/icon/player/ic_favorite_active.svg')
        }
        return require('@/assets/img/icon/player/ic_favorite_thin.svg')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 1.6rem;
    border-radius: $radius;
    transition: all ease-out .2s;
    opacity: 0.55;
    height: 7.2rem;
    z-index: 2;
    &__control {
      display: flex;
      overflow: hidden;
      .track {
        margin-left: 1.6rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        .song-name {
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: $white;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 38rem;
        }
        .song-category {
          margin-top: 0.8rem;
          font-weight: 500;
          font-size: 1.2rem;
          color: $white;
          opacity: .70;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      img {
        -webkit-user-drag: none;
        user-select: none;
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
    }
    &__actions {
      margin-right: 0.5rem;
      display: flex;
      justify-content: flex-end;
      justify-items: center;
      gap: 1.5rem;
      .duration {
        width: 3.2rem;
        font-weight: 500;
        font-size: 1.4rem;
        color: $white-text;
        line-height: 2.5rem;
      }
      img {
        -webkit-user-drag: none;
        width: 2.4rem;
        height: 2.4rem;
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
      &_volume {
        display: flex;
        align-items: center;
        margin-right: 5rem;
        gap: 1rem;
        img {
          width: 2.4rem;
          height: 2.4rem;
        }
        .slider {
          width: 8rem;
        }
      }
    }
    &.active {
      opacity: 1;
      background-color: transparent;
      border-radius: $radius;
      transition: all ease-out .2s;
      box-shadow: none;
    }
    &:hover {
      opacity: 1;
      cursor: pointer;
      background-color: $dark-base-bg-2!important;
      border-radius: $radius;
      transition: all ease-out .2s;
      box-shadow: $shadow;
    }
  }
</style>
