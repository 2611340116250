import ApiClient from '@/modules/shared/utils/api'
import { playlist } from '@/modules/playlist/repository/playlist'
import {
  PLAYLIST_ADDED_TO_FAVORITE, PLAYLIST_REMOVE_FROM_FAVORITE,
  PLAYLISTS_FAVORITE_LOADED,
  PLAYLISTS_LOADED,
  PLAYLISTS_SERVICE_LOADED
} from '@/modules/playlist/event'
import { useEventBus } from '@vueuse/core'
import { shuffle } from '@/modules/shared/utils/shuffle'

const bus = useEventBus('playlist')

export default {
  namespaced: true,
  state: {
    playlists: [
    ],
    servicePlaylists: [
    ],
    serviceMusicPlaylists: [],
    serviceSoundPlaylists: [],
    favoritePlaylists: [
    ],
    favoriteSoundPlaylists: [
    ],
    favoriteMusicPlaylists: [
    ],
    playlistTags: [],
    playlistMusicTags: [],
    playlistSoundTags: [],
    playlistGenres: [],
    playlistCategories: [],
    activePlaylist: null,
    activeMusicPlaylist: null,
    activeSoundPlaylist: null,
    selectedTags: [],
    selectedMusicTags: [],
    selectedSoundTags: [],
    selectedGenres: [],
    selectedCategories: []
  },
  getters: {
    playlists (state) {
      return state.playlists
    },
    serviceMusicPlaylists (state) {
      return shuffle(state.serviceMusicPlaylists)
    },
    serviceSoundPlaylists (state) {
      return shuffle(state.serviceSoundPlaylists)
    },
    favoritePlaylists (state) {
      return state.favoritePlaylists
    },
    favoriteMusicPlaylists (state) {
      return state.favoriteMusicPlaylists
    },
    favoriteSoundPlaylists (state) {
      return state.favoriteSoundPlaylists
    },
    playlistTags (state) {
      return state.playlistTags
    },
    playlistMusicTags (state) {
      return state.playlistMusicTags
    },
    playlistSoundTags (state) {
      return state.playlistSoundTags
    },
    playlistGenres (state) {
      return state.playlistGenres
    },
    playlistCategories (state) {
      return state.playlistCategories
    },
    activeMusicPlaylist (state) {
      return state.activeMusicPlaylist
    },
    activeSoundPlaylist (state) {
      return state.activeSoundPlaylist
    },
    selectedTags (state) {
      return state.selectedTags
    },
    selectedMusicTags (state) {
      return state.selectedMusicTags
    },
    selectedSoundTags (state) {
      return state.selectedSoundTags
    },
    selectedGenres (state) {
      return state.selectedGenres
    },
    selectedCategories (state) {
      return state.selectedCategories
    },
    servicePlaylists (state) {
      return shuffle(state.servicePlaylists)
    },
    activePlaylist (state) {
      return state.activePlaylist
    }
  },
  mutations: {
    setPlayLists (state, playlists) {
      state.playlists = playlists
    },
    setServicePlayLists (state, playlists) {
      state.servicePlaylists = playlists
    },
    setServiceMusicPlayLists (state, playlists) {
      state.serviceMusicPlaylists = playlists
    },
    setServiceSoundPlayLists (state, playlists) {
      state.serviceSoundPlaylists = playlists
    },
    setFavoritePlayLists (state, playlists) {
      state.favoritePlaylists = playlists
    },
    setFavoriteMusicPlayLists (state, playlists) {
      state.favoriteMusicPlaylists = playlists
    },
    setFavoriteSoundPlayLists (state, playlists) {
      state.favoriteSoundPlaylists = playlists
    },
    shuffleServicePlaylists (state) {
      state.serviceMusicPlaylists = shuffle(state.serviceMusicPlaylists)
    },
    setPlaylistTags (state, tags) {
      state.playlistTags = tags
    },
    setPlaylistMusicTags (state, tags) {
      state.playlistMusicTags = tags
    },
    setPlaylistSoundTags (state, tags) {
      state.playlistSoundTags = tags
    },
    setPlaylistGenres (state, genres) {
      state.playlistGenres = genres
    },
    setPlaylistCategories (state, categories) {
      state.playlistCategories = categories
    },
    addTag (state, tag) {
      if (state.selectedTags.find((item) => item.id === tag.id)) {
        state.selectedTags.splice(state.selectedTags.indexOf(tag), 1)
        return
      }
      state.selectedTags.push(tag)
    },
    addMusicTag (state, tag) {
      if (state.selectedMusicTags.find((item) => item.id === tag.id)) {
        state.selectedMusicTags.splice(state.selectedMusicTags.indexOf(tag), 1)
        return
      }
      state.selectedMusicTags.push(tag)
    },
    addSoundTag (state, tag) {
      if (state.selectedSoundTags.find((item) => item.id === tag.id)) {
        state.selectedSoundTags.splice(state.selectedSoundTags.indexOf(tag), 1)
        return
      }
      state.selectedSoundTags.push(tag)
    },
    addGenre (state, genre) {
      if (state.selectedGenres.find((item) => item.id === genre.id)) {
        state.selectedGenres.splice(state.selectedGenres.indexOf(genre), 1)
        return
      }
      state.selectedGenres.push(genre)
    },
    addCategory (state, category) {
      if (state.selectedCategories.find((item) => item.id === category.id)) {
        state.selectedCategories.splice(state.selectedCategories.indexOf(category), 1)
        return
      }
      state.selectedCategories.push(category)
    },
    setSelectedMusicTag (state, tags) {
      state.selectedMusicTags = tags
    },
    setSelectedSoundTag (state, tags) {
      state.selectedSoundTags = tags
    },
    setSelectedGenres (state, genres) {
      state.selectedGenres = genres
    },
    setSelectedCategories (state, categories) {
      state.selectedCategories = categories
    },
    setActivePlaylist (state, playlist) {
      state.activePlaylist = playlist
    },
    setActiveMusicPlaylist (state, playlist) {
      state.activeMusicPlaylist = playlist
    },
    setActiveSoundPlaylist (state, playlist) {
      state.activeSoundPlaylist = playlist
    },
    addToList (state, playlist) {
      state.playlists.push(playlist)
    },
    setSelectedTag (state, tags) {
      state.selectedTags = tags
    }
  },
  actions: {
    async loadPlaylist ({ commit, dispatch }) {
      try {
        const params = {
          name: 'DESC'
        }
        const { data } = await ApiClient.get(playlist.playlists(), { params })
        commit('setPlayLists', data.data)
        bus.emit({ event: PLAYLISTS_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadPlaylistCard ({ commit, dispatch }, playlistId) {
      try {
        const params = {
          name: 'DESC'
        }
        const { data } = await ApiClient.get(playlist.playlistsCard(playlistId), { params })
        return data.data
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadServiceMusicPlaylist ({ state, commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          audioType: 'MUSIC',
          tags: payload.tags ? payload.tags.toString() : null,
          genres: payload.genres ? payload.genres.toString() : null
        }
        const { data } = await ApiClient.get(playlist.servicePlaylists(), { params })
        commit('setServiceMusicPlayLists', data.data)
        bus.emit({ event: PLAYLISTS_SERVICE_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadServiceSoundPlaylist ({ state, commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          audioType: 'SOUND',
          tags: payload.tags ? payload.tags.toString() : null,
          categories: payload.categories ? payload.categories.toString() : null
        }
        const { data } = await ApiClient.get(playlist.servicePlaylists(), { params })
        commit('setServiceSoundPlayLists', data.data)
        bus.emit({ event: PLAYLISTS_SERVICE_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadFavoritePlaylist ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          genres: payload.genres ? payload.genres.toString() : null,
          categories: payload.categories ? payload.categories.toString() : null
        }
        const { data } = await ApiClient.get(playlist.favoritePlaylists(), { params })
        commit('setFavoritePlayLists', data.data)
        commit('setFavoriteMusicPlayLists', data.data.filter((item) => item.audioType === 'MUSIC'))
        commit('setFavoriteSoundPlayLists', data.data.filter((item) => item.audioType === 'SOUND'))
        bus.emit({ event: PLAYLISTS_FAVORITE_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async createPlaylist ({ commit, dispatch }, name) {
      try {
        const payload = {
          name: name,
          type: 'USER',
          isPublic: false

        }
        await ApiClient.post(playlist.create(), payload)
        dispatch('loadPlaylist')
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async addToPlaylist ({ commit, dispatch }, data) {
      try {
        const payload = {
          audioId: data.track.id,
          type: data.type
        }
        await ApiClient.post(playlist.addToPlayList(data.playlist.id), payload)
        dispatch('loadPlaylist')
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async removeFromPlaylist ({ commit, dispatch }, payload) {
      try {
        await ApiClient.delete(playlist.removeFromPlayList(payload.playlistId, payload.audioId), {
          data: payload
        })
        dispatch('loadPlaylist')
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async removePlaylist ({ commit, dispatch }, playlistId) {
      try {
        await ApiClient.delete(playlist.removePlayList(playlistId))
        dispatch('loadPlaylist')
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadPlaylistGenres ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          isFavorite: payload.isFavorite
        }
        const { data } = await ApiClient.get(playlist.playlistGenres(), { params })
        commit('setPlaylistGenres', data.data)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadPlaylistCategories ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          isFavorite: payload.isFavorite
        }
        const { data } = await ApiClient.get(playlist.playlistCategories(), { params })
        commit('setPlaylistCategories', data.data)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadPlaylistMusicTags ({ commit, dispatch }) {
      try {
        const params = {
          // name: 'DESC',
          type: 'MUSIC'
        }
        const { data } = await ApiClient.get(playlist.playlistTags(), { params })
        commit('setPlaylistMusicTags', data.data)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadPlaylistSoundTags ({ commit, dispatch }) {
      try {
        const params = {
          // name: 'DESC',
          type: 'SOUND'
        }
        const { data } = await ApiClient.get(playlist.playlistTags(), { params })
        commit('setPlaylistSoundTags', data.data)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async addToFavorite ({ commit, dispatch }, item) {
      try {
        const payload = {
          audioType: 'MUSIC'
        }
        await ApiClient.post(playlist.addToFavorite(item.id), payload)
        item.favoriteId = item.id
        bus.emit({ event: PLAYLIST_ADDED_TO_FAVORITE })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async removeFromFavorite ({ commit, dispatch }, item) {
      try {
        await ApiClient.delete(playlist.removeFromFavorite(item.id))
        item.favoriteId = null
        bus.emit({ event: PLAYLIST_REMOVE_FROM_FAVORITE })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadPlaylistTags ({ commit, dispatch }, payload) {
      try {
        const params = {
          // name: 'DESC',
          type: payload.type
        }
        const { data } = await ApiClient.get(playlist.playlistTags(), { params })
        commit('setPlaylistTags', data.data)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadServicePlaylist ({ state, commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          tags: payload.tags ? payload.tags.toString() : null,
          genres: payload.genres ? payload.genres.toString() : null,
          categories: payload.categories ? payload.categories.toString() : null
        }
        const { data } = await ApiClient.get(playlist.servicePlaylists(), { params })
        commit('setServicePlayLists', data.data)
        bus.emit({ event: PLAYLISTS_SERVICE_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async changePlayListName ({ state, commit, dispatch }, payload) {
      try {
        const body = {
          name: payload.name
        }
        await ApiClient.put(playlist.changeName(payload.playlist.id), body)
        payload.playlist.name = payload.name
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  }
}
