export const playlist = {
  playlists: () => '/audio/playlists/user',
  playlistsCard: (playlistId) => `/audio/playlists/${playlistId}/card`,
  servicePlaylists: () => '/audio/playlists/service',
  favoritePlaylists: () => '/audio/playlists/user/favorite',
  create: () => '/audio/playlist',
  addToPlayList: (playlistId) => `/audio/playlist/${playlistId}/add`,
  changeName: (playlistId) => `/audio/playlist/${playlistId}/name`,
  removeFromPlayList: (playlistId, audioId) => `/audio/playlist/${playlistId}/audio/${audioId}`,
  removePlayList: (playlistId) => `/audio/playlist/${playlistId}`,
  playlistTags: () => '/audio/playlists/tags',
  playlistGenres: () => '/audio/playlists/genres',
  playlistCategories: () => '/audio/playlists/categories',
  addToFavorite: (playlistId) => `/audio/playlist/${playlistId}/attach`,
  removeFromFavorite: (playlistId) => `/audio/playlist/${playlistId}/detach`
}
