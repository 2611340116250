<template>
  <div v-show="isChatOpen" class="chat-card">
    <div class="chat-card__title"><span class="text" @click="reload()">Чат службы поддержки</span>
      <span @click="close()" style="height: 2.4rem; width: 2.4rem" class="card__close"/></div>
    <div class="chat-card__body">
      <div class="messages scrollable scrollbar">
        <AppChatMessagesList />
      </div>
      <img class="content-bg" src="@/assets/img/icon/chat/ic_chat_bg.svg" alt="">
    </div>
    <div class="chat-card__footer">
      <div class="send-field"
           :class="{ float: isTextareaFloating }">
        <img @click="openFilePicker" class="add-file" src="@/assets/img/icon/chat/ic_chat_add_file.svg" alt="">
        <textarea
          v-if="!imageDataURL"
          ref="textarea"
          class="message"
          :class="{ error:  messageModel === '' }"
          placeholder="Напишите сообщение..."
          rows="1"
          v-model="messageModel"
          @input="resizeTextarea"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="send()"
          @keydown.enter.shift.exact="newline()"
          @focusin="isTextareaFloating = true"
          @focusout="isTextareaFloating = false"
          @paste="onPaste"
        ></textarea>
        <div v-if="imageDataURL" class="message-img">
          <img :src="imageDataURL" alt="">
          <span @click="removeImage()" style="height: 2.4rem; width: 2.4rem" class="message-img__close"></span>
        </div>
        <img
          @click="send()"
          class="send-message"
          src="@/assets/img/icon/chat/ic_chat_send.svg" alt=""
        >
      </div>
    </div>
    <Teleport to="body">
      <Transition name="d02">
        <AppChatPhotoViewer
          v-if="imageModalIsOpen"
          width=""
          height=""
          :url="imgUrl"
          @close="imageModalIsOpen = !imageModalIsOpen"
        >
        </AppChatPhotoViewer>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import AppChatMessagesList from '@/modules/help/components/chat/AppChatMessagesList'
import AppChatPhotoViewer from '@/modules/help/components/card/AppChatPhotoViewer'
import { useEventBus, useFileDialog } from '@vueuse/core'
import { CHAT_IMG_OPENED } from '@/modules/help/event'

export default {
  components: { AppChatPhotoViewer, AppChatMessagesList },

  setup () {
    const store = useStore()

    const isChatOpen = computed(() => store.getters['help/chatOpen'])
    const textarea = ref(null)
    const messageModel = ref('')
    const isTextareaFloating = ref(false)
    const imageDataURL = ref(null)
    const chatImageFile = ref(null)
    const imageModalIsOpen = ref(false)
    const imgUrl = ref(null)

    // watch(isChatOpen, (value) => {
    //   if (value) {
    //     store.dispatch('help/loadTicketMessages', {})
    //   }
    // })

    const processImage = (imageFile) => {
      const reader = new FileReader()
      reader.onload = event => {
        imageDataURL.value = event.target.result
      }
      reader.readAsDataURL(imageFile)
    }

    const helpBus = useEventBus('help')
    helpBus.on((event) => {
      if (event.event === CHAT_IMG_OPENED) {
        imgUrl.value = event.data
        imageModalIsOpen.value = !imageModalIsOpen.value
      }
    })

    const { files, open: openFilePicker } = useFileDialog({ multiple: false, accept: 'image/*' })

    watch(files, (file) => {
      chatImageFile.value = file[0]
      processImage(chatImageFile.value)
    })

    return {
      files,
      openFilePicker,
      imgUrl,
      imageModalIsOpen,
      imageDataURL,
      isTextareaFloating,
      messageModel,
      textarea,
      isChatOpen,
      close: () => {
        store.commit('help/setChatOpen', false)
      },
      resizeTextarea () {
        textarea.value.style.height = 'auto'
        textarea.value.style.height = `${textarea.value.scrollHeight}px`
      },
      send: () => {
        if (messageModel.value.trim() !== '' || chatImageFile.value) {
          store.dispatch('help/sendTicketMessage', {
            content: messageModel.value.trim(),
            img: chatImageFile.value
          })
          if (chatImageFile.value === null) {
            textarea.value.style.height = '2.9rem'
          }
          messageModel.value = ''
          imageDataURL.value = null
          chatImageFile.value = null
        } else {
          store.dispatch('notification/setMessage', {
            value: 'Напишите сообщение для поддержки.',
            type: 'danger'
          })
        }
      },
      newline: () => {
        messageModel.value = `${messageModel.value}\n`
      },
      reload: () => {
        store.dispatch('help/loadTicketMessages', {})
      },
      onPaste (event) {
        const clipboardData = event.clipboardData || window.clipboardData
        const items = clipboardData.items
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            const imageFile = items[i].getAsFile()
            chatImageFile.value = imageFile
            processImage(imageFile)
          }
        }
      },
      removeImage: () => {
        imageDataURL.value = null
        chatImageFile.value = null
        messageModel.value = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .chat-card {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45rem;
    height: calc(100vh - 10rem);
    z-index: 21;
    background-color: $selected_dd;
    box-shadow: 1rem 0 1rem rgba(0, 0, 0, 0.15);
    border-radius: 2rem 0 0 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &__title {
      position: relative;
      z-index: 23;
      min-height: 8.4rem;
      background: rgba(44, 44, 44, 0.25);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $white;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: normal;
      padding: 0 3rem;
      & .card__close {
        @include close;
      }
      & .text {
        @include default-hover-active;
      }
    }
    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 2.5rem 2rem 2rem 3rem;
      height: calc(100vh - 27rem);
      //height: 100%;
      width: 100%;
      z-index: 22;
      & .messages {
        //height: 100%;
        width: 100%;
        @include scrollbar-dark;
      }
      & .content-bg {
        -webkit-user-drag: none;
        user-select: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18rem;
        height: 18rem;
        flex-shrink: 0;
        z-index: 1;
      }
    }
    &__footer {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: end;
      padding: 2.5rem 3rem 2.5rem 3rem;
      overflow: hidden;
      width: 100%;
      z-index: 23;
      & .send-field {
        position: relative;
        z-index: 23;
        flex-shrink: 0;
        min-height: 5rem;
        max-height: 30rem;
        width: 100%;
        border-radius: $radius;
        display: flex;
        padding: 1.3rem 2rem 1.3rem 2rem;
        background-color: $input-bg;
        & .add-file {
          align-self: end;
          margin-right: 2rem;
          margin-bottom: .5rem;
          @include default-hover-active;
        }
        & .message {
          align-self: end;
          width: 100%;
          max-height: 29rem;
          background-color: transparent;
          outline: none;
          border: none;
          color: $white;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2.4rem;
          resize: none;
          padding-right: 1rem;
          padding-top: .5rem;
          margin-bottom: .1rem;
          @include textarea-scrollbar;
          &::placeholder {
            color: $second-text-2;
          }
        }
        & .message-img {
          position: relative;
          display: flex;
          width: 100%;
          max-height: 26rem;
          justify-content: center;
          img {
            align-items: center;
            width: calc(100% - 3rem);
            height: auto;
            object-fit: contain;
            border-radius: $radius;
            margin-right: 1rem;
          }
          &__close {
            position: absolute;
            top: 0;
            right: -2.5rem;
            @include close;
          }
        }
        & .send-message {
          align-self: end;
          margin-left: .6rem;
          margin-bottom: .4rem;
          @include default-hover-active;
        }
      }
    }
  }
</style>
