<template>
  <div class="wrapper">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  setup () {
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    min-height: 100vh;
    background-color: $dark-base-bg-2;
  }
  @media screen and (max-width: 480px) {
    .wrapper {
      padding: 3rem;
    }
  }
</style>
