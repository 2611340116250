import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'

export default function useMainMenu () {
  const router = useRouter()
  const route = useRoute()
  const store = useStore()

  const activeMenu = computed(() => store.getters['menu/activeMenu'])

  return {
    menuList: computed(() => store.getters['menu/menuList']),
    activeMenu,
    icon: (item) => {
      if (route.path === item.route) {
        if (activeMenu.value.id !== item.id) {
          store.commit('menu/setActiveMenu', item)
        }
        return require(`@/assets/img/icon/menu/${item.icon}_active.svg`)
      } else {
        return require(`@/assets/img/icon/menu/${item.icon}.svg`)
      }
    },
    choose: (item) => {
      if (item.alias !== 'scene') {
        store.commit('scene/setActiveSceneStop')
      }
      router.push(item.route)
      if (activeMenu.value.id !== item.id) {
        store.commit('menu/setActiveMenu', item)
      }
    }
  }
}
