<template>
  <div v-if="isShowing" class="picker_layout">
    <div class="picker_layout__navigation">
      <div
        class="tab"
        v-for="tab in tabs"
        :key="tab.id"
        :class="{ active: tab.id === activeTab.id }"
        @click="chooseTab(tab)"
      ><span class="title"><span :class="{ active: tab.id === activeTab.id }"></span>{{ tab.name }}</span></div>
    </div>
    <div class="picker_layout__content">
      <div v-if="activeTab.id === 0" class="genres">
        <div class="header">
          <div class="title">
            Выберите любимые жанры
          </div>
          <div>
            <div @click="clearGenres()" class="clear">Сбросить</div>
          </div>
        </div>
        <div class="genres__items scrollable scrollbar">
          <div
            class="genres__items_item"
            :class="{active: isGenreSelected(item)}"
            v-for="item in genresList" :key="item.id"
            @click="addToGenres(item)"
          > {{ capitalize(item.name) }}</div>
        </div>
      </div>
      <div v-if="activeTab.id === 1" class="tags">
        <div class="header">
          <div class="title">
            Выберите подходящие теги
          </div>
          <div>
            <div @click="clearTags()" class="clear">Сбросить</div>
          </div>
        </div>
        <div class="tags__items scrollable scrollbar">
          <div
            class="tags__items_item"
            :class="{active: isTagSelected(item)}"
            v-for="item in tagsList" :key="item.id"
            @click="addToTags(item)"
          > {{ capitalize(item.name) }}</div>
        </div>
      </div>
    </div>
    <div class="picker_layout__bottom_navigation">
      <AppButtonIcon
        style="width: 100%;"
        textColor="white"
        view="text"
        @click="$emit('close')"
      >
        <img style="margin-right: .5rem;" src="@/assets/img/icon/ic_close.svg" alt="">
        Отмена
      </AppButtonIcon>
      <AppButtonIcon
        style="width: 100%;"
        textColor="white"
        view="text"
        @click="$emit('save', {selectedGenres, selectedTags})"
      >
        <img style="margin-right: .5rem;" src="@/assets/img/icon/ic_approve.svg" alt="">
          Сохранить
      </AppButtonIcon>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { capitalize } from '@/modules/shared/utils/capitalize'
import { useStore } from 'vuex'
import AppButtonIcon from '@/components/ui/button/AppButtonIcon'
import { idsFromObject } from '@/modules/shared/utils/idsFromObject'

export default {
  components: { AppButtonIcon },
  emits: ['close', 'save'],
  props: ['isShow', 'genres', 'sGenres', 'sTags', 'type'],
  setup (props) {
    const store = useStore()
    const isShowing = computed(() => props.isShow)
    const type = ref(props.type)
    const tabs = ref(
      [
        { id: 0, name: 'Жанры' },
        { id: 1, name: 'Теги' }
      ]
    )
    const activeTab = ref(tabs.value[0])
    const selectedGenres = ref(props.sGenres)
    const selectedTags = ref(props.sTags)

    const genresList = computed(() => props.genres)
    const tagsList = computed(() => {
      return type.value === 'MUSIC' ? store.getters['music/tags'] : store.getters['sound/tags']
    })
    if (type.value === 'MUSIC') {
      store.dispatch('music/loadMusicTags', { genres: idsFromObject(selectedGenres.value) })
    } else {
      store.dispatch('sound/loadSoundTags', { categories: idsFromObject(selectedGenres.value) })
    }

    return {
      genresList,
      tagsList,
      selectedGenres,
      selectedTags,
      capitalize,
      activeTab,
      tabs,
      isShowing,
      chooseTab: (tab) => {
        activeTab.value = tab
      },
      addToGenres: (genre) => {
        if (selectedGenres.value.find((item) => item.id === genre.id)) {
          selectedGenres.value.splice(selectedGenres.value.indexOf(genre), 1)
        } else {
          selectedGenres.value.push(genre)
        }
        if (type.value === 'MUSIC') {
          store.dispatch('music/loadMusicTags', { genres: idsFromObject(selectedGenres.value) })
        } else {
          store.dispatch('sound/loadSoundTags', { categories: idsFromObject(selectedGenres.value) })
        }
      },
      addToTags: (tag) => {
        if (selectedTags.value.find((item) => item.id === tag.id)) {
          selectedTags.value.splice(selectedTags.value.indexOf(tag), 1)
          return
        }
        selectedTags.value.push(tag)
      },
      isGenreSelected: (genre) => selectedGenres.value.findIndex((item) => item.id === genre.id) > -1,
      isTagSelected: (tag) => selectedTags.value.findIndex((item) => item.id === tag.id) > -1,
      clearGenres () {
        selectedGenres.value = []
        if (type.value === 'MUSIC') {
          store.dispatch('music/loadMusicTags', { genres: [] })
        } else {
          store.dispatch('sound/loadSoundTags', { categories: [] })
        }
      },
      clearTags () {
        selectedTags.value = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .picker_layout {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $widget-bg;
    border-radius: $radius-20;
    z-index: 2;
    padding: 3rem 0 2rem 2rem;
    &__navigation {
      height: 5rem;
      user-select: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;
      color: $second-text-2;
      background-color: rgba(53, 55, 57, 0.6);
      border-radius: $radius;
      padding: .3rem;
      margin-right: 2rem;
      z-index: 1;
      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        text-align: center;
        border-radius: $radius;
        transition: all ease-out .3s;
        z-index: 1;
        opacity: 1;
        &:hover {
          cursor: pointer;
          opacity: .9;
        }
        &.active {
          font-weight: 600;
          color: $white-text;
          transition: all ease-out .3s;
          background-color: $selected_dd;
          z-index: 2;
          opacity: 1;
        }
        .title {
          position: relative;
          padding: 0;
        }
      }
    }
    &__bottom_navigation {
      position: relative;
      user-select: none;
      height: 5rem;
      display: flex;
      justify-content: space-around;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.8rem;
      color: $second-text-2;
      background-color: $selected_dd;
      border-radius: $radius;
      margin-right: 2rem;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        width: .13rem;
        height: 2rem;
        background-color: #9898A6;
        opacity: .7;
        z-index: 1;
      }
    }
    &__content {
      flex: 1;
      margin-top: 3rem;
      margin-bottom: 3rem;
      overflow: hidden;
      .genres {
        display: flex;
        flex-direction: column;
        padding-right: .8rem;
        &__items {
          margin-top: 3rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 6rem;
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          height: 51.5vh;
          padding-right: .8rem;
          @include scrollbar-light;
          &_item {
            height: 6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(53, 55, 57, 0.6);
            border-radius: $radius;
            color: $second-text-2;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.8rem;
            transition: all ease-out .2s;
            &:hover {
              transition: all ease-out .2s;
              cursor: pointer;
              color: $white-text;
            }
            &:active {
              transition: all ease-out .2s;
              cursor: pointer;
              color: $white-text;
              opacity: .7;
            }
            &.active {
              transition: all ease-out .2s;
              color: $primary;
            }
          }
        }
      }
      .tags {
        display: flex;
        flex-direction: column;
        padding-right: .8rem;
        &__items {
          margin-top: 3rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 6rem;
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          height: 51.5vh;
          padding-right: .8rem;
          @include scrollbar-light;
          &_item {
            height: 6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(53, 55, 57, 0.6);
            border-radius: $radius;
            color: $second-text-2;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.8rem;
            transition: all ease-out .2s;
            &:hover {
              cursor: pointer;
              color: $white-text;
              transition: all ease-out .2s;
            }
            &:active {
              cursor: pointer;
              color: $white-text;
              opacity: .7;
              transition: all ease-out .2s;
            }
            &.active {
              transition: all ease-out .2s;
              color: $primary;
            }
          }
        }
        &.version2 {
          .tags__items {
            margin-top: 3rem;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .tags__items_item {
              height: 5rem;
              padding: 1.5rem 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(53, 55, 57, 0.6);
              border-radius: $radius;
              color: $second-text-2;
              font-size: 1.4rem;
              font-weight: 500;
              line-height: 1.8rem;
              transition: all ease-out .2s;
              &:hover {
                cursor: pointer;
                color: $white-text;
                transition: all ease-out .2s;
              }
              &:active {
                cursor: pointer;
                color: $white-text;
                opacity: .7;
                transition: all ease-out .2s;
              }
              &.active {
                transition: all ease-out .2s;
                color: $white-text;
              }
            }
          }
        }
      }
      & .header {
        margin-top: 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .title {
          margin-left: 1.4rem;
          color: $white-text;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.7rem;
        }
        & .clear {
          margin-right: 2.4rem;
          color: $second-text-2;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 2.2rem;
          @include default-hover-active;
        }
      }

    }
  }
</style>
