<template>
  <div class="page-wrapper">
    <div class="top-menu">
      <div class="top-menu__list">
        <ul v-for="item in menuList" :key="item.id">
          <li
            @click="chooseTab(item)"
            :class="{ active: item.active }"
          ><span :class="{ active: item.active }"></span>{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div v-if="activeTab.id === 0">
      <ThePlaylistMusicTab />
    </div>
    <div v-if="activeTab.id === 1">
      <ThePlaylistSoundTab />
    </div>
    <div v-if="activeTab.id === 2">
      <ThePlaylistFavoriteTab />
    </div>
    <div v-if="activeTab.id === 3">
      <ThePlaylistOwnTab />
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import ThePlaylistMusicTab from '@/modules/playlist/components/ThePlaylistMusicTab'
import ThePlaylistSoundTab from '@/modules/playlist/components/ThePlaylistSoundTab'
import ThePlaylistOwnTab from '@/modules/playlist/components/ThePlaylistOwnTab'
import ThePlaylistFavoriteTab from '@/modules/playlist/components/ThePlaylistFavoriteTab'
import { PLAYLIST_ADDED_TO_FAVORITE, PLAYLIST_REMOVE_FROM_FAVORITE } from '@/modules/playlist/event'
import { useEventBus } from '@vueuse/core'
import { useStore } from 'vuex'

export default {
  components: { ThePlaylistFavoriteTab, ThePlaylistOwnTab, ThePlaylistSoundTab, ThePlaylistMusicTab },
  setup () {
    const store = useStore()
    const bus = useEventBus('playlist')

    const menuList = [
      { id: 0, name: 'Музыка', active: true, alias: 'music' },
      { id: 1, name: 'Звуки', active: false, alias: 'sound' },
      { id: 2, name: 'Любимое', active: false, alias: 'favorite' },
      { id: 3, name: 'Мои', active: false, alias: 'own' }
    ]
    const activeTab = ref(menuList[0])

    bus.on((event) => {
      if (event.event === PLAYLIST_ADDED_TO_FAVORITE || event.event === PLAYLIST_REMOVE_FROM_FAVORITE) {
        store.dispatch('playlist/loadFavoritePlaylist', {})
        if (activeTab.value.alias === 'favorite') {
          store.dispatch('playlist/loadPlaylistGenres', { isFavorite: true })
          store.dispatch('playlist/loadPlaylistCategories', { isFavorite: true })
        }
      }
    })

    return {
      activeTab,
      menuList,
      chooseTab: (item) => {
        if (item.alias === 'music') {
          store.dispatch('playlist/loadPlaylistGenres', {})
        }
        if (item.alias === 'sound') {
          store.dispatch('playlist/loadPlaylistCategories', { isFavorite: true })
        }
        activeTab.value.active = false
        item.active = true
        activeTab.value = item
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.page-wrapper {
  padding: 0 4rem 0 4rem;
  .top-menu {
    position: absolute;
    top: 3.1rem;
    left: 27%;
    transform: translateX(-50%);
    z-index: 21;
    .top-menu__list {
      display: flex;
      list-style: none;
      gap: 2.5rem;
      margin-left: 8rem;
      //margin-top: .2rem;
      ul {
        list-style: none;
        li {
          position: relative;
          user-select: none;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 600;
          color: $second-text-2;
          display: flex;
          align-items: center;
          transition: all ease-out .2s;
          //span.active {
          //  position: absolute;
          //  top: 50%;
          //  left: -1.1rem;
          //  transform: translateY(-50%);
          //  display: inline-block;
          //  //background-color: $white;
          //  background-color: $primary;
          //  border-radius: 50%;
          //  width: .5rem;
          //  height: .5rem;
          //  margin-right: .6rem;
          //}
          &.active {
            //color: $white-text;
            color: $primary;
          }
          &:hover {
            cursor: pointer;
            opacity: 0.7;
            transition: all ease-out .2s;
            color: $primary;
          }
          &:active {
            cursor: pointer;
            opacity: 0.5;
            transition: all ease-out .2s;
          }
        }
      }
    }
  }
}
</style>
