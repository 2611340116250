<template>
  <div class="page-wrapper">
    <div class="section">
      <div class="section__music">
        <AppMusicTrackSection />
      </div>
      <div class="section__sounds">
        <AppSoundTrackSection />
        <div class="section__sounds_bg"></div>
      </div>
    </div>
  </div>
</template>

<script>

import AppMusicTrackSection from '@/modules/music/components/music/AppMusicTrackSection'
import AppSoundTrackSection from '@/modules/sound/components/AppSoundTrackSection'
export default {
  components: { AppSoundTrackSection, AppMusicTrackSection },
  setup () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .page-wrapper {
    padding: 0 4rem 0 0;
    .section {
      display: grid;
      grid-template-columns: minmax(82rem, 1fr) 1fr;
      &__music {
        padding-right: 4rem;
      }
      &__sounds {
        position: relative;
        z-index: 1;
        padding-left: 4rem;
        &_bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 120%;
          height: 120vh;
          transform: translate(0, -10%);
          z-index: -1;
          background-color: $widget-bg;
        }
      }
    }
  }

</style>
