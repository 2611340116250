import store from '@/modules/app/store'

export function helpMessageHandler (message) {
  console.log(store)
  if (message.action === 'chat') {
    const isChatOpen = store.getters['help/chatOpen']
    if (isChatOpen) {
      store.dispatch('help/loadTicketMessages', {})
    } else {
      store.commit('help/setChatNewMessage', true)
    }
  }
}
