export const music = {
  music: () => '/audio/music',
  tags: () => '/audio/tags',
  genres: () => '/audio/genres',
  artists: () => '/audio/artists',
  favorites: () => '/audio/music/favorite',
  addToFavorite: (audioId) => `/audio/track/${audioId}/favorite`,
  removeFromFavorite: (favoriteId) => `/audio/track/${favoriteId}/favorite`,
  history: (audioId) => `/audio/${audioId}/history`
}
