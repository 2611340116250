export const identity = {
  login: 'identity/auth/login',
  signUp: 'identity/auth/signup',
  refreshToken: 'identity/auth/refresh-token',
  logout: 'identity/auth/logout',
  restorePass: 'identity/auth/password/reset',
  newPassword: 'identity/auth/password',
  updatePassword: 'identity/user/password',
  updateNickname: (userId) => `/identity/user/${userId}/profile/nickname`,
  updateTimezone: (userId) => `/identity/user/${userId}/profile/timezone`
}
