<template>
  <div
    v-if="isShow"
    ref="playlistModal"
    class="playlist-card"
  >
    <div class="playlist-card__title">Добавить в плейлист <span @click="isShow = !isShow" class="close"></span></div>
    <div class="playlist-card__items scrollable scrollbar">
      <div
        class="playlist-card__items_item"
        :class="{ 'in-playlist': inPlaylist(item) }"
        v-for="item in playlist"
        :key="item.id"
        @click="addToPlayList(item)"
      ><div class="name">{{ item.name }}</div>
        <img
          v-if="inPlaylist(item)"
          @click.stop="removeFromPlaylist(item)"
          src="@/assets/img/icon/ic_close_gray.svg" alt="">
      </div>
    </div>
    <AppInputIcon
      v-if="isCreating"
      placeholder="Название плейлиста"
      width="w100"
      class="mt-1"
      @approve="save"
      v-model="playlistName"
    />
    <div
      v-if="!isCreating"
      @click="createTyping()"
      class="playlist-card__create"
    ><img src="@/assets/img/icon/player/ic_add_gray.svg" alt="">Создать плейлист</div>
  </div>
</template>

<script>
import AppInputIcon from '@/components/ui/input/AppInputIcon'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { onClickOutside, useEventBus } from '@vueuse/core'
import { OPEN_PLAYLIST_MODAL } from '@/modules/music/event'

export default {
  components: { AppInputIcon },
  setup () {
    const musicBus = useEventBus('music')
    const soundBus = useEventBus('sound')
    const store = useStore()
    const isCreating = ref(false)
    const playlist = computed(() => store.getters['playlist/playlists'])
    const isShow = ref(false)
    const playlistModal = ref(null)
    const playlistName = ref('')
    const lastEvent = ref(null)

    onClickOutside(
      playlistModal,
      () => {
        setTimeout(() => { isShow.value = false }, 1)
      }
    )

    musicBus.on((event) => {
      if (event.event === OPEN_PLAYLIST_MODAL) {
        lastEvent.value = event
        playlistName.value = ''
        isCreating.value = false
        isShow.value = true
      }
    })

    soundBus.on((event) => {
      if (event.event === OPEN_PLAYLIST_MODAL) {
        lastEvent.value = event
        playlistName.value = ''
        isCreating.value = false
        isShow.value = true
      }
    })

    return {
      playlistName,
      playlistModal,
      isShow,
      isCreating,
      playlist,
      save: () => {
        if (playlistName.value !== '') {
          store.dispatch('playlist/createPlaylist', playlistName.value)
          setTimeout(() => {
            const createdPlaylist = playlist.value.slice(-1)[0]
            store.dispatch('playlist/addToPlaylist',
              {
                playlist: createdPlaylist,
                track: lastEvent.value.data.track,
                type: lastEvent.value.data.type
              })
          }, 1000)
        }
        isCreating.value = false
      },
      createTyping: () => { isCreating.value = true },
      addToPlayList: (item) => {
        store.dispatch('playlist/addToPlaylist',
          {
            playlist: item,
            track: lastEvent.value.data.track,
            type: lastEvent.value.data.type
          })
      },
      inPlaylist: (item) => {
        switch (lastEvent.value.data.type) {
          case 'MUSIC': {
            return item.musicIds.indexOf(lastEvent.value.data.track.id) !== -1
          }
          case 'SOUND': {
            return item.soundIds.indexOf(lastEvent.value.data.track.id) !== -1
          }
          default: return false
        }
      },
      removeFromPlaylist: (item) => {
        store.dispatch('playlist/removeFromPlaylist',
          {
            playlistId: item.id,
            audioId: lastEvent.value.data.track.id,
            type: lastEvent.value.data.type
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .playlist-card {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $selected_dd;
    border-radius: $radius-20;
    padding: 3rem 1rem 3rem 2rem;
    z-index: 2;
    box-shadow: $shadow;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $white-text;
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-weight: 600;
      .close {
        margin-right: 1rem;
        @include close;
      }
    }
    &__items {
      margin-top: 2rem;
      max-height: 23.5rem;
      min-height: 23.5rem;
      @include scrollbar;
      &_item {
        padding: 0 2rem;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 4.4rem;
        font-weight: 500;
        color: $second-text-2;
        font-size: 1.6rem;
        line-height: 1.8rem;
        transition: all ease-out .2s;
        border-radius: $radius;
        .name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        &.in-playlist {
          color: $white-text;
        }
        &:hover {
          cursor: pointer;
          background-color: $dark-base-bg-2;
          transition: all ease-out .2s;
        }
        &:active {
          opacity: .5;
        }
        img {
          -webkit-user-drag: none;
          @include default-hover-active;
        }
      }
    }
    &__create {
      display: flex;
      align-items: center;
      height: 4.5rem;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 3.8rem;
      color: $second-text-2;
      padding-left: 1.3rem;
      //margin-left: -0.5rem;
      margin-top: 1rem;
      img {
        -webkit-user-drag: none;
        @include default-hover-active;
      }
      &:hover {
        cursor: pointer;
        opacity: .7;
      }
    }
  }
</style>
