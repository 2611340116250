<template>
  <label class="check-label" :class="{error: inputError}" >
    <span class="title"><slot /></span>
    <input v-model="inputModel" type="checkbox">
<!--    <span v-if="inputError" class="error"> {{ inputError }}</span>-->
    <span class="checkmark"></span>
  </label>
</template>

<script>
import { computed, ref, watch } from 'vue'

export default {
  emits: ['update:modelValue'],
  props: ['modelValue', 'error'],
  setup (props, { emit }) {
    const inputModel = ref()
    const inputError = computed(() => props.error || '')

    watch(inputModel, (value) => {
      emit('update:modelValue', value)
    })

    return {
      inputError, inputModel
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input[type=radio] {
    display: none;
  }
  .check-label {
    position: relative;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $white-80;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;

    &:hover input~.checkmark {
      cursor: pointer;
    }

    input:checked~.checkmark {
      background: transparent;
      border: 1px solid $accent;

      &:after {
        content: '';
        position: absolute;
        top: 62%;
        right: 50%;
        background-image: url("../../../assets/img/icon/ic-check-accent.svg");
        background-repeat: no-repeat;
        background-size: contain;
        transform: translate(50%, -50%);
        width: 1.1rem;
        height: 1.1rem;
        z-index: 10;
        transition: all .3s ease-out;
      }

      &.accent {
        background: $accent;
      }

      &.reject {
        background: $error-text;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 50%;
          background-image: url("../../../assets/img/icon/ic-close-w.svg");
          background-repeat: no-repeat;
          background-size: contain;
          transform: translate(50%, -50%);
          width: .8rem;
          height: .8rem;
          z-index: 10;
        }
      }

      &.error {
        background: $error;
      }
    }

    .checkmark {
      position: absolute;
      z-index: 1;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 0.4rem;
      border: 1px solid $light-text;
      background: transparent;
      transition: all .3s ease-out;

      &.square {
        border-radius: $radius;
      }

      &.accent {
        border: 2px solid $accent  !important;
      }

      &.primary {
        border: 2px solid $primary  !important;
      }

      &.reject {
        border: 2px solid $red  !important;
      }
    }

    .title {
      margin-left: 3rem;
      cursor: pointer;
      line-height: normal;
      &.black {
        color: $dark-gray;
        font-weight: 600;
      }
    }

    &.error {
      .checkmark {
        border: 2px solid $error !important;
      }
    }

  }
</style>
