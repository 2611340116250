<template>
  <div ref="root" class="widget">
    <div class="widget__navigation">
      <div
        class="tab"
        v-for="tab in tabs"
        :key="tab.id"
        :class="{ active: tab.id === activeTab.id }"
        @click="chooseTab(tab)"
      ><span class="title"><span :class="{ active: tab.id === activeTab.id }"></span>{{ tab.name }}</span></div>
    </div>
    <div class="widget__content">
      <Transition name="d02">
        <div class="music scrollable scrollbar" v-if="activeTab.id === 0">
          <div
            :ref="setItemRef"
            :class="{ active:  activeTrack.track ? activeTrack.track.id === track.id : ''}"
            v-for="track in tracks" :key="track.id"
            class="item"
            @click="playTrack(track)"
            :data-id="`row-${track.id}`"
          >
            <div class="item__control">
              <img
                :src="isPlaying(track)" alt=""
              >
              <div class="track">
                <div class="song-name">{{ track.title }}</div>
                <div class="song-author">{{ track.artist }}</div>
              </div>
            </div>
            <div class="item__actions">
              <img @click.stop ="addToPlaylist(track, 'MUSIC')" src="@/assets/img/icon/player/ic_add.svg" alt="">
              <img
                @click.stop="addToFavorite(track)"
                :src="isFavorite(track)"
                alt=""
              >
              <div class="duration">{{ formatTime(track.duration) }}</div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="d02">
        <div class="sound scrollable scrollbar" v-if="activeTab.id === 1">
          <div
            v-for="track in sounds" :key="track.id"
            class="item"
            @click="playSound(track)"
            :class="{ active:  isActiveSound(track)}"
          >
            <div class="item__control">
              <img
                :src="isPlayingSound(track)" alt=""
              >
              <div class="track">
                <div class="song-name">{{ track.title }}</div>
                <div class="song-category">{{ track.category.trim() }}</div>
              </div>
            </div>
            <div class="item__actions sounds">
              <div class="actions">
                <img @click.stop="addToPlaylist(track, 'SOUND')" src="@/assets/img/icon/player/ic_add.svg" alt="">
                <img
                  @click.stop="addToFavorite(track)"
                  :src="isFavorite(track)"
                  alt=""
                >
                <img @click.stop="soundCreditsClick(track)" src="@/assets/img/icon/player/ic_info.svg" alt="">
              </div>
              <AppSlider
                @click.stop
                class="item__actions_volume"
                :color="defineSliderStyle(track)"
                :id="track.id"
                v-model="track.volume"
              />
            </div>
          </div>
        </div>
      </Transition>
      <AppPlaylistCard class="playlist" />
    </div>
    <div class="widget__bottom_navigation">
      <AppButtonIcon
        style="width: 100%;"
        textColor="white"
        view="text"
        @action="openTags()"
      >
        <img style="margin-right: .5rem;" src="@/assets/img/icon/player/ic_music.svg" alt="">Жанры и теги
      </AppButtonIcon>
      <AppButtonIcon
        style="width: 100%;"
        textColor="white"
        @action="loadFavorite()" view="text"
      >
        <img style="margin-right: .5rem;" src="@/assets/img/icon/player/ic_favorite_empty.svg" alt="">Любимое
      </AppButtonIcon>
    </div>
    <Transition name="d02">
      <AppTagsPicker
        v-if="activeTab.id === 0"
        :genres="musicGenres"
        :s-genres="selectedMusicGenres"
        :s-tags="selectedMusicTags"
        type="MUSIC"
        @close="closeTags()"
        @save="saveTags"
        :is-show="isShowTagPicker"
      />
    </Transition>
    <Transition name="d02">
      <AppTagsPicker
        v-if="activeTab.id === 1"
        :genres="soundCategories"
        :s-genres="selectedSoundCategories"
        :s-tags="selectedSoundTags"
        type="SOUND"
        @close="closeTags()"
        @save="saveTags"
        :is-show="isShowTagPicker"
      />
    </Transition>
    <AppMusicCredits
      v-if="isShowCredits"
      :credits="soundCredits"
      :click-x="lastClickX"
      :click-y="lastClickY"
      translate-x="405"
      translate-y="31"
      @action="closeSoundCredits()"
    />
  </div>
</template>

<script>
import { useMusicWidget } from '@/modules/music/use/useMusicWidget'
import { useSoundWidget } from '@/modules/sound/use/useSoundWidget'
import AppSlider from '@/components/ui/slider/AppSlider'
import AppPlaylistCard from '@/modules/playlist/components/AppPlaylistCard'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import AppTagsPicker from '@/modules/music/components/music/AppTagsPicker'
import AppButtonIcon from '@/components/ui/button/AppButtonIcon'
import AppMusicCredits from '@/modules/music/components/music/AppMusicCredits'
import MusicPlayerFactory from '@/modules/music/use/musicPlayer'
import { idsFromObject } from '@/modules/shared/utils/idsFromObject'

export default {
  components: { AppMusicCredits, AppButtonIcon, AppTagsPicker, AppPlaylistCard, AppSlider },
  setup () {
    const store = useStore()
    const tRoute = ref(null)
    const tabs = ref(
      [
        { id: 0, name: 'Музыка' },
        { id: 1, name: 'Звуки' }
      ]
    )
    const activeTab = ref()
    const isShowTagPicker = ref(false)

    const tracks = computed(() => store.getters['music/tracks'])
    const musicGenres = computed(() => store.getters['music/genres'])
    const musicTags = computed(() => store.getters['music/tags'])
    const selectedMusicGenres = computed(() => store.getters['music/selectedGenres'])
    const selectedMusicTags = computed(() => store.getters['music/selectedTags'])

    const sounds = computed(() => store.getters['sound/sounds'])
    const soundCategories = computed(() => store.getters['sound/categories'])
    const soundTags = computed(() => store.getters['sound/tags'])
    const selectedSoundCategories = computed(() => store.getters['sound/selectedCategories'])
    const selectedSoundTags = computed(() => store.getters['sound/selectedTags'])

    watch([activeTab, tracks, sounds], (values) => {
      switch (values[0].id) {
        case 0:
          isShowTagPicker.value = values[1].length === 0
          break
        case 1:
          isShowTagPicker.value = values[2].length === 0
          break
      }
    })
    activeTab.value = tabs.value[0]

    const player = MusicPlayerFactory.getInstance()
    player.setPlaylist(tracks)

    return {
      selectedMusicGenres,
      selectedMusicTags,
      selectedSoundCategories,
      selectedSoundTags,
      tracks,
      sounds,
      musicGenres,
      musicTags,
      soundCategories,
      soundTags,
      isShowTagPicker,
      activeTab,
      tabs,
      chooseTab: (tab) => {
        activeTab.value = tab
      },
      loadFavorite: () => {
        switch (activeTab.value.id) {
          case 0 :
            store.dispatch('music/loadFavoriteMusic', {})
            store.commit('playlist/setActiveMusicPlaylist', null)
            break
          case 1 :
            store.dispatch('sound/loadFavoriteSound', {})
            store.commit('playlist/setActiveSoundPlaylist', null)
            break
        }
      },
      tRoute,
      ...useMusicWidget(),
      ...useSoundWidget(),
      openTags: () => {
        isShowTagPicker.value = true
      },
      closeTags: () => {
        switch (activeTab.value.id) {
          case 0 :
            if (tracks.value.length === 0) {
              isShowTagPicker.value = true
              store.dispatch('notification/setMessage',
                {
                  value: 'Выберите жанры или тэги для загрузки композиций',
                  type: 'danger'
                }
              )
            } else {
              isShowTagPicker.value = false
            }
            break
          case 1 :
            if (sounds.value.length === 0) {
              isShowTagPicker.value = true
              store.dispatch('notification/setMessage',
                {
                  value: 'Выберите жанры или тэги для загрузки композиций',
                  type: 'danger'
                }
              )
            } else {
              isShowTagPicker.value = false
            }
            break
        }
      },
      saveTags: (selected) => {
        console.log(idsFromObject(selected.selectedTags))
        switch (activeTab.value.id) {
          case 0:
            store.commit('music/setSelectedGenres', selected.selectedGenres)
            store.commit('music/setSelectedTags', selected.selectedTags)
            store.dispatch('music/loadMusic', {
              tags: idsFromObject(selected.selectedTags),
              genres: idsFromObject(selected.selectedGenres)
            })
            store.commit('playlist/setActiveMusicPlaylist', null)
            store.dispatch('playlist/loadServiceMusicPlaylist', {
              tags: idsFromObject(selected.selectedTags),
              genres: idsFromObject(selected.selectedGenres)
            })
            break
          case 1:
            store.commit('sound/setSelectedCategories', selected.selectedGenres)
            store.commit('sound/setSelectedTags', selected.selectedTags)
            store.dispatch('sound/loadSound', {
              categories: idsFromObject(selected.selectedGenres),
              tags: idsFromObject(selected.selectedTags)
            })
            store.commit('playlist/setActiveSoundPlaylist', null)
            store.dispatch('playlist/loadServiceSoundPlaylist', {
              tags: idsFromObject(selected.selectedTags),
              categories: idsFromObject(selected.selectedGenres)
            })
            break
        }
        isShowTagPicker.value = false
        store.dispatch('music/loadMusicArtists', {
          tags: idsFromObject(selected.selectedTags),
          genres: idsFromObject(selected.selectedGenres)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .widget {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $widget-bg;
    border-radius: $radius-20;
    padding: 3rem 1rem 2rem 2rem;
    box-shadow: $shadow;
    &__navigation {
      height: 5rem;
      user-select: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;
      color: $second-text-2;
      background-color: rgba(53, 55, 57, 0.6);
      border-radius: $radius;
      padding: .3rem;
      margin-right: 1rem;
      z-index: 1;
      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        text-align: center;
        border-radius: $radius;
        transition: all ease-out .3s;
        z-index: 1;
        opacity: 1;
        &:hover {
          cursor: pointer;
          opacity: .9;
        }
        &.active {
          font-weight: 600;
          color: $white-text;
          transition: all ease-out .3s;
          background-color: $selected_dd;
          z-index: 2;
          opacity: 1;
        }
        .title {
          position: relative;
          padding: 0;
        }
      }
    }
    &__bottom_navigation {
      position: relative;
      user-select: none;
      height: 5rem;
      display: flex;
      justify-content: space-around;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;
      color: $second-text-2;
      background-color: $selected_dd;
      border-radius: $radius;
      margin-right: 1rem;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        width: .13rem;
        height: 2rem;
        background-color: #9898A6;
        opacity: .7;
        z-index: 1;
      }
    }
    &__content {
      flex: 1;
      margin-top: 3rem;
      margin-bottom: 3rem;
      overflow: hidden;
      .music {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        height: 60vh;
        padding-right: .8rem;
        @include scrollbar-light;
      }
      .sound {
        height: 60vh;
        padding-right: .8rem;
        .item {
          align-items: flex-start;
          .item__actions {
            margin-top: -.3rem;
            gap: 1.3rem;
          }
          &.active {
            opacity: 1;
            background-color: transparent;
            border-radius: $radius;
            transition: all ease-out .2s;
            box-shadow: none;
          }
        }
        @include scrollbar-light;
      }
      .item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.4rem 1.6rem;
        border-radius: $radius;
        transition: all ease-out .2s;
        gap: .4rem;
        opacity: 0.55;
        &__control {
          display: flex;
          overflow: hidden;
          .track {
            margin-left: 1.6rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            .song-name {
              font-weight: 600;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: $white;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .song-author {
              margin-top: 0.8rem;
              font-weight: 500;
              font-size: 1.2rem;
              color: $white;
              opacity: .70;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .song-category {
              display: inline-block;
              margin-top: 1rem;
              font-weight: 600;
              font-size: 1.2rem;
              line-height: 1.8rem;
              padding: 0.1rem 1rem;
              background-color: $selected_dd;
              color: $white;
              opacity: .7;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              border-radius: .8rem;
            }
          }
          img {
            -webkit-user-drag: none;
            user-select: none;
            &:hover {
              cursor: pointer;
              opacity: .7;
            }
            &:active {
              cursor: pointer;
              opacity: .5;
            }
          }
        }
        &__actions {
          display: flex;
          justify-content: flex-end;
          flex: 1;
          justify-items: center;
          gap: .5rem;
          .duration {
            font-weight: 500;
            font-size: 1.4rem;
            color: $white-text;
            line-height: 2.5rem;
          }
          img {
            -webkit-user-drag: none;
            width: 2.4rem;
            height: 2.4rem;
            &:hover {
              cursor: pointer;
              opacity: .7;
            }
            &:active {
              cursor: pointer;
              opacity: .5;
            }
          }
          &.sounds {
            gap: 1rem;
            flex-direction: column;
            align-items: flex-end;
            .actions {
              display: flex;
              gap: 0.3rem;
            }
            .item__actions_volume {
              align-self: flex-end;
              width: 7.6rem;
            }
          }
        }
        &.active {
          opacity: 1;
          background-color: $dark-base-bg-2;
          border-radius: $radius;
          transition: all ease-out .2s;
          box-shadow: $shadow;
        }
        &:hover {
          opacity: 1;
          cursor: pointer;
          background-color: $dark-base-bg-2!important;
          border-radius: $radius;
          transition: all ease-out .2s;
          box-shadow: $shadow;
        }
      }
    }
    .playlist {
      position: absolute;
      top: 1rem;
      left: -2rem;
      width: 35rem;
      max-height: 40rem;
      z-index: 2;
      overflow-y: hidden;
    }
  }
</style>
