export default {
  namespaced: true,
  state: {
    activeMenu: '',
    menu: [
      { id: 0, name: 'Главная', route: '/home', icon: 'ic_main', active: false, subMenu: false, alias: 'home' },
      // { id: 1, name: 'Музыка', route: '/music', icon: 'ic_music', active: false, subMenu: false, alias: 'music' },
      // { id: 2, name: 'Звуки', route: '/sound', icon: 'ic_sound', active: false, subMenu: false, alias: 'sound' },
      // { id: 3, name: 'Видео', route: '/video', icon: 'ic_video', active: false, subMenu: false, alias: 'video' },
      { id: 7, name: 'Музыка и звуки', route: '/music-sounds', icon: 'ic_music', active: false, subMenu: true, alias: 'musicSounds' },
      { id: 6, name: 'Плейлисты', route: '/playlists', icon: 'ic_playlist', active: false, subMenu: true, alias: 'playlists' },
      { id: 4, name: 'Сцены', route: '/scene', icon: 'ic_scene', active: false, subMenu: false, alias: 'scene' }
      // { id: 5, name: 'Любимое', route: '/favorite', icon: 'ic_favorite', active: false, subMenu: false, alias: 'favorite' },

    ]
  },
  getters: {
    menuList (state) {
      return state.menu
    },
    activeMenu (state) {
      return state.activeMenu
    }
  },
  mutations: {
    setActiveMenu (state, activeMenu) {
      state.activeMenu = activeMenu
      state.menu.forEach((item) => {
        if (item.id === activeMenu.id) {
          item.active = true
        } else {
          item.active = false
        }
      })
    },
    setAllNotActive (state) {
      state.menu.forEach((item) => {
        item.active = false
      })
    }
  },
  actions: {
  }
}
