import axios from 'axios'
import router from '@/modules/app/router'
import { useEventBus } from '@vueuse/core'
import { TOKEN_UPDATED, USER_LOGOUT } from '@/modules/identity/event'

const bus = useEventBus('auth')

const ApiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_BASE_VERSION}`
})

ApiClient.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access-token')}`

bus.on((event) => {
  if (event.event === TOKEN_UPDATED) {
    ApiClient.defaults.headers.common.Authorization = `Bearer ${event.data}`
  }
})

ApiClient.interceptors.response.use((response) => {
  return response
}, (error) => {
  switch (error.response.status) {
    case 401:
      bus.emit({ event: USER_LOGOUT })
      router.push('/sign-in?message=unauthorized')
      break
    case 403:
      bus.emit({ event: USER_LOGOUT })
      router.push('/sign-in?message=forbidden')
      break
  }

  return Promise.reject(error)
})

export default ApiClient
