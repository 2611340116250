<template>
  <div class="chat-message" :class="{ support: isSupport(messageItem) }">
    <div v-if="isSupport(messageItem)" class="chat-message__title">
      <div v-if="messageItem.author.name " class="name"> {{ messageItem.author.name }}</div>
      <div class="label">cлужба поддержки</div>
    </div>
    <div class="chat-message__body">
      <div v-if="messageItem.content" class="chat-message__body_text" v-html="messageItem.content.replace(/\n/g, '<br />')"></div>
      <div v-if="messageItem.img" class="chat-message__body_img">
        <img @click="openViewer(messageItem.img)" :src="defineImgUrl(messageItem.img)" alt="">
      </div>
      <div class="chat-message__body_date">{{ messageItem.createdAt ? formatDate(messageItem.createdAt, 'HH:mm', user.timeZone) : '' }}</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { formatDate } from '@/modules/shared/utils/formatDate'
import { useEventBus } from '@vueuse/core'
import { CHAT_IMG_OPENED } from '@/modules/help/event'

export default {
  props: ['message'],
  setup (props) {
    const store = useStore()
    const helpBus = useEventBus('help')

    const user = computed(() => store.getters['auth/user'])
    const messageItem = computed(() => props.message)

    return {
      formatDate,
      user,
      messageItem,
      isSupport: (message) => {
        return message.author.id !== user.value.id
      },
      defineImgUrl: (path) => {
        return `${process.env.VUE_APP_BASE_URL}/storage/${path}`
      },
      openViewer: (url) => {
        helpBus.emit({ event: CHAT_IMG_OPENED, data: `${process.env.VUE_APP_BASE_URL}/storage/${url}` })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .chat-message {
    width: 87%;
    background-color: $dark-base-bg-2;
    padding: 1.8rem 2rem;
    border-radius: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    gap: .7rem;
    align-self: end;
    &.support {
      background-color: #2E2F2F;
      border-radius: 1rem 1rem 1rem 0;
      align-self: start;
    }
    &__title {
      margin-top: .5rem;
      color: rgba(152, 152, 152, 0.50);
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 0;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    &__body {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      &_text {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.9rem;
        color: $white;
        align-self: end;
      }
      &_date {
        color: rgba(152, 152, 152, 0.50);
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.7rem;
        align-self: end;
      }
      &_img {
        width: 25.6rem;
        height: auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: $radius;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
