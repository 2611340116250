<template>
  <div class="player">
    <Transition>
      <div v-if="activeTrack.track" class="info">
        <div class="loading" :class="{ 'loading-blink': activeTrack.isLoading }">
          <img src="@/assets/img/icon/player/ic_player_loading.svg" alt="">
        </div>
        <div @click="scrollToTrack" class="track">
          <div class="track__name">
            <span>{{ activeTrack.track.title }}</span>
            <AppMusicPlayerCredits :credits="activeTrack.track.credits" />
          </div>
          <div class="track__author">{{ activeTrack.track.artist }}</div>
          <div v-if="activeTrack.track.album" class="track__label">{{ activeTrack.track.album }}</div>
        </div>
      </div>
    </Transition>
    <div v-if="!activeTrack.track" style="width: 100%;"></div>

    <Transition>
      <div v-if="activeTrack.track" class="progress">
          <div class="progress__controls">
            <img @click="shuffle()" class="shuffle" :src="isShuffle()" alt="">
            <img @click="backward()" class="backward" src="@/assets/img/icon/player/ic_backward.svg" alt="">
            <img @click="play()" class="play" :src="isPlaying()" alt="">
            <img @click="forward()" class="forward" src="@/assets/img/icon/player/ic_forward.svg" alt="">
            <img @click="repeat()" class="repeat" :src="isRepeat()" alt="">
          </div>
          <div class="progress__progress">
            <div class="time-start"> {{ formatTime(timeLeft) }}</div>
            <div class="slider">
              <AppSlider
                color="primary"
                @seekMouseUp="musicSeekMove()"
                @seekMouseDown="musicSeekLock()"
                v-model="musicSeekValue"
              />
            </div>
            <div class="time-end">{{ formatTime(activeTrack.track.duration) }}</div>
          </div>
        </div>
    </Transition>
    <div v-if="!activeTrack.track" class="progress disabled">
      <div class="progress__controls">
        <img class="shuffle" src="@/assets/img/icon/player/ic_shuffle.svg" alt="">
        <img class="backward" src="@/assets/img/icon/player/ic_backward.svg" alt="">
        <img class="play" src="@/assets/img/icon/player/ic_play_music.svg" alt="">
        <img class="forward" src="@/assets/img/icon/player/ic_forward.svg" alt="">
        <img class="repeat" src="@/assets/img/icon/player/ic_repeat.svg" alt="">
      </div>
      <div class="progress__progress">
        <div class="slider">
        </div>
      </div>
    </div>

    <div class="controls">
     <div
       @mouseover="mouseVolumeOver()"
       @mouseleave="mouseVolumeLeave()"
       class="relative"
     >
       <img
         class="controls__volume"
         :class="{ muted: isMuted === true || masterVolume === 0 }"
         @click="mute()"
         :src="defineVolumeIcon(isVolumeHovered)" alt="">
       <Transition>
         <div v-if="isVolumeHovered" class="controls__master_volume">
           <span>М</span>
           <AppVerticalSlider
             class="vt-slider"
             color="primary"
             @seekMouseMove="masterVolumeMove()"
             v-model="masterVolumeValue"
           />
         </div>
       </Transition>
     </div>
      <div class="controls__volume_bar">
        <AppSlider
          color="primary"
          @seekMouseMove="musicVolumeMove()"
          v-model="musicVolumeValue"
        />
      </div>
      <AppActiveSounds />
      <img class="menu-icon" @click="switchSideInfo()" :src="defineMenuIcon()" alt="">
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {
  SCROLL_TO_TRACK,
  TRACK_CHANGED, TRACK_INIT,
  TRACK_PAUSED,
  TRACK_PLAY,
  TRACK_RESUMED, TRACK_SEEK_CHANGED,
  TRACK_VOLUME_CHANGED
} from '@/modules/music/event'
import { onKeyStroke, useEventBus, useMagicKeys } from '@vueuse/core'
import { USER_LOGOUT } from '@/modules/identity/event'
import { computed, ref, watch, watchEffect } from 'vue'
import { formatTime } from '@/modules/shared/utils/formatTime'
import AppSlider from '@/components/ui/slider/AppSlider'
import AppVerticalSlider from '@/components/ui/slider/AppVerticalSlider'
import AppActiveSounds from '@/modules/sound/components/AppActiveSounds'
import { APP_SIDE_INFO } from '@/modules/app/event'
import AppMusicPlayerCredits from '@/modules/music/components/music/AppMusicPlayerCredits'
import MusicPlayerFactory from '@/modules/music/use/musicPlayer'
import { SCENE_CARD_LOADED, SCENE_STOP, SCENE_VOLUME_CHANGE } from '@/modules/scene/event'

export default {
  components: { AppMusicPlayerCredits, AppActiveSounds, AppVerticalSlider, AppSlider },

  setup () {
    const bus = useEventBus('music')
    const sceneBus = useEventBus('scene')
    const userBus = useEventBus('auth')
    const appBus = useEventBus('app')
    const store = useStore()

    const activeTrack = store.getters['music/activeTrack']

    const tracks = computed(() => store.getters['music/tracks'])
    const isMuted = computed(() => store.getters['music/isMuted'])
    const musicSeekValue = ref(0)

    const isVolumeHovered = ref(false)

    const player = MusicPlayerFactory.getInstance()

    const musicVolumeValue = ref(store.getters['music/musicVolume'] * 100)
    const masterVolumeValue = ref(store.getters['music/masterVolume'] * 100)
    const masterVolume = ref(store.getters['music/masterVolume'] * 100)

    const timeLeft = ref(0)
    const { ctrl, m, ь } = useMagicKeys()
    const isMusicSeekLock = ref(false)
    const sideInfoBlock = ref(true)

    watch(masterVolume, (value) => {
      masterVolumeValue.value = value
    })

    player.setPlaylist(tracks)
    player.setActiveTrack(activeTrack)

    bus.on((event) => {
      switch (event.event) {
        case TRACK_INIT:
          player.play(true)
          break
        case TRACK_CHANGED:
          player.play()
          break
        case TRACK_PLAY:
          player.resume()
          break
        case TRACK_PAUSED:
          player.pause()
          break
        case TRACK_RESUMED:
          player.resume()
          break
        case TRACK_VOLUME_CHANGED:
          // player.changeVolume(event.data)
          break
        case TRACK_SEEK_CHANGED:
          if (!isMusicSeekLock.value) {
            musicSeekValue.value = (event.data.percentage >= 100 ? 100 : event.data.percentage)
          }
          timeLeft.value = event.data.left
          break
      }
    })

    userBus.on((event) => {
      if (event.event === USER_LOGOUT) {
        if (player && player.isPlaying) {
          player.stop()
        }
        store.commit('music/setActiveTrackUnload')
        player.stopAll()
      }
    })

    sceneBus.on((event) => {
      if (event.event === SCENE_VOLUME_CHANGE) {
        masterVolumeValue.value = store.getters['scene/volume'] * 100
      }
      if (event.event === SCENE_CARD_LOADED) {
        musicVolumeValue.value = store.getters['scene/musicVolume'] * 100
        masterVolumeValue.value = store.getters['scene/volume'] * 100
        masterVolume.value = store.getters['scene/volume'] * 100
      }
      if (event.event === SCENE_STOP) {
        musicVolumeValue.value = store.getters['music/musicVolume'] * 100
        masterVolumeValue.value = store.getters['music/masterVolume'] * 100
        masterVolume.value = store.getters['music/masterVolume'] * 100
      }
    })

    const mute = () => {
      store.commit('music/setMuted')
      player.masterMute()
    }

    onKeyStroke('ArrowRight', (e) => {
      player.seekForward10s()
    })
    onKeyStroke('ArrowLeft', (e) => {
      player.seekBackward10s()
    })
    onKeyStroke('ArrowUp', (e) => {
      player.volumeUp()
    })
    onKeyStroke('ArrowDown', (e) => {
      player.volumeDown()
    })

    watchEffect(() => {
      if (ctrl.value && m.value) {
        mute()
      }
      if (ctrl.value && ь.value) {
        mute()
      }
    })

    return {
      isVolumeHovered,
      masterVolumeValue,
      masterVolume,
      mute,
      isMuted,
      musicSeekValue,
      musicVolumeValue,
      timeLeft,
      formatTime,
      activeTrack,
      scrollToTrack: () => { bus.emit({ event: SCROLL_TO_TRACK }) },
      play: () => {
        if (player.isPlaying) {
          store.commit('music/setActiveTrackPause')
        } else {
          store.commit('music/setActiveTrackResume')
        }
      },
      shuffle: () => store.commit('music/setMusicShuffle'),
      forward: () => {
        player.forward()
      },
      backward: () => player.backward(),
      repeat: () => store.commit('music/setMusicLoop'),
      musicSeekMove: () => {
        player.seek(musicSeekValue.value)
        isMusicSeekLock.value = false
      },
      musicVolumeMove: () => {
        player.changeVolume(musicVolumeValue.value / 100)
      },
      masterVolumeMove: () => {
        player.changeMasterVolume(masterVolumeValue.value / 100)
      },
      musicSeekLock: () => {
        isMusicSeekLock.value = true
      },
      isPlaying: () => {
        if (!activeTrack.track) {
          return require('@/assets/img/icon/player/ic_play_music.svg')
        }
        if (activeTrack.isPlaying) {
          return require('@/assets/img/icon/player/ic_pause_music.svg')
        }
        return require('@/assets/img/icon/player/ic_play_music.svg')
      },
      isRepeat: () => {
        if (store.getters['music/isLoop']) {
          return require('@/assets/img/icon/player/ic_repeat_active.svg')
        } else {
          return require('@/assets/img/icon/player/ic_repeat_gray.svg')
        }
      },
      isShuffle: () => {
        if (store.getters['music/isShuffle']) {
          return require('@/assets/img/icon/player/ic_shuffle_active.svg')
        } else {
          return require('@/assets/img/icon/player/ic_shuffle_gray.svg')
        }
      },
      defineVolumeIcon: (isVolumeHovered) => {
        if (!isVolumeHovered) {
          return require('@/assets/img/icon/player/ic_volume.svg')
        } else {
          return require('@/assets/img/icon/player/ic_volume_active.svg')
        }
      },
      mouseVolumeOver: () => {
        isVolumeHovered.value = true
      },
      mouseVolumeLeave: () => {
        isVolumeHovered.value = false
      },
      switchSideInfo: () => {
        sideInfoBlock.value = !sideInfoBlock.value
        appBus.emit({ event: APP_SIDE_INFO, data: sideInfoBlock.value })
      },
      defineMenuIcon: () => {
        if (sideInfoBlock.value) {
          return require('@/assets/img/icon/player/ic_menu_active.svg')
        } else {
          return require('@/assets/img/icon/player/ic_menu.svg')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .player {
    position: relative;
    background-color: $player-bg;
    height: 100%;
    width: 100%;
    padding: .5rem 4rem 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    flex: 1;
    .info {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1.6rem;
      .loading {
        margin-top: 1rem;
        transition: opacity ease-out .2s;
      }
      .track {
        user-select: none;
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &__name {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: $white_dirty;
          max-width: 40rem;
          img {
            margin-left: 1rem;
            width: 2rem;
            height: 2rem;
            &:hover {
              cursor: pointer;
              opacity: .7;
            }
            &:active {
              cursor: pointer;
              opacity: .5;
            }
          }
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        &__author {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1rem;
          color: $second-text-2;
          margin-top: .6rem;
          opacity: .6;
        }
        &__label {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1rem;
          color: $second-text-2;
          margin-top: 1rem;
          opacity: .6;
          text-transform: uppercase;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .progress {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.4rem;
      &__controls {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.2rem;
        .shuffle {
          -webkit-user-drag: none;
          &:hover {
            cursor: pointer;
            opacity: .7;
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
        .backward {
          -webkit-user-drag: none;
          &:hover {
            cursor: pointer;
            opacity: .7;
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
        .play {
          -webkit-user-drag: none;
          &:hover {
            cursor: pointer;
            opacity: .7;
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
        .forward {
          -webkit-user-drag: none;
          &:hover {
            cursor: pointer;
            opacity: .7;
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
        .repeat {
          -webkit-user-drag: none;
          &:hover {
            cursor: pointer;
            opacity: .7;
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
      }
      &__progress {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2.2rem;
        .slider {
          width: 51.6rem;
        }
        .time-start {
          margin-top: .5rem;
          user-select: none;
          width: 2.2rem;
          font-weight: 600;
          color: $white-text;
          font-size: 1.2rem;
          line-height: 2rem;
        }
        .time-end {
          margin-top: .5rem;
          user-select: none;
          font-weight: 600;
          color: $white-text;
          font-size: 1.2rem;
          line-height: 2rem;
        }
      }
      &.disabled {
        opacity: .2;
        gap: 3.5rem;
        .slider {
          width: 51.6rem;
          margin-top: -1.2rem;
          background-color: $white;
          height: .4rem;
          border-radius: $radius;
        }
      }
    }
    .controls {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 1.6rem;
      &__master_volume {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 4.5rem;
        left: -1rem;
        background-color: $selected_dd;
        border-radius: $radius;
        height: 12rem;
        width: 4rem;
        padding: 1rem 1.5rem;
        box-shadow: $shadow;
        span {
          position: absolute;
          top: 1rem;
          left: 50%;
          transform: translateX(-50%);
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.8rem;
          margin-bottom: 1rem;
          color: $white-text;
        }
        .vt-slider {
          width: 8rem;
          margin-top: 2.2rem;
        }
      }
      &__volume {
        margin-right: -.5rem;
        margin-top: -1rem;
        padding: 2rem 0 1rem 0;
        &.muted {
          opacity: .3;
        }
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
      &__volume_bar {
        margin-top: -0.4rem;
        width: 8.2rem;
      }
      .menu-icon {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .player {
      .controls {
        .menu-icon {
          display: flex;
        }
      }
    }
  }

</style>
