const identityRoutes = [
  {
    path: '/sign-in',
    name: 'signIn',
    meta: { layout: 'auth' },
    component: () => import('@/modules/identity/pages/SignIn')
  },
  {
    path: '/sign-up',
    name: 'signUp',
    meta: { layout: 'auth' },
    component: () => import('@/modules/identity/pages/SignUp')
  },
  {
    path: '/restore',
    name: 'restore',
    meta: { layout: 'auth' },
    component: () => import('@/modules/identity/pages/Restore')
  },
  {
    path: '/new-password',
    name: 'newPassword',
    meta: { layout: 'auth' },
    component: () => import('@/modules/identity/pages/NewPassword')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: () => import('@/modules/identity/pages/Profile')
  }
]

export default identityRoutes
