<template>
  <div class="relative">
    <div :class="{ expand: isExpandedTags }" class="tags-wrapper">
      <div v-if="tags.length > 0" class="tags">
        <div
          :class="{ active: isSelected(tag) }"
          @click="$emit('choose', { tag })"
          class="tag"
          v-for="tag in tags"
          :key="tag.id"
        >{{ capitalize(tag.name) }}</div>
      </div>
      <div v-else class="cap">{{ empty }}</div>
      <div
        @click="expandTags"
        class="tag-dropdown"
        :class="{ expand: isExpandedTags, active: _selectedTags.length > 0 }"
      >
        <img :src="defineDDIcon()" alt="">
        <!--      <div v-else class="count">{{ _selectedTags.length }}</div>-->
      </div>
    </div>
    <Transition>
      <div v-if="isExpandedTags" class="tags-wrapper-expand">
        <div class="tags">
          <div
            :class="{ active: isSelected(tag) }"
            @click="$emit('choose', { tag })"
            class="tag"
            v-for="tag in tags"
            :key="tag.id"
          >{{ capitalize(tag.name) }}</div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { capitalize } from '@/modules/shared/utils/capitalize'

export default {
  emits: ['choose'],
  props: ['_tags', '_selectedTags', 'empty'],
  setup (props, { emit }) {
    const tags = computed(() => props._tags)
    const selectedTags = computed(() => props._selectedTags)
    // const selectedTags = ref([])
    const isExpandedTags = ref(false)

    return {
      capitalize,
      isExpandedTags,
      tags,
      isSelected: (tag) => {
        return selectedTags.value.filter((item) => item.id === tag.id).length > 0
      },
      expandTags: () => { isExpandedTags.value = !isExpandedTags.value },
      defineDDIcon: () => {
        if (selectedTags.value.length > 0) {
          return require('@/assets/img/icon/ic_dropdown_arrow_active.svg')
        } else {
          return require('@/assets/img/icon/ic_dropdown_arrow.svg')
        }
      },
      choose: (tag) => {
        emit('choose', { tag })
        if (selectedTags.value.find((item) => item.id === tag.id)) {
          selectedTags.value.splice(selectedTags.value.indexOf(tag), 1)
          return
        }
        selectedTags.value.push(tag)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .tags-wrapper {
    position: relative;
    display: flex;
    max-height: 3.1rem;
    justify-content: space-between;
    overflow: hidden;
    transition: opacity ease-out 2s;
    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: .8rem;
      width: 100%;
      .tag {
        padding: .6rem 1.2rem;
        background: rgba(53, 55, 57, 0.6);
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: $second-text-2;
        border-radius: $radius;
        user-select: none;
        transition: all ease-out .2s;
        &.active {
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.8rem;
          background-color: $selected_dd;
          color: $white-90;
          transition: all ease-out .2s;
        }
        &:hover {
          cursor: pointer;
          color: $white;
          transition: all ease-out .2s;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
    }
    .cap {
      width: 100%;
      font-size: 1.2rem;
      color: $second-text-2;
      font-weight: 500;
      opacity: .7;
    }
    .tag-dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 4.3rem;
      height: 3.1rem;
      background-color: $selected_dd;
      border-radius: $radius;
      border: 1px solid transparent;
      transition: all ease-out .2s;
      outline: none;
      color: $primary;
      font-size: 1.2rem;
      font-weight: 600;
      img {
        transition: all ease-out .2s;
        width: 1.9rem;
        height: 1.9rem;
        -webkit-user-drag: none;
        user-select: none;
      }
      &:hover {
        cursor: pointer;
        opacity: .7;
      }
      &:active {
        cursor: pointer;
        opacity: .5;
      }
      &.expand {
        img {
          transition: all ease-out .2s;
          transform: rotate(180deg);
        }
      }
      &.active {
        //background-color: rgba(242, 120, 68, 0.7);
        //border: 1px solid $primary;
        //background-color: transparent;
        //transition: all ease-out .2s;
        //outline: none;
      }
    }
    &.expand {
    }
  }
  .tags-wrapper-expand {
    position: absolute;
    background-color: $selected_dd;
    margin-right: 6.4rem;
    border-radius: $radius;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    min-height: 3.1rem;
    overflow: hidden;
    box-shadow: $shadow;
    padding: 1.5rem;
    width: calc(100% - 6rem);
    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .tag {
        padding: .6rem 1.2rem;
        background: $card;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: $second-text-2;
        border-radius: $radius;
        &.active {
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.8rem;
          background-color: $selected_dd;
          color: rgba(255, 255, 255, 0.9);
        }
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
    }
  }
</style>
