export const scene = {
  scenes: () => '/scene/scenes',
  serviceScenes: () => '/scene/scenes/service',
  sceneCard: (sceneId) => `/scene/scene/${sceneId}`,
  userScenes: () => '/scene/scenes/user',
  favorites: () => 'scene/scenes/user/favorite',
  tags: () => '/scene/tags',
  addToFavorite: (sceneId) => `/scene/scene/${sceneId}/favorite`,
  removeFromFavorite: (favoriteId) => `/scene/scene/${favoriteId}/favorite`,
  history: (sceneId) => `/scene/scene/${sceneId}/history`
}
