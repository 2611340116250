<template>
  <div class="page-wrapper">
    <div class="video-block">
      <AppVideoPlayer class="video"/>
      <div class="search">
        <AppSearch v-model="search" placeholder="Введите название видео"/>
      </div>
      <div class="tags-block">
        <AppTagsLine
          :_tags="videoTags"
          :_selected-tags="selectedTags"
          @choose="chooseTag"
        />
      </div>
      <div class="video-playlist scrollable scrollbar">
        <div
          :class="{ active:  activeVideo.video ? activeVideo.video.id === video.id : ''}"
          class="item"
          v-for="video in videos"
          :key="video.id"
          @click="chooseVideo(video)"
        >
          <img :src="videoPreview(video)" alt="">
          <div class="item__title">{{ video.title }}</div>
        </div>
      </div>
    </div>
    <div class="widget-block">
      <TheMusicWidget />
    </div>
    <Transition name="slide">
      <div v-if="sideInfoBlock" class="info-block">
        <Transition name="d01">
          <div v-if="discounts.length > 0" class="info-block__sales">
            <div v-for="item in discounts" :key="item.id">
              <AppDiscountCard @action="closeDiscount" :item="item"/>
            </div>
          </div>
        </Transition>
        <div class="info-block__playlists">
          <div class="info-block__playlists_title">Плейлисты на ваш вкус</div>
          <div class="info-block__playlists_items">
            <div
              v-for="playlist in definePlaylistCount(serviceMusicPlaylists.filter((item) => item.audioType === 'MUSIC'))"
              :key="playlist.id"
              class="item"
              @click="selectPlaylist(playlist)"
            >
              <img class="item__preview" :src="playlistPreview(playlist)" alt="">
              <img class="item__play" src="@/assets/img/icon/player/ic_play_music.svg" alt="">
              <div class="item__title">{{ playlist.name }}</div>
            </div>
          </div>
        </div>
        <div class="info-block__artists">
          <div class="info-block__artists_title">Похожие исполнители</div>
          <div class="info-block__artists_items" :class="{more: defineArtistCount(musicArtists).length > 4}">
            <div
              v-for="artist in musicArtists"
              :key="artist.id"
              class="item"
              @click="selectArtist(artist)"
            >
              <div class="item__icon"></div>
              <div class="item__title"> {{ artist.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import TheMusicWidget from '@/modules/music/components/music/TheMusicWidget'
import AppSearch from '@/components/ui/search/AppSearch'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { capitalize } from '@/modules/shared/utils/capitalize'
import AppVideoPlayer from '@/modules/video/components/AppVideoPlayer'
import { useEventBus, watchDebounced } from '@vueuse/core'
import { PLAYLIST_SELECTED } from '@/modules/playlist/event'
import { TRACK_CHANGED } from '@/modules/music/event'
import AppDiscountCard from '@/modules/billing/components/AppDiscountCard'
import { APP_SIDE_INFO } from '@/modules/app/event'
import AppTagsLine from '@/components/ui/tags/AppTagsLine'

export default {
  components: { AppTagsLine, AppDiscountCard, AppVideoPlayer, AppSearch, TheMusicWidget },

  setup () {
    const store = useStore()
    const bus = useEventBus('playlist')
    const appBus = useEventBus('app')
    const musicBus = useEventBus('music')
    const activeVideo = computed(() => store.getters['video/activeVideo'])
    const videoTags = computed(() => store.getters['video/videoTags'])
    const videos = computed(() => store.getters['video/videos'])
    const selectedTags = store.getters['video/selectedTags']
    const search = ref('')
    const serviceMusicPlaylists = computed(() => store.getters['playlist/serviceMusicPlaylists'])
    const musicArtists = computed(() => store.getters['music/artists'])
    const discounts = computed(() => store.getters['billing/discounts'])
    const sideInfoBlock = ref(true)

    musicBus.on((event) => {
      if (event.event === TRACK_CHANGED) {
        store.commit('playlist/shuffleServicePlaylists')
        store.commit('music/shuffleMusicArtists')
      }
    })

    appBus.on((event) => {
      if (event.event === APP_SIDE_INFO) {
        sideInfoBlock.value = event.data
      }
    })

    watchDebounced(search, () => {
      store.dispatch('video/loadVideo', { search: search.value })
    }, { debounce: 1000, maxWait: 5000 })

    return {
      sideInfoBlock,
      discounts,
      musicArtists,
      serviceMusicPlaylists,
      activeVideo,
      capitalize,
      search,
      selectedTags,
      videos,
      videoTags,
      videoPreview: (video) => {
        return `${process.env.VUE_APP_BASE_URL}${video.preview}`
      },
      chooseVideo: (newVideo) => {
        store.commit('video/setActiveVideo', { video: newVideo, isInit: false })
      },
      chooseTag: (tag) => {
        store.commit('video/addTag', tag.tag)
        store.dispatch('video/loadVideo', {})
      },
      playlistPreview: (playlist) => {
        return `${process.env.VUE_APP_BASE_URL}/storage/${playlist.cover}`
      },
      selectPlaylist: (item) => {
        store.commit('playlist/setActiveMusicPlaylist', item)
        bus.emit({ event: PLAYLIST_SELECTED, data: { playlist: item, replace: false } })
      },
      definePlaylistCount: (playlists) => {
        if (discounts.value.length > 0) {
          return playlists.slice(0, 4)
        }
        return playlists.slice(0, 6)
      },
      defineArtistCount: (artist) => {
        if (discounts.value.length > 0) {
          return artist.slice(0, 4)
        }
        return artist.slice(0, 6)
      },
      selectArtist: (item) => {
        store.dispatch('music/loadMusic', { artists: [item.id] })
      },
      closeDiscount: (item) => {
        store.commit('billing/removeDiscount', item.discountId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .page-wrapper {
    display: grid;
    grid-template-columns: 65rem 42.8rem 1fr;
    grid-template-rows: 1fr;
    column-gap: 3rem;
    height: 100%;
    user-select: none;
    padding: 0 4rem 0 4rem;
    .video-block {
      .video {

      }
      .video-playlist {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 3rem;
        row-gap: 3rem;
        margin-top: 3rem;
        height: 32.5vh;
        margin-right: -1.6rem;
        padding-right: 1.6rem;
        &.scrollbar::-webkit-scrollbar {
          width: 0.4rem;
          background-color: rgba(53, 55, 57, 0.6);
          position: absolute;
        }
        &.scrollbar::-webkit-scrollbar-track {
          width: 0.4rem;
          border-radius: .2rem;
          background-color: rgba(53, 55, 57, 0.6);
          opacity: .4;
        }
        &.scrollbar::-webkit-scrollbar-thumb {
          border-radius: .2rem;
          background-color: rgba(29, 29, 29, 0.6);
        }
        .item {
          position: relative;
          transition: all ease-out .2s;
          width: 14rem;
          height: 14rem;
          img {
            position: relative;
            -webkit-user-drag: none;
            width: 100%;
            height: 100%;
            border-radius: $radius-20;
            opacity: .7;
            filter: brightness(60%) contrast(90%);
            object-fit: cover;
            transition: all ease-out .3s;
          }
          &__title {
            position: absolute;
            bottom: 1.8rem;
            width: 100%;
            text-align: center;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.2rem;
            color: $white-80;
            padding: 0 1rem;
            z-index: 2;
          }
          &:hover {
            cursor: pointer;
            img {
              transition: all ease-out .3s;
              opacity: 1;
              filter: brightness(130%) contrast(100%);
            }
          }
          &:active {
            cursor: pointer;
            opacity: .7;
          }
          &.active {
            img {
              transition: all ease-out .3s;
              opacity: 1;
              filter: brightness(130%) contrast(100%);
            }
          }
          &.active:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: $radius-20;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 10%, transparent 60%);
          }
        }
      }
      .search {
        margin-top: 4rem;
      }
      .tags-block {
        position: relative;
        width: 100%;
        margin-top: 1.6rem;
        .video-tags {
          position: relative;
          display: flex;
          max-height: 3.1rem;
          overflow: hidden;
          transition: opacity ease-out 2s;
          .tags {
            display: flex;
            flex-wrap: wrap;
            gap: .8rem;
            .tag {
              padding: .6rem 1.2rem;
              background: rgba(53, 55, 57, 0.6);
              font-weight: 500;
              font-size: 1.2rem;
              line-height: 1.8rem;
              color: $second-text-2;
              border-radius: $radius;
              &.active {
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1.8rem;
                background-color: $selected_dd;
                color: $white-90;
              }
              &:hover {
                cursor: pointer;
                opacity: .7;
              }
              &:active {
                cursor: pointer;
                opacity: .5;
              }
            }
          }
          .tag-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 4.3rem;
            height: 3.1rem;
            background-color: $selected_dd;
            border-radius: $radius;
            img {
              transition: all ease-out .2s;
              width: 1.9rem;
              height: 1.9rem;
            }
            &:hover {
              cursor: pointer;
              opacity: .7;
            }
            &:active {
              cursor: pointer;
              opacity: .5;
            }
            &.expand {
              img {
                transition: all ease-out .2s;
                transform: rotate(180deg);
              }
            }
          }
          &.expand {
          }
        }
        .video-tags-expand {
          position: absolute;
          background-color: $selected_dd;
          margin-right: 6.4rem;
          border-radius: $radius;
          top: 0;
          left: 0;
          z-index: 3;
          display: flex;
          min-height: 3.1rem;
          overflow: hidden;
          box-shadow: $shadow;
          padding: 1.5rem;
          .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .tag {
              padding: .6rem 1.2rem;
              background: $card;
              font-weight: 500;
              font-size: 1.2rem;
              line-height: 1.8rem;
              color: $second-text-2;
              border-radius: $radius;
              &.active {
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1.8rem;
                background-color: $selected_dd;
                color: rgba(255, 255, 255, 0.9);
              }
              &:hover {
                cursor: pointer;
                opacity: .7;
              }
              &:active {
                cursor: pointer;
                opacity: .5;
              }
            }
          }
        }
      }
    }
    .widget-block {
      min-height: 78vh;
      max-height: 80vh;
      //height: 100%;
      //padding-bottom: 2rem;
      //transform: translateX(-1rem);
    }
    .info-block {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
      &__sales {
        //position: absolute;
        //top: 0;
        //right: 0;
        margin-bottom: 4rem;
      }
      &__playlists {
        &_title {
          color: $white-text;
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-weight: 700;
        }
        &_items {
          margin-top: 2.5rem;
          display: flex;
          flex-wrap: wrap;
          column-gap: 3.1rem;
          row-gap: 2.4rem;
          & .item {
            position: relative;
            overflow: hidden;
            border-radius: 2.4rem;
            width: 20rem;
            height: 12rem;
            box-shadow: $shadow;
            transition: all ease-out .3s;
            &:hover {
              .item__preview {
                opacity: 1;
                filter: none;
                transition: all ease-out .3s;
                cursor: pointer;
              }
              .item__play {
                opacity: 1;
              }
            }
            &:active {
              opacity: .5;
            }
            &__title {
              position: absolute;
              left: 0;
              width: 100%;
              text-align: center;
              bottom: 1.5rem;
              padding: 0 1.5rem;
              font-size: 1.4rem;
              line-height: 1.4rem;
              font-weight: 500;
              color: $white-text;
              opacity: .85;
              &:hover {
                cursor: pointer;
              }
            }
            &__preview {
              -webkit-user-drag: none;
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: .9;
              filter: brightness(60%) contrast(90%);
              transition: all ease-out .3s;
            }
            &__play {
              -webkit-user-drag: none;
              opacity: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 1;
              transform: translate(-50%, -50%);
              transition: all ease-out .3s;
              @include default-hover-active;
            }
          }
        }
      }
      &__artists {
        margin-top: 4rem;
        &_title {
          color: $white-text;
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-weight: 700;
        }
        &_items {
          display: flex;
          flex-wrap: wrap;
          margin-top: 2.5rem;
          column-gap: 1.6rem;
          row-gap: 1.6rem;
          max-height: 12vh;
          overflow: hidden;
          &.more {
            max-height: 25vh;
          }
          & .item {
            height: 5rem;
            min-width: 10rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: $selected_dd;
            border-radius: $radius;
            padding: 0 1.6rem;
            &:hover {
              cursor: pointer;
              .item__title {
                color: $white-text;
                transition: all ease-out .3s;
              }
              .item__icon {
                background-image: url("@/assets/img/icon/menu/ic_music.svg");
                transition: all ease-out .3s;
              }
            }
            &:active {
              opacity: .5;
            }
            &__title {
              color: $second-text-2;
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 1.4rem;
              margin-left: 1rem;
              transition: all ease-out .3s;
            }
            &__icon {
              width: 2.2rem;
              height: 2.2rem;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: url("@/assets/img/icon/menu/ic_music_gray.svg");
              transition: all ease-out .3s;
            }
          }
        }
      }
      &:before {
        position: absolute;
        top: -25%;
        left: -3rem;
        content: '';
        height: 150vh;
        width: 0.2rem;
        background-color: rgba(33, 33, 33, 0.3);
        z-index: 1;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .page-wrapper {
      position: relative;
      grid-template-columns: 65rem 43rem;
      .widget-block {
        transform: translateX(0);
      }
      .info-block {
        position: absolute;
        top: -3rem;
        right: -3rem;
        width: 51.5rem;
        background-color: $widget-bg;
        padding: 3rem 4rem;
        border-top-left-radius: $radius-20;
        border-bottom-left-radius: $radius-20;
        z-index: 2;
        height: 100vh;
        box-shadow: $shadow;
        &:before {
          display: none;
        }
      }
    }
  }
</style>
