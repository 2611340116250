<template>
  <div v-if="isOpenHelp" class="help-card">
    <div class="help-card__title">Помощь<span @click="close()" style="height: 2.4rem; width: 2.4rem" class="card__close"/></div>
    <div @click="openPlayVideo()" class="help-card__video-cap">
      <div class="overlay"></div>
      <div class="info">
        <span class="info__title">Что такое Zenplace?</span>
        <span class="info__subtitle">Короткое видео обо всех фишках сервиса</span>
      </div>
      <img class="preview" src="@/assets/img/shared/help_preview.jpg" alt="">
      <img class="icon" src="@/assets/img/icon/player/ic_play_video.svg" alt="">
    </div>
    <div class="help-card__info-block scrollbar scrollable">
      <div class="help-card__info-block__title">Юридическая информация</div>
      <div class="help-card__info-block__policy">
        <a :href="openUserPolicy()" target="_blank" class="item">
          <span>Пользовательское соглашение</span>
          <a :href="openUserPolicy()" download="">
            <img src="@/assets/img/icon/ic_download.svg" alt="">
          </a>
        </a>
        <a :href="openPersonalPolicy()" target="_blank" class="item">
          <span>Политика обработки персональных данных</span>
          <a :href="openPersonalPolicy()" download="">
            <img src="@/assets/img/icon/ic_download.svg" alt="">
          </a>
        </a>
        <a :href="openPaymentsPolicy()" target="_blank" class="item">
          <span>Оферта рекуррентных платежей</span>
          <a :href="openPaymentsPolicy()" download="">
            <img src="@/assets/img/icon/ic_download.svg" alt="">
          </a>
        </a>
      </div>
      <div class="help-card__info-block__title">Информация для правообладателей</div>
      <div class="copyright-holder">
        Музыкальные треки, видео и звуки, размещенные в сервисе Zenplace, используются в соответствии с лицензией CC BY
        и CC BY-SA. Если вы правообладатель или его уполномоченный представитель и считаете, что использование
        какого-либо музыкального трека, видео или звука в сервисе Zenplace нарушает интеллектуальные права, напишите в
        чат поддержки или на эл.почту copyright@zenplace.ru
      </div>
      <AppButton
        class="mt-2"
        color="primary"
        size="sm"
        textColor="primary"
        view="outline"
        @click="openModal()"
      >Сообщить о нарушении</AppButton>
    </div>
    <Teleport to="body">
      <Transition name="d02">
        <AppModal
          v-if="modalIsOpen"
          title="Сообщить о нарушении"
          @close="modalIsOpen = !modalIsOpen"
          width="40"
          height=""
        >
        <AppRightsViolationForm @completed="modalIsOpen = !modalIsOpen"/>
        </AppModal>
      </Transition>
    </Teleport>
    <Teleport to="body">
      <Transition name="d02">
        <VideoModal
          v-if="videoModalIsOpen"
          width="120"
          height=""
          video-url="/video/help_video.mp4"
          @close="videoModalIsOpen = !videoModalIsOpen"
        >
        </VideoModal>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import AppButton from '@/components/ui/button/AppButton'
import AppModal from '@/components/ui/modal/AppModal'
import AppRightsViolationForm from '@/modules/help/components/card/AppRightsViolationForm'
import VideoModal from '@/components/ui/modal/VideoModal'

export default {
  components: { VideoModal, AppRightsViolationForm, AppModal, AppButton },
  setup () {
    const store = useStore()

    const isOpenHelp = computed(() => store.getters['help/helpOpen'])
    const modalIsOpen = ref(false)
    const videoModalIsOpen = ref(false)

    return {
      modalIsOpen,
      videoModalIsOpen,
      isOpenHelp,
      openUserPolicy: () => {
        return `${process.env.VUE_APP_BASE_URL}/docs/user-policy.pdf`
      },
      openPersonalPolicy: () => {
        return `${process.env.VUE_APP_BASE_URL}/docs/personal-policy.pdf`
      },
      openPaymentsPolicy: () => {
        return `${process.env.VUE_APP_BASE_URL}/docs/payment-policy.pdf`
      },
      openModal: () => {
        modalIsOpen.value = !modalIsOpen.value
      },
      openPlayVideo: () => {
        videoModalIsOpen.value = !videoModalIsOpen.value
      },
      close: () => {
        store.commit('help/setHelpOpen', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .help-card {
    position: absolute;
    bottom: 0;
    left: 24rem;
    width: 58rem;
    min-height: 100%;
    margin-top: 10rem;
    z-index: 21;
    display: flex;
    flex-direction: column;
    background-color: $widget-bg;
    padding: 3.2rem 3rem 4rem 4rem;
    box-shadow: 1rem 0 1rem rgba(0, 0, 0, 0.15);
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $white;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.4rem;
      .card__close {
        margin-right: .5rem;
        @include close;
      }
    }
    &__video-cap {
      margin-top: 4.2rem;
      position: relative;
      width: calc(100% - 1rem);
      height: 24rem;
      overflow: hidden;
      box-shadow: $shadow;
      background-color: rgba(61, 61, 63, 0.4);
      border-radius: $radius-20;
      z-index: 1;
      margin-bottom: 4rem;
      & .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 25%, transparent 85%);
        z-index: 2;
      }
      & .info {
        position: absolute;
        left: 4rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        gap: 2rem;
        z-index: 3;
        &__title {
          color: $white;
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 0;
        }
        &__subtitle {
          color: $white;
          font-size: 0.8rem;
          font-weight: 700;
          line-height: 0;
        }
      }
      & .preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 2.8rem;
        height: 2.8rem;
        z-index: 4;
        @include default-hover-active;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &__info-block {
      max-height: 58rem;
      @include scrollbar-light;
      &__title {
        color: $white;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.4rem;
      }
      &__policy {
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 3rem;
        margin-right: 1rem;
        & .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 6rem;
          width: 100%;
          background-color: rgba(61, 61, 63, 0.40);
          border: 1px solid $dark-base-bg-2;
          border-radius: $radius;
          padding: 0 2rem;
          color: $white;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2.4rem;
          text-decoration: none;
          @include default-hover-active;
          img {
            margin-top: .6rem;
            width: 2rem;
            height: 2rem;
            @include default-hover-active;
          }
        }
      }
      & .copyright-holder {
        margin-top: 2rem;
        color: $white-80;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.4rem;
      }
    }
    & .videoModal {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //background-color: rgba(36, 36, 39, 0.80);
      background-color: black;
      z-index: 100;
    }
  }
</style>
