import ApiClient from '@/modules/shared/utils/api'
import { scene } from '@/modules/scene/repository'
import {
  SCENE_CARD_LOADED,
  SCENE_FAVORITE_LOADED,
  SCENE_PAUSE,
  SCENE_PLAYING,
  SCENE_RESUME,
  SCENE_SELECTED,
  SCENE_STOP,
  SCENE_TAGS_LOADED,
  SCENE_VIDEO_RELOAD,
  SCENE_VIDEO_SELECTED, SCENE_VOLUME_CHANGE,
  SCENES_LOADED,
  SCENES_SERVICE_LOADED
} from '@/modules/scene/event'
import { useEventBus } from '@vueuse/core'
import { shuffle } from '@/modules/shared/utils/shuffle'

const bus = useEventBus('scene')

const SCENES_CONFIG = 'scenes-config'

export default {
  namespaced: true,
  state: {
    scenesConfig: JSON.parse(localStorage.getItem(SCENES_CONFIG)) || [],
    activeScene: { scene: null, isPlaying: false, status: null },
    activePlaylist: null,
    activeVideo: {
      video: null,
      isPlaying: false
    },
    activeTrack: { track: null, isPlaying: false, isLoading: false },
    scenes: [],
    serviceScenes: [],
    userScenes: [],
    favoriteScenes: [],
    music: [],
    sounds: [],
    volume: 0.8,
    musicVolume: 0.8,
    sceneTags: [],
    selectedTags: [],
    selectedCategories: [],
    tracks: null
  },
  getters: {
    activeScene (state) {
      return state.activeScene
    },
    activePlaylist (state) {
      return state.activePlaylist
    },
    activeVideo (state) {
      return state.activeVideo
    },
    activeTrack (state) {
      return state.activeTrack
    },
    scenes (state) {
      return state.scenes
    },
    serviceScenes (state) {
      return shuffle(state.serviceScenes)
    },
    userScenes (state) {
      return state.userScenes
    },
    favoriteScenes (state) {
      return state.favoriteScenes
    },
    sceneTags (state) {
      return state.sceneTags
    },
    selectedTags (state) {
      return state.selectedTags
    },
    selectedCategories (state) {
      return state.selectedCategories
    },
    tracks (state) {
      return shuffle(state.tracks)
    },
    music (state) {
      return state.music
    },
    sounds (state) {
      return state.sounds
    },
    volume (state) {
      return state.volume
    },
    musicVolume (state) {
      return state.musicVolume
    },
    scenesConfig (state) {
      return state.scenesConfig
    }
  },
  mutations: {
    setScenes (state, scenes) {
      state.scenes = scenes
    },
    setServiceScenes (state, scenes) {
      state.serviceScenes = scenes
    },
    setUserScenes (state, scenes) {
      state.userScenes = scenes
    },
    setFavoriteScenes (state, scene) {
      state.favoriteScenes = scene
    },
    setActiveScene (state, scene) {
      state.activeScene.scene = scene
      const scenesConfig = state.scenesConfig
      const sceneIndex = scenesConfig.findIndex((item) => item.id === scene.id)

      if (sceneIndex !== -1) {
        const config = scenesConfig[sceneIndex]
        state.activeScene.scene.masterVolume = config.volume
        state.activeScene.scene.musicVolume = config.musicVolume
        state.activeScene.scene.soundsVolume.forEach((sound) => {
          const finded = config.soundsVolume.find((item) => item.id === sound.id)
          if (finded) {
            sound.volume = finded.volume
          }
        })
      }

      state.volume = state.activeScene.scene.masterVolume
      state.musicVolume = state.activeScene.scene.musicVolume
      state.activeScene.status = 'ready'
      state.activeScene.isPlaying = false
      state.activeScene.scene.masterVolume = state.activeScene.scene.masterVolume * 100
      bus.emit({ event: SCENE_SELECTED })
    },
    setActivePlaylist (state, playlist) {
      state.activePlaylist = playlist
      state.tracks = playlist.musicIds
    },
    setActiveVideo (state, payload) {
      state.activeVideo.video = payload.video
      if (payload.isInit) {
        state.activeVideo.video.isPlaying = false
        bus.emit({ event: SCENE_VIDEO_RELOAD })
      } else {
        state.activeVideo.video.isPlaying = true
        bus.emit({ event: SCENE_VIDEO_SELECTED })
      }
    },
    setSounds (state, sounds) {
      state.sounds = sounds
    },
    setTags (state, tags) {
      state.sceneTags = tags
    },
    addTag (state, tag) {
      if (state.selectedTags.find((item) => item.id === tag.id)) {
        state.selectedTags.splice(state.selectedTags.indexOf(tag), 1)
        return
      }
      state.selectedTags.push(tag)
    },
    setSelectedTag (state, tags) {
      state.selectedTags = tags
    },
    setSelectedCategories (state, tags) {
      state.selectedCategories = tags
    },
    setActiveScenePlaying (state) {
      state.activeScene.isPlaying = true
      state.activeScene.status = 'play'
      bus.emit({ event: SCENE_PLAYING })
    },
    setActiveSceneStop (state) {
      // state.activeScene.isPlaying = false
      // state.activeScene.status = 'stop'
      bus.emit({ event: SCENE_STOP })
    },
    setActiveScenePause (state) {
      state.activeScene.isPlaying = false
      state.activeScene.status = 'pause'
      bus.emit({ event: SCENE_PAUSE })
    },
    setActiveSceneResume (state) {
      state.activeScene.isPlaying = true
      state.activeScene.status = 'play'
      bus.emit({ event: SCENE_RESUME })
    },
    putSoundVolumeToSceneConfig (state, payload) {
      const configs = state.scenesConfig
      const sceneIndex = configs.findIndex((item) => item.id === state.activeScene.scene.id)
      if (sceneIndex === -1) {
        const config = {
          id: state.activeScene.scene.id,
          volume: state.activeScene.scene.masterVolume,
          musicVolume: state.activeScene.scene.musicVolume,
          soundsVolume: [payload]
        }
        configs.push({ ...config })
      } else {
        const foundSound = configs[sceneIndex].soundsVolume.find((item) => item.id === payload.id)
        if (foundSound) {
          foundSound.volume = payload.volume
        } else {
          configs[sceneIndex].soundsVolume.push({ ...payload })
        }
      }
      localStorage.setItem(SCENES_CONFIG, JSON.stringify(configs))
    },
    setSceneVolume (state, volume) {
      state.volume = volume
      const payload = {
        id: state.activeScene.scene.id,
        volume: volume,
        musicVolume: state.activeScene.scene.musicVolume,
        soundsVolume: state.activeScene.scene.soundsVolume
      }
      const configs = state.scenesConfig
      const sceneIndex = configs.findIndex((item) => item.id === payload.id)
      if (sceneIndex === -1) {
        configs.push({ ...payload })
      } else {
        configs[sceneIndex] = payload
      }
      state.activeScene.scene.masterVolume = volume * 100
      localStorage.setItem(SCENES_CONFIG, JSON.stringify(configs))
      bus.emit({ event: SCENE_VOLUME_CHANGE })
    },
    setMusicVolume (state, volume) {
      state.musicVolume = volume
      const payload = {
        id: state.activeScene.scene.id,
        volume: state.activeScene.scene.masterVolume / 100,
        musicVolume: volume,
        soundsVolume: state.activeScene.scene.soundsVolume
      }
      const configs = state.scenesConfig
      const sceneIndex = configs.findIndex((item) => item.id === payload.id)
      if (sceneIndex === -1) {
        configs.push({ ...payload })
      } else {
        configs[sceneIndex] = payload
      }
      localStorage.setItem(SCENES_CONFIG, JSON.stringify(configs))
    }
  },
  actions: {
    async loadScenes ({ commit, dispatch }) {
      try {
        const params = {
          name: 'DESC'
        }
        const { data } = await ApiClient.get(scene.scenes(), { params })
        commit('setScenes', data.data)
        bus.emit({ event: SCENES_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadServiceScenes ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          statuses: 'ACTIVE',
          tags: payload.tags ? payload.tags.toString() : null,
          categories: payload.categories ? payload.categories.toString() : null
        }
        const { data } = await ApiClient.get(scene.serviceScenes(), { params })
        commit('setServiceScenes', data.data)
        bus.emit({ event: SCENES_SERVICE_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadFavoriteScenes ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          statuses: 'ACTIVE',
          tags: payload.tags ? payload.tags.toString() : null,
          categories: payload.categories ? payload.categories.toString() : null
        }
        const { data } = await ApiClient.get(scene.favorites(), { params })
        commit('setFavoriteScenes', data.data)
        bus.emit({ event: SCENE_FAVORITE_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadSceneTags ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          isFavorite: payload.isFavorite
        }
        const { data } = await ApiClient.get(scene.tags(), { params })
        commit('setTags', data.data)
        bus.emit({ event: SCENE_TAGS_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadSceneCard ({ commit, dispatch }, sceneId) {
      try {
        const params = {
          name: 'DESC'
        }
        const { data } = await ApiClient.get(scene.sceneCard(sceneId), { params })
        commit('setActiveScene', data.data)
        bus.emit({ event: SCENE_CARD_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async addToFavorite ({ commit, dispatch }, item) {
      try {
        await ApiClient.post(scene.addToFavorite(item.id), null)
        item.favoriteId = item.id
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async removeFromFavorite ({ commit, dispatch }, item) {
      try {
        await ApiClient.delete(scene.removeFromFavorite(item.id))
        item.favoriteId = null
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async saveSceneHistory ({ commit, dispatch }, payload) {
      try {
        if (payload.duration <= 5 || payload.duration > 7200) {
          return
        }
        const body = {
          duration: payload.duration
        }
        await ApiClient.post(scene.history(payload.scene.id), body)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  }
}
