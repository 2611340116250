import { createRouter, createWebHistory } from 'vue-router'
import store from '@/modules/app/store'
import Home from '@/modules/app/pages/lk/Home'
import identityRoutes from '@/modules/identity/router'
import landingRoutes from '@/modules/landing/router'
import billingRoutes from '@/modules/billing/router'
import Sound from '@/modules/app/pages/lk/Sound'
import Scene from '@/modules/app/pages/lk/Scene'
import Favorite from '@/modules/app/pages/lk/Favorite'
import Playlist from '@/modules/app/pages/lk/Playlist'
import Video from '@/modules/app/pages/lk/Video'
import MusicAndSounds from '@/modules/app/pages/lk/MusicAndSounds'

const routes = [
  ...identityRoutes,
  ...landingRoutes,
  ...billingRoutes,
  {
    path: '/home',
    name: 'home',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: Home
  },
  {
    path: '/music-sounds',
    name: 'music-sounds',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: MusicAndSounds
  },
  {
    path: '/sound',
    name: 'sound',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: Sound
  },
  {
    path: '/video',
    name: 'video',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: Video
  },
  {
    path: '/scene',
    name: 'scene',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: Scene
  },
  {
    path: '/favorite',
    name: 'favorite',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: Favorite
  },
  {
    path: '/playlists',
    name: 'playlists',
    meta: {
      layout: 'lk',
      auth: true
    },
    component: Playlist
  },
  {
    path: '/:notFound(.*)',
    name: 'notFound',
    meta: {
      layout: 'app',
      auth: false
    },
    component: () => import('@/modules/app/pages/NotFound')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth
  const isAuth = store.getters['auth/isAuthenticated']

  if (requireAuth && isAuth && store.dispatch('auth/checkIsTokenValid')) {
    if (to.name === 'landing' && isAuth) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else if (requireAuth && !isAuth && !store.dispatch('auth/checkIsTokenValid')) {
    next('/sign-in?message=auth')
  } else {
    if (to.name === 'landing' && isAuth) {
      next({ name: 'home' })
    } else {
      next()
    }
  }
})

export default router
