<template>
  <div ref="root">
    <div
      :ref="setItemRef"
      :class="[{ active:  activeTrack.track ? activeTrack.track.id === track.id : '' }, activeBg ]"
      v-for="track in tracks" :key="track.id"
      class="item"
      @click="playTrack(track)"
      :data-id="`row-${track.id}`"
    >
      <div class="item__control">
        <img
          :src="isPlaying(track)" alt=""
        >
        <div class="track">
          <div class="song-name">{{ track.title }}</div>
          <div class="song-author">{{ track.artist }}</div>
        </div>
      </div>
      <div class="item__actions">
        <div class="genres">
          <div
            class="genre"
            :class="[activeBg]"
            v-for="item in track.genres.split(',')"
            :key="item"
          >{{ capitalize(item) }}</div>
        </div>
        <img @click.stop="$emit('remove', { audioId: track.id, type: 'MUSIC'})" v-if="removable" src="@/assets/img/icon/ic_remove_x.svg" alt="">
        <img @click.stop ="addToPlaylist(track, 'MUSIC')" src="@/assets/img/icon/player/ic_add.svg" alt="">
        <img
          @click.stop="addToFavorite(track)"
          :src="isFavorite(track)"
          alt=""
        >
        <div class="duration">{{ formatTime(track.duration) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useMusicWidget } from '@/modules/music/use/useMusicWidget'
import MusicPlayerFactory from '@/modules/music/use/musicPlayer'

export default {
  emits: ['remove'],
  props: ['_tracks', '_removable', 'bgActive'],
  setup (props) {
    const tracks = computed(() => props._tracks)
    const removable = ref(props._removable || false)
    const activeBg = ref(props.bgActive || 'default')

    const player = MusicPlayerFactory.getInstance()
    player.setPlaylist(tracks)

    return {
      activeBg,
      removable,
      tracks,
      ...useMusicWidget()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 1.6rem;
    border-radius: $radius;
    transition: all ease-out .2s;
    opacity: 0.55;
    margin-top: .4rem;
    height: 7.2rem;
    z-index: 2;
    &__control {
      display: flex;
      overflow: hidden;
      .track {
        margin-left: 1.6rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        .song-name {
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: $white;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 38rem;
        }
        .song-author {
          margin-top: 0.8rem;
          font-weight: 500;
          font-size: 1.2rem;
          color: $white;
          opacity: .70;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      img {
        -webkit-user-drag: none;
        user-select: none;
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
    }
    &__actions {
      margin-right: 3.45rem;
      display: flex;
      justify-content: flex-end;
      justify-items: center;
      gap: 1.5rem;
      .genres {
        display: flex;
        align-items: center;
        & .genre {
          padding: 0.1rem 1rem;
          background-color: $selected_dd;
          color: $white-70;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.8rem;
          border-radius: .8rem;
          margin-right: 5rem;
        }
      }
      .duration {
        width: 3.2rem;
        font-weight: 500;
        font-size: 1.4rem;
        color: $white-text;
        line-height: 2.5rem;
      }
      img {
        -webkit-user-drag: none;
        width: 2.4rem;
        height: 2.4rem;
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
    }
    &.active {
      opacity: 1;
      border-radius: $radius;
      transition: all ease-out .2s;
      box-shadow: $shadow;
      &.default {
        background-color: $dark-base-bg-2;
      }
      &.dark {
        background-color: $selected_dd;
      }
    }
    &:hover {
      opacity: 1;
      cursor: pointer;
      background-color: $dark-base-bg-2;
      border-radius: $radius;
      transition: all ease-out .2s;
      box-shadow: $shadow;
      &.default {
        background-color: $dark-base-bg-2;
      }
      &.dark {
        background-color: $selected_dd;
      }
    }
  }
</style>
