<template>
  <div class="wrapper">
    <div class="zen-cont">
      <div class="zen-logo"></div>
    </div>
    <router-view v-slot="{ Component }">
      <Transition name="d02" mode="out-in">
        <component style="z-index: 1" :is="Component" />
      </Transition>
    </router-view>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: 100vh;
    background-color: $dark-base-bg-2;
    z-index: 0;
    .zen-cont {
      position: absolute;
      top: 50%;
      width: 100%;
      padding: 0 6rem;
      transform: translateY(-50%);
      .zen-logo {
        width: 100%;
        height: 38rem;
        background-image: url("@/assets/img/zenplace-bg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      padding: 3rem;
    }
  }
</style>
