<template>
  <div class="page-wrapper">
    <div class="top-menu">
      <div class="top-menu__list">
        <ul v-for="item in menuList" :key="item.id">
          <li
            @click="chooseTab(item)"
            :class="{ active: item.active }"
          ><span :class="{ active: item.active }"></span>{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div class="section" :class="{ expand: isExpandScene }">
      <div v-if="activeTab.id === 0">
        <div class="section__scenes">
          <div class="section__scenes_tags">
            <div class="tags-header">
              <div class="tags-header__title">Теги</div>
              <div @click="clearFilter()" class="tags-header__action">Сбросить</div>
            </div>
            <div class="tags-block">
              <AppTagsLine
                :_tags="sceneTags"
                :_selected-tags="selectedTags"
                @choose="chooseTag"
                empty="Нет доступных тэгов"
              />
            </div>
          </div>
          <div
            v-if="serviceScenes.length > 0"
            class="section__scenes_cont scrollbar scrollable">
            <div class="section__scenes_items">
              <div
                v-for="scene in serviceScenes"
                :key="scene.id"
                class="item"
                @click="selectScene(scene)"
              >
                <img class="item__preview" :src="defineSceneCover(scene)" alt="">
                <img class="item__play" src="@/assets/img/icon/player/ic_play_music.svg" alt="">
                <div class="item__title">
                  <div class="title">{{ scene.title }}</div>
                  <div class="genres">{{ prepGenres(scene.tags) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="cap">По вашему запросу сцен не найдено</div>
        </div>
      </div>
      <div v-if="activeTab.id === 1">
        <div class="section__scenes">
          <div class="section__scenes_tags">
            <div class="tags-header">
              <div class="tags-header__title">Теги</div>
              <div @click="clearFilter()" class="tags-header__action">Сбросить</div>
            </div>
            <div class="tags-block">
              <AppTagsLine
                :_tags="sceneTags"
                :_selected-tags="selectedTags"
                @choose="chooseTag"
                empty="Нет доступных тэгов"
              />
            </div>
          </div>
          <div
            v-if="favoriteScene.length > 0"
            class="section__scenes_cont scrollbar scrollable">
            <div class="section__scenes_items">
              <div
                v-for="scene in favoriteScene"
                :key="scene.id"
                class="item"
                @click="selectScene(scene)"
              >
                <img class="item__preview" :src="defineSceneCover(scene)" alt="">
                <img class="item__play" src="@/assets/img/icon/player/ic_play_music.svg" alt="">
                <div class="item__title">
                  <div class="title">{{ scene.title }}</div>
                  <div class="genres">{{ prepGenres(scene.tags) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="cap">По вашему запросу сцен не найдено</div>
        </div>
      </div>
      <div class="section__details">
        <div v-if="activeScene.scene" class="section__details_title"><img @click="expandScene()" src="@/assets/img/icon/player/ic_full_screen.svg" alt=""></div>
        <div v-if="!activeScene.scene" class="cap">
          Выберите сцену
        </div>
        <div v-if="activeScene.scene" class="section__details_video">
          <AppSceneVideoPlayer :is-expand="isExpandScene"/>
        </div>
        <div v-if="activeScene.scene" class="header">
          <div class="header__title">
            <img v-if="audioTab.id === 0"
                 @click="toFavorite(activePlaylist)"
                 :src="isFavorite(activePlaylist)"
                 alt=""
            ><span v-if="audioTab.id === 0">{{ activePlaylist ? activePlaylist.name : '' }}</span><span v-if="audioTab.id === 1">Звуки</span>
          </div>
          <div class="header__tabs">
            <div
              class="tab"
              v-for="tab in audioTabList"
              :key="tab.id"
              :class="{ active: tab.id === audioTab.id }"
              @click="chooseAudioTab(tab)"
            ><span class="title"><span :class="{ active: tab.id === activeTab.id }"></span>{{ tab.name }}</span></div>
          </div>
        </div>

        <Transition name="d01">
          <div v-show="audioTab.id === 0">
            <div class="section__details_playlist">
              <div class="content">
                <div v-if="activePlaylist" class="music scrollable scrollbar">
                  <AppMusicTrack :_tracks="tracks" />
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition name="d01">
          <div v-show="audioTab.id === 1">
            <div v-if="activeScene.scene" class="section__details_sound">
              <div class="sound scrollable scrollbar">
                <AppSoundTrack :_tracks="activeScene.scene.sounds" translate-x="410" translate-y="27" />
              </div>
            </div>
          </div>
        </Transition>
        <div class="section__details_bg"></div>
        <AppPlaylistCard class="playlist" />
      </div>
    </div>
    <Transition name="d03">
      <TheSceneFullMode
        v-show="isExpandScene"
        :scene="activeScene"
        :playlist="activePlaylist"
        @close="expandScene()"
        class="full-mode"
        :style="{ padding: '0 4rem 0 4rem' }"/>
    </Transition>
  </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useEventBus } from '@vueuse/core'
import {
  SCENE_ADDED_TO_FAVORITE,
  SCENE_CARD_LOADED,
  SCENE_COLLAPSED,
  SCENE_EXPANDED, SCENE_PAUSE,
  SCENE_PLAYING, SCENE_REMOVED_FROM_FAVORITE, SCENE_RESUME,
  SCENE_SELECTED, SCENE_STOP, SCENE_VOLUME_CHANGE
} from '@/modules/scene/event'
import AppTagsLine from '@/components/ui/tags/AppTagsLine'
import AppPlaylistCard from '@/modules/playlist/components/AppPlaylistCard'
import AppMusicTrack from '@/modules/music/components/music/AppMusicTrack'
import { capitalize } from '@/modules/shared/utils/capitalize'
import AppSceneVideoPlayer from '@/modules/scene/components/AppSceneVideoPlayer'
import AppSoundTrack from '@/modules/sound/components/AppSoundTrack'
import TheSceneFullMode from '@/modules/scene/components/TheSceneFullMode'
import MusicPlayerFactory from '@/modules/music/use/musicPlayer'
import SoundPlayerFactory from '@/modules/sound/use/soundPlayer'

export default {
  components: { TheSceneFullMode, AppSoundTrack, AppSceneVideoPlayer, AppMusicTrack, AppPlaylistCard, AppTagsLine },
  setup () {
    const store = useStore()
    const bus = useEventBus('scene')

    const menuList = [
      { id: 0, name: 'Публичные', active: true, alias: 'public' },
      { id: 1, name: 'Любимые', active: false, alias: 'favorite' }
    ]
    const activeTab = ref(menuList[0])

    const audioTabList = [
      { id: 0, name: 'Музыка', active: true, alias: 'music' },
      { id: 1, name: 'Звуки', active: false, alias: 'sounds' }
    ]
    const audioTab = ref(audioTabList[0])

    const isExpandScene = ref(false)

    const activeScene = computed(() => store.getters['scene/activeScene'])
    const activePlaylist = computed(() => store.getters['scene/activePlaylist'])
    const serviceScenes = computed(() => store.getters['scene/serviceScenes'])
    const favoriteScene = computed(() => store.getters['scene/favoriteScenes'])
    const sceneTags = computed(() => store.getters['scene/sceneTags'])
    const selectedTags = computed(() => store.getters['scene/selectedTags'])
    const selectedCategories = computed(() => store.getters['scene/selectedCategories'])
    const tracks = computed(() => store.getters['scene/tracks'])
    const sounds = computed(() => store.getters['scene/sounds'])

    const player = MusicPlayerFactory.getInstance()
    const soundPlayer = SoundPlayerFactory.getInstance()

    if (serviceScenes.value.length === 0) {
      store.dispatch('scene/loadServiceScenes', {})
    }

    if (favoriteScene.value.length === 0) {
      store.dispatch('scene/loadFavoriteScenes', {})
    }

    if (sceneTags.value.length === 0) {
      store.dispatch('scene/loadSceneTags', {})
    }

    bus.on((event) => {
      if (event.event === SCENE_SELECTED) {
        console.log(SCENE_SELECTED)
      }
      if (event.event === SCENE_CARD_LOADED) {
        store.commit('scene/setActiveVideo', {
          video: activeScene.value.scene.videos[0],
          isInit: true
        })
        store.commit('scene/setActivePlaylist', activeScene.value.scene.playlists[0])
        store.commit('scene/setSounds', activeScene.value.scene.sounds)
        player.setPlaylist(tracks)
        player.stop()
        player.setMode('scene')

        soundPlayer.stopAll()
        store.commit('sound/clearActiveSound')

        const sceneConfig = store.getters['scene/scenesConfig']
        sounds.value.forEach((sound) => {
          sound = { sound, isPlaying: false, volume: sound.volume, howl: null }
          const sceneIndex = sceneConfig.findIndex((item) => item.id === activeScene.value.scene.id)
          if (sceneIndex !== -1) {
            const foundScene = sceneConfig[sceneIndex]
            foundScene.soundsVolume.forEach((soundVol) => {
              if (soundVol.id === sound.sound.id) {
                sound.volume = soundVol.volume * 100
                sound.sound.volume = soundVol.volume * 100
              }
            })
          } else {
            activeScene.value.scene.soundsVolume.forEach((soundVol) => {
              if (soundVol.id === sound.sound.id) {
                sound.volume = soundVol.volume * 100
                sound.sound.volume = soundVol.volume * 100
              }
            })
          }
        })
      }
      if (event.event === SCENE_PLAYING) {
        soundPlayer.setMode('scene')
        store.commit('music/setActiveTrack', { track: tracks.value[0], isInit: false })
        setTimeout(() => {
          player.play()
          player.setMasterVolume(store.getters['scene/volume'])
          player.setTrackVolume(activeScene.value.scene.musicVolume)
        }, 100)

        sounds.value.forEach((sound) => {
          setTimeout(() => {
            sound = { sound, isPlaying: true, volume: sound.volume, howl: null }
            soundPlayer.addToPlay(sound)
            store.commit('sound/setActiveSound', sound)
          }, 100)
        })
      }
      if (event.event === SCENE_PAUSE) {
        player.pause()
        soundPlayer.pauseAll()
        store.commit('sound/clearActiveSound')
        store.commit('music/setActiveTrackPause')
      }
      if (event.event === SCENE_RESUME) {
        store.commit('music/setActiveTrackPlaying')
        player.resume()
        soundPlayer.playAll()
        sounds.value.forEach((sound) => {
          setTimeout(() => {
            sound = { sound, isPlaying: true, volume: sound.volume, howl: null }
            store.commit('sound/setActiveSound', sound)
          }, 100)
        })
      }
      if (event.event === SCENE_STOP) {
        if (activeScene.value.isPlaying) {
          activeScene.value.isPlaying = false
          activeScene.value.status = 'stop'
          player.stop()
          soundPlayer.stopAll()
          store.commit('sound/clearActiveSound')
        }
        soundPlayer.setMode('sound')
        player.setMode('music')
        player.setMasterVolume(store.getters['music/masterVolume'])
        player.setTrackVolume(store.getters['music/musicVolume'])
      }
      if (event.event === SCENE_VOLUME_CHANGE) {
        player.setMasterVolume(store.getters['scene/volume'])
      }
      if (event.event === SCENE_ADDED_TO_FAVORITE || event.event === SCENE_REMOVED_FROM_FAVORITE) {
        store.dispatch('scene/loadFavoriteScenes', {})
        if (activeTab.value.alias === 'favorite') {
          store.dispatch('scene/loadSceneTags', { isFavorite: true })
        }
      }
    })

    return {
      audioTabList,
      audioTab,
      menuList,
      activeTab,
      isExpandScene,
      tracks,
      activeScene,
      activePlaylist,
      sceneTags,
      selectedTags,
      selectedCategories,
      favoriteScene,
      serviceScenes,
      defineSceneCover: (item) => {
        return `${process.env.VUE_APP_BASE_URL}/storage${item.cover}`
      },
      selectScene: (item) => {
        store.dispatch('scene/loadSceneCard', item.id)
      },
      chooseTag: (tag) => {
        store.commit('scene/addTag', tag.tag)
        store.dispatch('scene/loadServiceScenes', {
          tags: selectedTags.value.map(tag => tag.id).join(',') || null,
          categories: selectedCategories.value.map(tag => tag.id).join(',') || null
        })
      },
      clearFilter: () => {
        store.commit('scene/setSelectedTag', [])
        store.dispatch('scene/loadServiceScenes', {})
      },
      isFavorite: (playlist) => {
        if (playlist) {
          if (playlist.favoriteId !== null) {
            return require('@/assets/img/icon/player/ic_favorite.svg')
          } else {
            return require('@/assets/img/icon/player/ic_favorite_empty.svg')
          }
        }
      },
      toFavorite: (playlist) => {
        if (playlist.favoriteId !== null) {
          store.dispatch('playlist/removeFromFavorite', playlist)
        } else {
          store.dispatch('playlist/addToFavorite', playlist)
        }
      },
      playlistPreview: (playlist) => {
        return `${process.env.VUE_APP_BASE_URL}/storage/${playlist.cover}`
      },
      expandScene: () => {
        isExpandScene.value = !isExpandScene.value
        if (isExpandScene.value) {
          bus.emit({ event: SCENE_EXPANDED })
        } else {
          bus.emit({ event: SCENE_COLLAPSED })
        }
      },
      prepGenres: (list) => {
        list = list.split(',')
        list = list.map(item => capitalize(item))
        return list.join(' • ')
      },
      capitalize,
      chooseTab: (item) => {
        if (item.alias === 'favorite') {
          store.dispatch('scene/loadSceneTags', { isFavorite: true })
          if (selectedTags.value.length > 0) {
            store.commit('scene/setSelectedTag', [])
            store.dispatch('scene/loadFavoriteScenes', {})
          }
        } else {
          store.dispatch('scene/loadSceneTags', {})
          if (selectedTags.value.length > 0) {
            store.commit('scene/setSelectedTag', [])
            store.dispatch('scene/loadServiceScenes', {})
          }
        }
        activeTab.value.active = false
        item.active = true
        activeTab.value = item
      },
      chooseAudioTab: (item) => {
        audioTab.value.active = false
        item.active = true
        audioTab.value = item
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  $duration: 0.4s;
  .page-wrapper {
    padding: 0 4rem 0 4rem;
    position: relative;
    .top-menu {
      position: absolute;
      top: -5.4rem;
      left: 25%;
      transform: translateX(-50%);
      z-index: 21;
      .top-menu__list {
        display: flex;
        list-style: none;
        gap: 2.5rem;
        margin-left: 8rem;
        //margin-top: .2rem;
        ul {
          list-style: none;
          li {
            position: relative;
            user-select: none;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 600;
            color: $second-text-2;
            display: flex;
            align-items: center;
            transition: all ease-out .2s;
            //span.active {
            //  position: absolute;
            //  top: 50%;
            //  left: -1.1rem;
            //  transform: translateY(-50%);
            //  display: inline-block;
            //  //background-color: $white;
            //  background-color: $primary;
            //  border-radius: 50%;
            //  width: .5rem;
            //  height: .5rem;
            //  margin-right: .6rem;
            //}
            &.active {
              //color: $white-text;
              color: $primary;
            }
            &:hover {
              cursor: pointer;
              opacity: 0.7;
              transition: all ease-out .2s;
              color: $primary;
            }
            &:active {
              cursor: pointer;
              opacity: 0.5;
              transition: all ease-out .2s;
            }
          }
        }
      }
    }
    .full-mode {
      position: absolute;
      width: calc(100% + 4rem);
      height: 100%;
      left: -4rem;
      top: 0;
      z-index: 21;
    }
    .section {
      display: grid;
      grid-template-columns: 51% auto;
      grid-template-rows: 1fr 1fr;
      transition: all ease-out $duration;
      &__scenes {
        margin-top: 1rem;
        padding-right: 4rem;
        transition: all ease-out $duration;
        .cap {
          margin-top: 4rem;
          width: 100%;
          font-size: 1.2rem;
          color: $second-text-2;
          font-weight: 500;
          opacity: .7;
        }
        &_tags {
          & .tags-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__title {
              color: $white;
              font-size: 1.4rem;
              font-weight: 600;
              line-height: 2.4rem;
              user-select: none;
            }
            &__action {
              color: $second-text-2;
              font-size: 1.2rem;
              font-weight: 500;
              line-height: 2.2rem;
              @include default-hover-active;
            }
          }
          & .genre-block {
            margin-top: 1.4rem;
          }
          & .tags-block {
            position: relative;
            width: 100%;
            margin-top: 1.5rem;
          }
        }
        &_cont {
          margin-top: 2.5rem;
          height: 65vh;
          margin-right: -1.5rem;
          @include scrollbar;
        }
        &_items {
          display: flex;
          flex-wrap: wrap;
          column-gap: 3rem;
          row-gap: 3rem;
          & .item {
            position: relative;
            overflow: hidden;
            border-radius: 2.4rem;
            width: 24rem;
            height: 14rem;
            //box-shadow: $shadow;
            transition: all ease-out .3s;
            user-select: none;
            &:hover {
              .item__preview {
                opacity: 1;
                filter: none;
                transition: all ease-out .3s;
                cursor: pointer;
              }
              .item__play {
                opacity: 1;
              }
            }
            &:active {
              opacity: .5;
            }
            &__title {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              gap: .4rem;
              position: absolute;
              width: 100%;
              bottom: 1.6rem;
              left: 2.4rem;
              & .title {
                font-size: 1.2rem;
                line-height: 1.4rem;
                font-weight: 600;
                color: $white-text;
                &:hover {
                  cursor: pointer;
                }
              }
              & .genres {
                color: $white-80;
                font-size: 1rem;
                font-weight: 500;
                &:hover {
                  cursor: pointer;
                }
              }
            }
            &__preview {
              -webkit-user-drag: none;
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: .9;
              filter: brightness(60%) contrast(90%);
              transition: all ease-out .3s;
            }
            &__play {
              -webkit-user-drag: none;
              opacity: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 1;
              transform: translate(-50%, -50%);
              transition: all ease-out .3s;
              @include default-hover-active;
            }
          }
        }
      }
      &__details {
        position: relative;
        display: flex;
        flex-direction: column;
        //gap: 4rem;
        padding-left: 4rem;
        transition: all ease-out $duration;
        &_title {
          background-color: $widget-bg;
          display: flex;
          align-items: center;
          gap: 1rem;
          position: absolute;
          top: -5.4rem;
          left: 0;
          padding-left: 4rem;
          min-width: 11rem;
          font-size: 2.2rem;
          line-height: 2.4rem;
          font-weight: 600;
          color: $white-text;
          user-select: none;
          z-index: 21;
          transition: all ease-out 0.2s;
          img {
            -webkit-user-drag: none;
            transform: rotate(180deg);
            @include default-hover-active;
          }
        }
        &_video {
          z-index: 2;
          max-height: 30rem;
        }
        & .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2.8rem;
          z-index: 2;
          &__title {
            color: $white;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 600;
            display: flex;
            align-items: center;
            img {
              width: 2.4rem;
              height: 2.4rem;
              margin-left: 1.6rem;
              @include default-hover-active;
            }
            span {
              margin-left: 1.7rem;
            }
          }
          &__tabs {
            height: 4rem;
            user-select: none;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 1.8rem;
            color: $second-text-2;
            background-color: rgba(53, 55, 57, 0.6);
            border-radius: 1.5rem;
            padding: .3rem;
            & .tab {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
              text-align: center;
              border-radius: 1.2rem;
              transition: all ease-out .3s;
              z-index: 1;
              opacity: 1;
              &:hover {
                cursor: pointer;
                opacity: .9;
              }
              &.active {
                font-weight: 600;
                color: $white-text;
                transition: all ease-out .3s;
                background-color: $selected_dd;
                z-index: 2;
                opacity: 1;
              }
              .title {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.8rem;
                position: relative;
                padding: 0 2.6rem;
              }
            }
          }
        }
        &_sound {
          z-index: 2;
          margin-top: 2.5rem;
          .sound {
            max-height: 40vh;
          }
        }
        &_playlist {
          & .content {
            position: relative;
            z-index: 2;
            width: 100%;
            .music {
              position: relative;
              display: flex;
              flex-direction: column;
              gap: .5rem;
              max-height: 40vh;
              padding-right: .8rem;
              margin-top: 2.5rem;
              margin-right: -1rem;
              @include scrollbar-light;
            }
          }
        }
        &_bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 120%;
          height: 120vh;
          transform: translate(0, -10%);
          z-index: 1;
          background-color: $widget-bg;
        }
        & .cap {
          position: relative;
          color: $second-text-2;
          opacity: .7;
          font-size: 1.4rem;
          font-weight: 500;
          z-index: 3;
          padding-left: 0;
        }
        .playlist {
          position: absolute;
          top: 0;
          left: 2rem;
          width: 35rem;
          max-height: 40rem;
        }
      }
    }
  }
</style>
