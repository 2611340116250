<template>

</template>

<script>

export default {
  setup () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

</style>
