<template>
  <div :class="{ active: isSearchFocus }" class="search-field">
    <img class="search" src="@/assets/img/icon/ic_search.svg" alt="">
    <input
      @blur="isSearchFocus = false"
      @focus="isSearchFocus = true"
      v-model.trim="inputModel"
      type="text"
      :placeholder="inputPlaceholder"
    >
    <img v-if="inputModel !== ''" class="clear-search" @click="clearSearch()" src="@/assets/img/icon/ic_close_gray.svg" alt="">
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  emits: ['update:modelValue'],
  props: ['modelValue', 'placeholder'],
  setup (props, { emit }) {
    const isSearchFocus = ref(false)
    const inputModel = ref(props.modelValue)
    const inputPlaceholder = ref(props.placeholder || '')

    watch(inputModel, (value) => {
      emit('update:modelValue', value.trim())
    })

    return {
      inputPlaceholder,
      inputModel,
      isSearchFocus,
      clearSearch: () => {
        inputModel.value = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .search-field {
    display: flex;
    align-items: center;
    height: 4rem;
    background-color: $selected_dd;
    border-radius: $radius;
    padding: .5rem 1.2rem;
    transition: box-shadow ease-out .3s;
    &.active {
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
      transition: box-shadow ease-out .3s;
    }
    .search {

    }
    input {
      width: 100%;
      margin-left: 1rem;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 1.2rem;
      color: $white_dirty;
      &::placeholder {
        font-weight: 500;
        line-height: 1.8rem;
        font-size: 1.2rem;
        color: $second-text-2;
      }
    }
    .clear-search {
      @include default-hover-active;
    }
  }
</style>
