<template>
  <input
    :style="defineSeekStyle"
    v-model="seekModel"
    :class="[classNames, viewStyle]"
    @mouseup="$emit('seekMouseUp')"
    @mousedown="$emit('seekMouseDown')"
    @mousemove="$emit('seekMouseMove')"
    type="range" min="1" max="100" class="slider"
  >
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useEventBus } from '@vueuse/core'
import { SOUND_VOLUME_CHANGED } from '@/modules/sound/event'

export default {
  emits: ['update:modelValue', 'seekMouseUp', 'seekMouseDown', 'seekMouseMove'],
  props: ['id', 'color', 'modelValue', 'view'],

  setup (props, { emit }) {
    const bus = useEventBus('sound')
    const seekModel = ref(0)
    const id = ref(props.id || null)
    const color = computed(() => props.color)
    const viewStyle = ref(props.view)
    const seekProgress = computed(() => props.modelValue)
    const classNames = ref(`seek-${color.value}`)

    watch(seekModel, (value) => {
      emit('update:modelValue', value)
      if (id.value) {
        bus.emit({ event: SOUND_VOLUME_CHANGED, data: { id: id.value, volume: value } })
      }
    })

    watch(seekProgress, (value) => {
      seekModel.value = value
    })

    watch(color, (value) => {
      classNames.value = `seek-${value}`
    })

    seekModel.value = seekProgress.value

    return {
      viewStyle,
      seekModel,
      classNames,
      defineSeekStyle: computed(() => {
        let rgb = 'rgb(242, 120, 68)'
        switch (color.value) {
          case 'primary':
            rgb = 'rgb(242, 120, 68)'; break
          case 'white':
            rgb = 'rgb(255, 255, 255)'; break
          case 'dark':
            rgb = 'rgb(53, 55, 57)'; break
        }
        return `background: linear-gradient(90deg, ${rgb} ${seekProgress.value}%, rgb(76, 78, 84) ${seekProgress.value}%)`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .slider {
    height: .4rem;
    -webkit-appearance: none;
    width: 100%;
    appearance: none;
    outline: none;
    border-radius: 2rem;
    transition: all linear 2s;
    background-color: $primary;
    &:hover {
      cursor: pointer;
    }
    &.seek-primary {
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        background-color: $primary;
        cursor: pointer;
        border-radius: 50%;
        transition: scale ease-out .3s;
      }
      &::-webkit-slider-thumb:hover {
        transition: scale ease-out .3s;
        scale: 1.5;
      }
      &::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
        background-color: $primary;
        cursor: pointer;
        border-radius: 50%;
      }
    }
    &.seek-white{
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        background-color: $white;
        cursor: pointer;
        border-radius: 50%;
        transition: scale ease-out .3s;
      }
      &::-webkit-slider-thumb:hover {
        transition: scale ease-out .3s;
        scale: 1.5;
      }
      &::-moz-range-thumb{
        width: 1rem;
        height: 1rem;
        background-color: $white;
        cursor: pointer;
        border-radius: 50%;
      }
    }
    &.seek-dark{
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        background-color: $selected_dd;
        cursor: pointer;
        border-radius: 50%;
        transition: scale ease-out .3s;
      }
      &::-webkit-slider-thumb:hover {
        transition: scale ease-out .3s;
        scale: 1.5;
      }
      &::-moz-range-thumb{
        width: 1rem;
        height: 1rem;
        background-color: $selected_dd;
        cursor: pointer;
        border-radius: 50%;
      }
    }
    &.view-2 {
      height: .6rem;
      &::-webkit-slider-thumb {
        width: 0;
        height: 0;
        //transform: translate(-10%, -100%);
      }
      &::-moz-range-thumb {
        width: 0;
        height: 0;
        //transform: translateX(-10%);
      }
      &::-webkit-slider-thumb:hover {
        scale: none;
      }
    }
  }
</style>
