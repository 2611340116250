<template>
  <form class="fields" @submit.prevent="onSubmit">
    <div class="form-control fields__header">Кем вы являетесь</div>
    <AppRadioGroup
      class="mt-1-5"
      value="Правообладатель"
      placeholder="Правообладатель - физическое лицо"
      v-model="person"
      id="r-1"
    />
    <AppRadioGroup
      class="mt-1-5"
      value="Представитель правообладателя"
      placeholder="Представитель правообладателя - юрлицо"
      v-model="person"
      id="r-2"
    />
    <div class="form-control fields__header">Контакты для связи</div>
    <AppInputWithLabel
      placeholder="Эл.почта или номер телефона"
      type="text"
      :is-disabled="false"
      :error="contactError"
      v-model="contact"
      error-mt="6"
    />
    <div class="form-control fields__header">Нарушение</div>
    <AppTextareaWithLabel
      placeholder="Опишите нарушение"
      rows="6"
      :is-disabled="false"
      :error="descriptionError"
      v-model="description"
      error-mt="17.5"
    />
    <div class="form-control checkbox">
      <AppCheckbox
        :error="agreementError"
        v-model="agreement"
      >
        Подтверждаю, что являюсь правообладателем или его уполномоченным представителем.
      </AppCheckbox>
    </div>
    <AppButton
      class="btn"
      type="btn"
      color="primary"
      size="md"
      textColor="white"
      view="primary"
      :disabled="isSubmitting"
      @action="onSubmit"
    >Отправить</AppButton>
  </form>
</template>

<script>
import { useStore } from 'vuex'
import { useField, useForm } from 'vee-validate'
import AppButton from '@/components/ui/button/AppButton'
import AppCheckbox from '@/components/ui/checkbox/AppCheckbox'
import * as yup from 'yup'
import AppInputWithLabel from '@/components/ui/input/AppInputWithLabel'
import AppTextareaWithLabel from '@/components/ui/input/AppTextareaWithLabel'
import AppRadioGroup from '@/components/ui/radiogroup/AppRadioGroup'
import { ref } from 'vue'

export default {
  components: { AppRadioGroup, AppTextareaWithLabel, AppInputWithLabel, AppCheckbox, AppButton },
  setup (props, { emit }) {
    const store = useStore()

    const { handleSubmit, isSubmitting } = useForm()

    const { value: contact, errorMessage: contactError } = useField(
      'contact',
      yup
        .string()
        .required('Укажите контакты для связи')
    )

    const { value: description, errorMessage: descriptionError } = useField(
      'description',
      yup
        .string()
        .required('Необходимо указать в чем заключается нарушение')
    )

    const { value: agreement, errorMessage: agreementError, handleBlur: agreementBlur } = useField(
      'agreement',
      yup
        .boolean()
        .required('Подтвердите согласие')
        .isTrue()
    )

    // const { value: person } = useField(
    //   'userType',
    //   yup
    //     .string()
    //     .required('Выберите тип')
    // )

    const person = ref('Правообладатель')

    const onSubmit = handleSubmit(async values => {
      try {
        await store.dispatch('help/sendRightsViolation', {
          userType: person.value,
          contact: values.contact,
          description: values.description
        })
        emit('completed')
      } catch (e) {}
    })

    return {
      person,
      description,
      descriptionError,
      contact,
      contactError,
      agreement,
      agreementError,
      agreementBlur,
      onSubmit,
      isSubmitting
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .fields {
    &__header {
      color: $white;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2.4rem;
    }
    & .btn {
      margin-top: 2.5rem;
      width: 100%;
    }
  }
</style>
