import { Centrifuge } from 'centrifuge'
import { useStore } from 'vuex'
import { processMessage } from '@/modules/notification/use/processMessage'

export function useCentrifuge () {
  const store = useStore()

  const centrifuge = new Centrifuge(process.env.VUE_APP_CENTRIFUGE_HOST, {
    token: '',
    getToken (ctx) {
      return new Promise((resolve, reject) => {
        resolve(store.getters['auth/token'])
      })
    }
  })

  centrifuge.on('connected', function (ctx) {
    console.log('connected', ctx)
  })

  centrifuge.on('disconnected', function (ctx) {
    console.log('disconnected', ctx)
  })

  centrifuge.on('publication', function (ctx) {
    processMessage(ctx.data)
    store.dispatch('notification/setMessage', {
      value: ctx.data.message,
      title: ctx.data.title,
      type: ctx.data.type
    })
  })

  // const sub = centrifuge.newSubscription('personal:#8f4c8550-020b-4455-ae6c-a6a2bfd1dac4')
  //
  // centrifuge.on('publication', function (ctx) {
  //   console.log(ctx.data)
  // })
  //
  // sub.subscribe()

  centrifuge.connect()

  // setTimeout(function () {
  //   centrifuge.history('test', { since: { offset: 0 }, limit: 10 }).then(function (resp) {
  //     console.log(resp)
  //   }, function (err) {
  //     console.log('history error', err)
  //   })
  //
  //   centrifuge.presence('test').then(function (resp) {
  //     console.log(resp)
  //   }, function (err) {
  //     console.log('presence error', err)
  //   })
  //
  //   centrifuge.presenceStats('test').then(function (resp) {
  //     console.log(resp)
  //   }, function (err) {
  //     console.log('presence stats error', err)
  //   })
  // }, 500)

  return centrifuge
}
