<template>
  <div class="section">
    <div class="section__playlists">
      <div class="section__playlists_tags">
        <div class="tags-header">
          <div class="tags-header__title">Категории и теги</div>
          <div @click="clearFilter()" class="tags-header__action">Сбросить</div>
        </div>
        <div class="genre-block">
          <AppTagsLine
            :_tags="playlistCategories"
            :_selected-tags="playlistSelectedCategories"
            @choose="chooseGenre"
            empty="Нет доступных категорий"
          />
        </div>
        <div class="tags-block">
          <AppTagsLine
            :_tags="playlistTags"
            :_selected-tags="playlistSelectedTags"
            @choose="chooseTag"
            empty="Нет доступных тэгов"
          />
        </div>
      </div>
      <div
        v-if="serviceSoundPlaylists.length > 0"
        class="section__playlists_cont scrollbar scrollable">
        <div class="section__playlists_items">
          <div
            v-for="playlist in serviceSoundPlaylists.filter((item) => item.audioType === 'SOUND')"
            :key="playlist.id"
            class="item"
            @click="selectPlaylist(playlist)"
          >
            <img class="item__preview" :src="playlistPreview(playlist)" alt="">
            <img class="item__play" src="@/assets/img/icon/player/ic_play_music.svg" alt="">
            <div class="item__title">
              <div class="title">{{ playlist.name }}</div>
              <div class="genres">{{ prepGenres(playlist.categories) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cap">По вашему запросу плейлистов не найдено</div>
    </div>
    <div class="section__tracks">
      <div class="section__tracks_content">
        <div v-if="activeSoundPlaylist" class="playlist-info">
          <div class="cover">
            <img class="preview"  :src="playlistPreview(activeSoundPlaylist)" alt="">
            <img
              @click="toFavorite(activeSoundPlaylist)"
              class="icon"
              :src="isFavorite(activeSoundPlaylist)"
              alt=""
            >
          </div>
          <div class="info">
            <div class="info__title">{{ activeSoundPlaylist.name }}</div>
            <div class="info__genres">
              <div
                class="genre"
                v-for="item in activeSoundPlaylist.categories.split(',')"
                :key="item"
              >{{ capitalize(item) }}</div>
            </div>
            <div class="info__description">{{ activeSoundPlaylist.description }}</div>
          </div>
        </div>
        <div v-else class="cap">
          Выберите плейлист
        </div>
        <div v-if="activeSoundPlaylist" class="sound scrollable scrollbar">
          <AppSoundTrack :_tracks="tracks" translate-x="1520" translate-y="110"/>
        </div>
      </div>
      <div class="section__tracks_bg"></div>
      <AppPlaylistCard class="playlist" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import AppTagsLine from '@/components/ui/tags/AppTagsLine'
import AppPlaylistCard from '@/modules/playlist/components/AppPlaylistCard'
import AppSoundTrack from '@/modules/sound/components/AppSoundTrack'
import { computed } from 'vue'
import { capitalize } from '@/modules/shared/utils/capitalize'
import { PLAYLIST_SELECTED } from '@/modules/playlist/event'
import { useEventBus } from '@vueuse/core'

export default {
  components: { AppSoundTrack, AppPlaylistCard, AppTagsLine },
  setup () {
    const store = useStore()
    const bus = useEventBus('playlist')

    const activeSoundPlaylist = computed(() => store.getters['playlist/activeSoundPlaylist'])

    const playlistSelectedCategories = computed(() => store.getters['playlist/selectedCategories'])
    const playlistSelectedTags = computed(() => store.getters['playlist/selectedSoundTags'])

    const playlistTags = computed(() => store.getters['playlist/playlistSoundTags'])
    const playlistCategories = computed(() => store.getters['playlist/playlistCategories'])
    const serviceSoundPlaylists = computed(() => store.getters['playlist/serviceSoundPlaylists'])

    const tracks = computed(() => store.getters['sound/sounds'])

    // setTimeout(() => {
    //   activePlaylist.value = serviceSoundPlaylists.value[0]
    // }, 500)

    return {
      tracks,
      activeSoundPlaylist,
      capitalize,
      playlistCategories,
      playlistSelectedCategories,
      playlistTags,
      playlistSelectedTags,
      serviceSoundPlaylists,
      selectPlaylist: (item) => {
        store.commit('playlist/setActiveSoundPlaylist', item)
        bus.emit({ event: PLAYLIST_SELECTED, data: { playlist: item, replace: false } })
      },
      playlistPreview: (playlist) => {
        return `${process.env.VUE_APP_BASE_URL}/storage/${playlist.cover}`
      },
      chooseGenre: (category) => {
        store.commit('playlist/addCategory', category.tag)
        store.dispatch('playlist/loadServiceSoundPlaylist', {
          tags: playlistSelectedTags.value.map(tag => tag.id).join(',') || null,
          categories: playlistSelectedCategories.value.map(tag => tag.id).join(',') || null
        })
      },
      chooseTag: (tag) => {
        store.commit('playlist/addSoundTag', tag.tag)
        store.dispatch('playlist/loadServiceSoundPlaylist', {
          tags: playlistSelectedTags.value.map(tag => tag.id).join(',') || null,
          categories: playlistSelectedCategories.value.map(tag => tag.id).join(',') || null
        })
      },
      prepGenres: (list) => {
        list = list.split(',')
        list = list.map(item => capitalize(item))
        return list.join(' • ')
      },
      clearFilter: () => {
        store.commit('playlist/setSelectedSoundTag', [])
        store.commit('playlist/setSelectedCategories', [])
        store.dispatch('playlist/loadServiceSoundPlaylist', {})
      },
      isFavorite: (playlist) => {
        if (playlist.favoriteId !== null) {
          return require('@/assets/img/icon/player/ic_favorite.svg')
        }
        return require('@/assets/img/icon/player/ic_favorite_empty.svg')
      },
      toFavorite: (playlist) => {
        if (playlist.favoriteId !== null) {
          store.dispatch('playlist/removeFromFavorite', playlist)
        } else {
          store.dispatch('playlist/addToFavorite', playlist)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .section {
    display: grid;
    grid-template-columns: minmax(82rem, 1fr) 1fr;
    &__playlists {
      margin-top: 1rem;
      padding-right: 4rem;
      .cap {
        margin-top: 2rem;
        width: 100%;
        font-size: 1.2rem;
        color: $second-text-2;
        font-weight: 500;
        opacity: .7;
      }
      &_tags {
        & .tags-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &__title {
            color: $white;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 2.4rem;
            user-select: none;
          }
          &__action {
            color: $second-text-2;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 2.2rem;
            @include default-hover-active;
          }
        }
        & .genre-block {
          margin-top: 1.4rem;
        }
        & .tags-block {
          position: relative;
          width: 100%;
          margin-top: .8rem;
        }
      }
      &_cont {
        margin-top: 2.5rem;
        height: 65vh;
        margin-right: -1.5rem;
        @include scrollbar;
      }
      &_items {
        display: flex;
        flex-wrap: wrap;
        column-gap: 3rem;
        row-gap: 3rem;
        & .item {
          position: relative;
          overflow: hidden;
          border-radius: 2.4rem;
          width: 24rem;
          height: 14rem;
          //box-shadow: $shadow;
          transition: all ease-out .3s;
          &:hover {
            .item__preview {
              opacity: 1;
              filter: none;
              transition: all ease-out .3s;
              cursor: pointer;
            }
            .item__play {
              opacity: 1;
            }
          }
          &:active {
            opacity: .5;
          }
          &__title {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: .4rem;
            position: absolute;
            width: 100%;
            bottom: 1.6rem;
            left: 2.4rem;
            & .title {
              font-size: 1.2rem;
              line-height: 1.4rem;
              font-weight: 600;
              color: $white-text;
              &:hover {
                cursor: pointer;
              }
            }
            & .genres {
              color: $white-80;
              font-size: 1rem;
              font-weight: 500;
              &:hover {
                cursor: pointer;
              }
            }
          }
          &__preview {
            -webkit-user-drag: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .9;
            filter: brightness(60%) contrast(90%);
            transition: all ease-out .3s;
          }
          &__play {
            -webkit-user-drag: none;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            transition: all ease-out .3s;
            @include default-hover-active;
          }
        }
      }
    }
    &__tracks {
      position: relative;
      &_content {
        position: relative;
        z-index: 2;
        padding-left: 3rem;
        transform: translateX(1rem);
        .playlist-info {
          position: relative;
          display: flex;
          align-items: center;
          .cover {
            position: relative;
            .preview {
              position: relative;
              width: 14rem;
              height: 13rem;
              object-fit: cover;
              border-radius: $radius-20;
              opacity: .8;
              overflow: hidden;
              //filter: brightness(60%) contrast(90%);
              //box-shadow: $shadow;
            }
            .icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 3.2rem;
              height: 3.2rem;
              @include default-hover-active;
            }
          }
          .info {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-left: 3rem;
            &__title {
              color: $white;
              font-size: 2rem;
              font-weight: 600;
              line-height: 2.4rem;
            }
            &__genres {
              & .genre {
                display: inline-block;
                padding: 0.1rem 1rem;
                background-color: $selected_dd;
                color: $white-80;
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 1.8rem;
                border-radius: .8rem;
                margin-right: .4rem;
              }
            }
            &__description {
              color: $white-70;
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 2rem;
            }
          }
        }
        .cap {
          position: relative;
          color: $second-text-2;
          opacity: .7;
          font-size: 1.4rem;
          font-weight: 500;
        }
        .sound {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: .5rem;
          height: 62vh;
          padding-right: .8rem;
          margin-top: 5rem;
          @include scrollbar-light;
        }
      }
      .playlist {
        position: absolute;
        left: 1rem;
        top: 14rem;
        width: 35rem;
        max-height: 40rem;
      }
      &_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 120%;
        height: 120vh;
        transform: translate(0, -10%);
        z-index: 1;
        background-color: $widget-bg;
      }
    }
  }
</style>
