<template>
  <div class="modal">
    <div
        ref="card"
        class="card"
        :style="{ width: mWidth+'rem', height: mHeight+'rem' }"
    >
      <div class="card__title">{{ mTitle }}
        <span @click="$emit('close')" style="height: 2.4rem; width: 2.4rem" class="card__close"/>
      </div>
      <div class="card__body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
  emits: ['close'],
  props: ['title', 'width', 'height'],
  setup (props, { emit }) {
    const mTitle = ref(props.title)
    const mWidth = ref(props.width)
    const mHeight = ref(props.height)

    const card = ref(null)

    onClickOutside(
      card,
      (event) => {
        setTimeout(() => {
          emit('close')
        }, 10)
      }
    )

    return {
      card,
      mTitle,
      mWidth,
      mHeight
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 39, 0.80);
    z-index: 100;
    .card {
      @include card;
    }
  }
</style>
