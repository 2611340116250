<template>
  <div class="modal">
    <div class="modal__details">
      <div class="modal__details_title"><img @click="$emit('close')" src="@/assets/img/icon/ic_close.svg" alt="">Сцена</div>
      <div v-if="!activeScene.scene" class="cap">
        Выберите сцену
      </div>
      <div v-if="activeScene.scene" class="modal__details_video">
        <AppSceneVideoPlayer is-expand="true"/>
      </div>
      <div v-if="activeScene.scene" class="modal__details_sound">
        <div class="sound scrollable scrollbar">
          <AppSoundTrack :_tracks="activeScene.scene.sounds" translate-x="410" translate-y="27"/>
        </div>
      </div>
      <div class="modal__details_playlist">
        <div class="content">
          <div v-if="activePlaylist" class="playlist-info">
            <div class="cover">
              <img class="preview"  :src="playlistPreview(activePlaylist)" alt="">
              <img
                @click="toFavorite(activePlaylist)"
                class="icon"
                :src="isFavorite(activePlaylist)"
                alt=""
              >
            </div>
            <div class="info">
              <div class="info__title">{{ activePlaylist.name }}</div>
              <div class="info__genres">
                <div
                  class="genre"
                  v-for="item in activePlaylist.genres.split(',')"
                  :key="item"
                >{{ capitalize(item) }}</div>
              </div>
              <div class="info__description">{{ activePlaylist.description }}</div>
            </div>
          </div>
          <div v-if="activePlaylist" class="music scrollable scrollbar">
            <AppMusicTrack :_tracks="activePlaylist.musicIds" />
          </div>
        </div>
        <AppPlaylistCard class="playlist" />
        <div class="modal__details_bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import AppSceneVideoPlayer from '@/modules/scene/components/AppSceneVideoPlayer'
import AppSoundTrack from '@/modules/sound/components/AppSoundTrack'
import AppPlaylistCard from '@/modules/playlist/components/AppPlaylistCard'
import AppMusicTrack from '@/modules/music/components/music/AppMusicTrack'
import { useStore } from 'vuex'
import { capitalize } from '@/modules/shared/utils/capitalize'

export default {
  components: { AppMusicTrack, AppPlaylistCard, AppSoundTrack, AppSceneVideoPlayer },
  props: ['scene', 'playlist'],
  emits: ['close'],
  setup (props) {
    const store = useStore()
    const activeScene = computed(() => props.scene)
    const activePlaylist = computed(() => props.playlist)

    return {
      activePlaylist,
      activeScene,
      playlistPreview: (playlist) => {
        return `${process.env.VUE_APP_BASE_URL}/storage/${playlist.cover}`
      },
      isFavorite: (playlist) => {
        if (playlist.favoriteId !== null) {
          return require('@/assets/img/icon/player/ic_favorite.svg')
        }
        return require('@/assets/img/icon/player/ic_favorite_empty.svg')
      },
      toFavorite: (playlist) => {
        if (playlist.favoriteId !== null) {
          store.dispatch('playlist/removeFromFavorite', playlist)
        } else {
          store.dispatch('playlist/addToFavorite', playlist)
        }
      },
      capitalize
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .modal {
    width: 100%;
    height: 100%;
    &__details {
      position: relative;
      display: grid;
      grid-template-columns: 78rem 1fr;
      grid-template-rows: 36rem 1fr;
      column-gap: 8rem;
      padding-left: 4rem;
      &_title {
        background-color: $widget-bg;
        display: flex;
        align-items: center;
        gap: 1rem;
        position: absolute;
        top: -5.4rem;
        left: 0;
        padding-left: 4rem;
        min-width: 11rem;
        font-size: 2.2rem;
        line-height: 2.4rem;
        font-weight: 600;
        color: $white-text;
        user-select: none;
        z-index: 12;
        img {
          -webkit-user-drag: none;
          width: 2.4rem;
          height: 2.4rem;
          transform: rotate(180deg);
          @include default-hover-active;
        }
      }
      &_video {
        z-index: 2;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
      &_sound {
        z-index: 2;
        grid-column: 1 / 2;
        grid-row: 2 / 5;
        margin-top: 3.6rem;
      }
      &_playlist {
        grid-column: 2 / 4;
        grid-row: 1 / 3;
        & .content {
          position: relative;
          z-index: 2;
          width: 100%;
          .playlist-info {
            position: relative;
            display: flex;
            align-items: center;
            .cover {
              position: relative;
              .preview {
                position: relative;
                width: 14rem;
                height: 13rem;
                object-fit: cover;
                border-radius: $radius-20;
                opacity: .8;
                overflow: hidden;
                //filter: brightness(60%) contrast(90%);
                //box-shadow: $shadow;
              }
              .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 3.2rem;
                height: 3.2rem;
                @include default-hover-active;
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin-left: 3rem;
              transition: all ease-out .3s;
              &__title {
                color: $white;
                font-size: 2rem;
                font-weight: 600;
                line-height: 2.4rem;
              }
              &__genres {
                & .genre {
                  display: inline-block;
                  padding: 0.1rem 1rem;
                  background-color: $selected_dd;
                  color: $white-80;
                  font-size: 1.1rem;
                  font-weight: 500;
                  line-height: 1.8rem;
                  border-radius: .8rem;
                  margin-right: 0.4rem;
                }
              }
              &__description {
                color: $white-70;
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 2rem;
              }
            }
          }
          .music {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            height: 62vh;
            padding-right: .8rem;
            margin-top: 4.6rem;
            @include scrollbar-light;
          }
        }
      }
      &_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 120vw;
        height: 120vh;
        transform: translate(0, -10%);
        z-index: 1;
        background-color: $widget-bg;
      }
      & .cap {
        position: relative;
        color: $second-text-2;
        opacity: .7;
        font-size: 1.4rem;
        font-weight: 500;
        z-index: 3;
        padding-left: 0;
      }
      .playlist {
        position: absolute;
        top: 0;
        left: 54%;
        width: 35rem;
        max-height: 40rem;
      }
    }
  }
</style>
