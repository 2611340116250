import ApiClient from '@/modules/shared/utils/api'
import store from '@/modules/app/store'
import { billing } from '@/modules/billing/repository/billing'
import { useEventBus } from '@vueuse/core'
import { BILLING_DISCOUNTS_LOADED, BILLING_LOADED } from '@/modules/billing/event'

const bus = useEventBus('billing')

export default {
  namespaced: true,
  state: {
    billing: null,
    discounts: [],
    payments: [],
    referrals: []
  },
  getters: {
    billing (state) {
      if (!state.billing) {
        return state.billing
      } else {
        return state.billing
      }
    },
    discounts (state) {
      return state.discounts
    },
    payments (state) {
      return state.payments
    },
    referrals (state) {
      return state.referrals
    }
  },
  mutations: {
    removeDiscount (state, discountId) {
      const discountIndex = state.discounts.findIndex((item) => item.id === discountId)
      state.discounts = state.discounts.length > 1 ? state.discounts.splice(discountIndex, 1) : []
    }
  },
  actions: {
    async loadBilling ({ state, dispatch }) {
      try {
        let workspace = null
        while (!workspace) {
          workspace = store.getters['workspace/workspace']
          await new Promise(resolve => setTimeout(resolve, 100))
        }
        const { data } = await ApiClient.get(billing.billing(workspace.id))
        state.billing = data.data
        bus.emit({ event: BILLING_LOADED, data: state.billing })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadDiscounts ({ state, dispatch }) {
      try {
        const params = {
          statuses: 'ACTIVE',
          isPublic: true
        }
        const { data } = await ApiClient.get(billing.discounts(), { params })
        state.discounts = data.data
        bus.emit({ event: BILLING_DISCOUNTS_LOADED, data: state.billing })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadAccountPayments ({ state, dispatch }) {
      try {
        const params = {
          statuses: 'COMPLETED',
          limit: 6
        }
        const { data } = await ApiClient.get(billing.payments(state.billing.id), { params })
        state.payments = data.data
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadAccountReferrals ({ state, dispatch }) {
      try {
        const params = {
          statuses: 'COMPLETED'
        }
        const { data } = await ApiClient.get(billing.referralPayments(state.billing.id), { params })
        state.referrals = data.data
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  }
}
