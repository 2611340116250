import { createLogger, createStore } from 'vuex'
import auth from '@/modules/identity/store/auth'
import notification from '@/modules/notification/store'
import workspace from '@/modules/workspace/store'
import billing from '@/modules/billing/store'
import menu from '@/modules/app/store/menu'
import music from '@/modules/music/store'
import sound from '@/modules/sound/store'
import video from '@/modules/video/store'
import scene from '@/modules/scene/store'
import playlist from '@/modules/playlist/store'
import help from '@/modules/help/store'

const plugins = []

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}

export default createStore({
  plugins,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth, notification, workspace, billing, menu, music, sound, video, scene, playlist, help
  }
})
