<template>
  <div>
    <div class="section__header">
      <div class="tags-header">
        <div class="tags-header__title">Музыка</div>
        <div @click="clearFilter()" class="tags-header__action">Сбросить</div>
      </div>
      <div class="section__search">
        <AppSearch class="search" v-model="musicSearch" placeholder="Поиск"/>
        <div class="tabs">
          <div
            class="tabs__tab"
            v-for="tab in musicTabList"
            :key="tab.id"
            :class="{ active: tab.id === musicTab.id }"
            @click="chooseMusicTab(tab)"
          >{{ tab.name }}</div>
        </div>
      </div>
      <div class="genre-block">
        <AppTagsLine
          v-if="musicTab.alias === 'all'"
          :_tags="musicGenres"
          :_selected-tags="musicSelectedGenres"
          @choose="chooseMusicGenre"
          empty="Нет доступных жанров"
        />
        <AppTagsLine
          v-if="musicTab.alias === 'favorite'"
          :_tags="musicFavGenres"
          :_selected-tags="musicSelectedFavGenres"
          @choose="chooseMusicFavGenre"
          empty="Нет доступных жанров"
        />
      </div>
      <div class="tags-block">
        <AppTagsLine
          v-if="musicTab.alias === 'all'"
          :_tags="musicTags"
          :_selected-tags="musicSelectedTags"
          @choose="chooseMusicTag"
          empty="Нет доступных тэгов"
        />
        <AppTagsLine
          v-if="musicTab.alias === 'favorite'"
          :_tags="musicFavTags"
          :_selected-tags="musicSelectedFavTags"
          @choose="chooseMusicFavTag"
          empty="Нет доступных тэгов"
        />
      </div>
    </div>
    <div class="section__tracks">
      <div v-if="musicTracks.length > 0" class="music scrollable scrollbar">
        <AppMusicTrack :_tracks="musicTracks" bg-active="dark" />
      </div>
      <AppPlaylistCard class="playlist" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import AppSearch from '@/components/ui/search/AppSearch'
import AppTagsLine from '@/components/ui/tags/AppTagsLine'
import AppPlaylistCard from '@/modules/playlist/components/AppPlaylistCard'
import AppMusicTrack from '@/modules/music/components/music/AppMusicTrack'
import { idsFromObject } from '@/modules/shared/utils/idsFromObject'
import { watchDebounced } from '@vueuse/core'

export default {
  components: { AppMusicTrack, AppPlaylistCard, AppTagsLine, AppSearch },
  setup () {
    const store = useStore()
    const musicSearch = ref('')

    const musicGenres = computed(() => store.getters['music/genres'])
    const musicTags = computed(() => store.getters['music/tags'])
    const musicFavGenres = computed(() => store.getters['music/favGenres'])
    const musicFavTags = computed(() => store.getters['music/favTags'])

    const musicSelectedGenres = computed(() => store.getters['music/selectedGenres'])
    const musicSelectedTags = computed(() => store.getters['music/selectedTags'])
    const musicSelectedFavGenres = computed(() => store.getters['music/selectedFavGenres'])
    const musicSelectedFavTags = computed(() => store.getters['music/selectedFavTags'])

    const musicTracks = computed(() => store.getters['music/tracks'])
    const favoriteMusicTracks = computed(() => store.getters['music/favoriteTracks'])

    const musicTabList = [
      { id: 0, name: 'Все', active: true, alias: 'all' },
      { id: 1, name: 'Любимые', active: false, alias: 'favorite' }
    ]
    const lastTab = ref(store.getters['music/lastMusicSoundsTab'] ?? musicTabList[0])
    const musicTab = ref(lastTab)

    watchDebounced(musicSearch, () => {
      if (musicSearch.value === '') {
        if (musicTab.value.alias === 'favorite') {
          store.dispatch('music/loadFavoriteMusic', {
            tags: idsFromObject(musicSelectedFavTags.value),
            genres: idsFromObject(musicSelectedFavGenres.value)
          })
        } else {
          store.dispatch('music/loadMusic', {
            tags: idsFromObject(musicSelectedTags.value),
            genres: idsFromObject(musicSelectedGenres.value)
          })
        }
      } else {
        if (musicTab.value.alias === 'favorite') {
          store.dispatch('music/loadFavoriteMusic', { search: musicSearch.value })
        } else {
          store.dispatch('music/loadMusic', { search: musicSearch.value })
        }
      }
    }, { debounce: 1000, maxWait: 5000 })

    return {
      musicSearch,
      musicFavGenres,
      musicFavTags,
      musicTracks,
      musicSelectedGenres,
      musicSelectedTags,
      musicSelectedFavGenres,
      musicSelectedFavTags,
      musicGenres,
      musicTags,
      musicTabList,
      musicTab,
      chooseMusicTab: (tab) => {
        musicSearch.value = ''
        if (tab.alias === 'favorite' && favoriteMusicTracks.value.length === 0) {
          store.dispatch('music/loadFavoriteMusic', {
            tags: idsFromObject(musicSelectedFavTags.value),
            genres: idsFromObject(musicSelectedFavGenres.value)
          })
        }
        if (tab.alias === 'all') {
          store.dispatch('music/loadMusic', {
            genres: idsFromObject(musicSelectedGenres.value),
            tags: idsFromObject(musicSelectedTags.value)
          })
        }
        musicTab.value.active = false
        tab.active = true
        musicTab.value = tab
        store.commit('music/setLastMusicSoundsTab', tab)
      },
      clearFilter: () => {
        if (musicTab.value.alias === 'favorite') {
          store.commit('music/setSelectedFavTags', [])
          store.commit('music/setSelectedFavGenres', [])
          store.dispatch('music/loadFavoriteMusic', {})
        } else {
          store.commit('music/setSelectedTags', [])
          store.commit('music/setSelectedGenres', [])
          store.dispatch('music/loadMusic', {})
        }
      },
      chooseMusicGenre: (genre) => {
        store.commit('music/addGenre', genre.tag)
        store.dispatch('music/loadMusic', {
          tags: idsFromObject(musicSelectedTags.value),
          genres: idsFromObject(musicSelectedGenres.value)
        })
        store.commit('playlist/setActiveMusicPlaylist', null)
      },
      chooseMusicTag: (tag) => {
        store.commit('music/addTag', tag.tag)
        store.dispatch('music/loadMusic', {
          tags: idsFromObject(musicSelectedTags.value),
          genres: idsFromObject(musicSelectedGenres.value)
        })
        store.commit('playlist/setActiveMusicPlaylist', null)
      },
      chooseMusicFavGenre: (genre) => {
        store.commit('music/addFavGenre', genre.tag)
        store.dispatch('music/loadFavoriteMusic', {
          tags: idsFromObject(musicSelectedFavTags.value),
          genres: idsFromObject(musicSelectedFavGenres.value)
        })
        store.commit('playlist/setActiveMusicPlaylist', null)
      },
      chooseMusicFavTag: (tag) => {
        store.commit('music/addFavTag', tag.tag)
        store.dispatch('music/loadFavoriteMusic', {
          tags: idsFromObject(musicSelectedFavTags.value),
          genres: idsFromObject(musicSelectedFavGenres.value)
        })
        store.commit('playlist/setActiveMusicPlaylist', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .section {
    &__header {
      margin-left: 4rem;
      & .tags-header {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__title {
          color: $white;
          font-size: 1.4rem;
          font-weight: 600;
          line-height: 2.4rem;
          user-select: none;
        }
        &__action {
          color: $second-text-2;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 2.2rem;
          @include default-hover-active;
        }
      }
      & .genre-block {
        margin-top: 1.4rem;
      }
      & .tags-block {
        position: relative;
        width: 100%;
        margin-top: .8rem;
      }
    }
    &__music {
      padding-right: 4rem;
    }
    &__search {
      display: flex;
      align-items: center;
      border-radius: $radius;
      margin-top: 1.6rem;
      & .search {
        width: 100%;
      }
      & .tabs {
        position: relative;
        margin-left: 1rem;
        width: 20rem;
        height: 4rem;
        user-select: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(53, 55, 57, 0.6);
        border-radius: 1rem;
        padding: .3rem;
        z-index: 2;
        &__tab {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          text-align: center;
          border-radius: .8rem;
          transition: all ease-out .3s;
          z-index: 2;
          opacity: 1;
          color: $second-text-2;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.8rem;
          position: relative;
          padding: 0 2.6rem;
          &:hover {
            cursor: pointer;
            opacity: .8;
          }
          &.active {
            font-weight: 600;
            color: $white-text;
            transition: all ease-out .3s;
            background-color: $selected_dd;
            z-index: 2;
            opacity: 1;
          }
        }
      }
    }
    &__tracks {
      position: relative;
      margin-right: -1.5rem;
      & .music {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        height: 61vh;
        padding-right: .8rem;
        margin-top: 3rem;
        padding-left: 4rem;
        @include scrollbar-dark;
      }
      & .playlist {
        position: absolute;
        left: 4rem;
        top: 0;
        width: 35rem;
        max-height: 40rem;
      }
    }
  }
</style>
