<template>
  <button :type="typeBtn" class="btn" :class="classNames" @click="$emit('action')">
<!--    <span v-if="btnIcon" :style="defineIcon()" class="icon"/>-->
    <span class="slot">
      <slot />
    </span>
  </button>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['action'],
  props: ['color', 'size', 'type', 'textColor', 'view', 'icon'],
  setup (props) {
    const typeBtn = ref(props.type || 'button')
    const color = ref(props.color || '')
    const view = ref(props.view || '')
    const textColor = ref(props.textColor || '')
    const size = ref(props.size || 'md')
    const btnIcon = ref(props.icon || null)

    return {
      btnIcon,
      typeBtn,
      classNames: `btn-${size.value} ${view.value} ${color.value} text-${textColor.value}`,
      defineIcon: () => {
        return { 'background-image': `url(${require(`@/assets/img/icon/player/${btnIcon.value}.svg`)})` }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    transition: all 0.2s ease-out;
    outline: none;
    &-md {
      height: 5rem;
      //padding: 1.7rem 5.6rem 1.7rem 5.6rem;
      font-size: 1.4rem;
      min-width: 20rem;
    }
    &-sm {
      height: 3.5rem;
      font-size: 1.2rem;
      min-width: 12rem;
    }
    img {
      margin-right: 1rem;
    }
    .icon {
      //background-image: url("@/assets/img/icon/ic_approve_gray.svg");
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: .5rem;
      width: 1.8rem;
      height: 1.8rem;
    }
    .slot {
      display: flex;
      align-items: center;
      opacity: 0.55;
      transition: all 0.2s ease-out;
      &:hover {
        opacity: 1;
        transition: all 0.2s ease-out;
      }
    }
    &:hover {
      //color: $white-text;
      cursor: pointer;
    }
    &:active {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  .primary {
    background-color: $primary;
  }
  .flat {
    background-color: transparent;
    font-weight: 600;
  }
  .outline {
    background-color: transparent;
    border: 1px solid $primary;
    font-weight: 600;
  }
  .text {
    height: auto;
    background-color: transparent;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 0;
    min-width: 0;
  }
  .text- {
    &white {
      color: $white;
    }
    &primary {
      color: $primary;
    }
    &gray {
      color: $light-text;
    }
    &accent {
      color: $accent-text;
    }
  }

  @media screen and (max-width: 480px) {
    .btn {
      min-width: 100%;
      width: 100%;
    }
  }

</style>
