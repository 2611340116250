import { useEventBus, useMouse } from '@vueuse/core'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { USER_LOGOUT } from '@/modules/identity/event'
import { SOUND_ADDED_TO_PLAY } from '@/modules/sound/event'
import SoundPlayerFactory from '@/modules/sound/use/soundPlayer'

export function useSoundWidget () {
  const userBus = useEventBus('auth')
  const bus = useEventBus('sound')
  const store = useStore()

  const activeSounds = computed(() => store.getters['sound/activeSounds'])
  const categories = computed(() => store.getters['sound/categories'])

  const player = SoundPlayerFactory.getInstance()

  const isShowCredits = ref(false)
  const soundCredits = ref(null)
  const { x: clickX, y: clickY } = useMouse()
  const lastClickX = ref()
  const lastClickY = ref()

  userBus.on((event) => {
    if (event.event === USER_LOGOUT) {
      player.stopAll()
      store.commit('sound/clearActiveSound')
    }
  })

  bus.on((event) => {
    if (event.event === SOUND_ADDED_TO_PLAY) {
      player.addToPlay(event.data)
    }
  })

  return {
    lastClickX,
    lastClickY,
    isShowCredits,
    soundCredits,
    clickX,
    clickY,
    categories,
    activeSounds,
    playSound: (sound) => {
      sound = { sound, isPlaying: true, volume: sound.volume, howl: null }
      store.commit('sound/setActiveSound', sound)
      player.addToPlay(sound)
    },
    isPlayingSound: (sound) => {
      if (activeSounds.value.find((item) => item.sound.id === sound.id)) {
        return require('@/assets/img/icon/player/ic_pause_music.svg')
      }
      return require('@/assets/img/icon/player/ic_play_music.svg')
    },
    isActiveSound: (sound) => {
      return activeSounds.value.find((item) => item.sound.id === sound.id)
    },
    defineSliderStyle: (track) => {
      if (activeSounds.value.find((item) => item.sound.id === track.id)) {
        return 'primary'
      } else {
        return 'white'
      }
    },
    soundCreditsClick: (track) => {
      lastClickX.value = clickX.value
      lastClickY.value = clickY.value

      if (soundCredits.value === null) {
        soundCredits.value = track.credits
        isShowCredits.value = !isShowCredits.value
      }
    },
    closeSoundCredits: () => {
      isShowCredits.value = !isShowCredits.value
      soundCredits.value = null
    }
  }
}
