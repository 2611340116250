<template>
  <div class="section">
    <div class="section__playlists">
      <div
        v-if="userPlaylists.length > 0"
        class="section__playlists_cont scrollbar scrollable"
      >
        <div class="section__playlists_items scrollbar scrollable">
          <div
            v-for="playlist in userPlaylists"
            :key="playlist.id"
            class="item"
            @click="selectPlaylist(playlist)"
          >
            <div class="item__title">
              <img class="item__title_preview" :src="defineCover(playlist)" alt="">
              <div class="item__title_name">{{ playlist.name }}</div>
            </div>
            <div class="item__controls">
              <div class="item__controls_count" v-text="defineCountName(playlist.musicIds.length + playlist.soundIds.length)"></div>
              <img
                @click="removePlaylist(playlist)"
                class="item__controls_remove" src="@/assets/img/icon/ic_remove.svg" alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cap">Плейлистов не найдено</div>
    </div>
    <div class="section__tracks">
      <div class="section__tracks_content">
        <div v-if="activeMusicPlaylist && activeMusicPlaylist.type === 'USER'" class="playlist-info">
          <div class="cover">
            <img class="preview" :src="defineCover(activeMusicPlaylist)" alt="">
          </div>
          <div class="info">
            <div v-show="!editMode" class="info__title">{{ activeMusicPlaylist.name }}</div>
            <input ref="playlistNameInput" v-show="editMode" class="info__input" v-model="playlistName" />
<!--            <div class="info__description">{{ activeMusicPlaylist.description }}</div>-->
            <div class="info__actions">
              <div @click="edit()" v-if="!editMode" class="btn">Редактировать</div>
              <div @click="save()" v-else class="btn">Сохранить</div>
              <div
                v-if="!editMode"
                @click="removePlaylist(activeMusicPlaylist)"
                class="btn"
              >Удалить</div>
            </div>
          </div>
        </div>
        <div v-else class="cap">
          Выберите плейлист
        </div>
      </div>
      <div v-if="activeMusicPlaylist && activeMusicPlaylist.type === 'USER' && isShowTab" class="tabs">
        <div
          class="tabs__tab"
          v-for="tab in audioTabList"
          :key="tab.id"
          :class="{ active: tab.id === audioTab.id }"
          @click="chooseAudioTab(tab)"
        >{{ tab.name }}</div>
      </div>
      <Transition name="d01">
       <div v-if="audioTab.id === 0">
         <div v-if="activeMusicPlaylist && activeMusicPlaylist.type === 'USER'" class="music scrollable scrollbar">
           <AppMusicTrack
             v-if="tracks.length > 0"
             :_tracks="tracks"
             _removable="true"
             @remove="removeAudio"
           />
           <div v-else class="cap">Композиций не найдено</div>
         </div>
       </div>
      </Transition>
      <Transition name="d01">
        <div v-if="audioTab.id === 1">
          <div v-if="activeMusicPlaylist" class="sound scrollable scrollbar">
            <AppSoundTrack
              v-if="sounds.length > 0"
              :_tracks="sounds"
              translate-x="410"
              translate-y="27"
              _removable="true"
              @remove="removeAudio"
            />
            <div v-else class="cap">Композиций не найдено</div>
          </div>
        </div>
      </Transition>
      <div class="section__tracks_bg"></div>
      <AppPlaylistCard class="playlist" />
    </div>
  </div>
</template>

<script>
import AppPlaylistCard from '@/modules/playlist/components/AppPlaylistCard'
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { PLAYLIST_SELECTED } from '@/modules/playlist/event'
import { useEventBus } from '@vueuse/core'
import AppMusicTrack from '@/modules/music/components/music/AppMusicTrack'
import AppSoundTrack from '@/modules/sound/components/AppSoundTrack'

export default {
  components: { AppSoundTrack, AppMusicTrack, AppPlaylistCard },
  setup () {
    const store = useStore()
    const bus = useEventBus('playlist')
    const userPlaylists = computed(() => store.getters['playlist/playlists'])
    const activeMusicPlaylist = computed(() => store.getters['playlist/activeMusicPlaylist'])
    const tracks = computed(() => store.getters['music/tracks'])
    const sounds = computed(() => store.getters['sound/sounds'])

    const audioTabList = [
      { id: 0, name: 'Музыка', active: true, alias: 'music' },
      { id: 1, name: 'Звуки', active: false, alias: 'sounds' }
    ]
    const audioTab = ref(audioTabList[0])
    const isShowTab = ref(true)

    watch(activeMusicPlaylist, (value) => {
      audioTab.value = audioTabList[0]
      isShowTab.value = true

      if (value.musicIds.length === 0 && value.soundIds.length === 0) {
        isShowTab.value = false
        return
      }
      if (value.musicIds.length === 0) {
        audioTab.value = audioTabList[1]
        isShowTab.value = false
        return
      }
      if (value.soundIds.length === 0) {
        audioTab.value = audioTabList[0]
        isShowTab.value = false
      }
    })

    const playlistNameInput = ref(null)
    const editMode = ref(false)
    const playlistName = ref('')

    return {
      isShowTab,
      playlistNameInput,
      playlistName,
      editMode,
      tracks,
      sounds,
      audioTabList,
      audioTab,
      activeMusicPlaylist,
      userPlaylists,
      selectPlaylist: (item) => {
        editMode.value = false
        store.commit('playlist/setActiveMusicPlaylist', item)
        bus.emit({ event: PLAYLIST_SELECTED, data: { playlist: item, replace: true } })
        playlistName.value = activeMusicPlaylist.value.name
      },
      removePlaylist: (item) => {
        if (activeMusicPlaylist.value.id === item.id) {
          store.commit('playlist/setActiveMusicPlaylist', null)
        }
        store.dispatch('playlist/removePlaylist', item.id)
      },
      save: () => {
        editMode.value = false
        if (playlistName.value !== activeMusicPlaylist.value.name) {
          store.dispatch('playlist/changePlayListName', {
            playlist: activeMusicPlaylist.value,
            name: playlistName.value
          })
        }
      },
      edit: () => {
        editMode.value = true
        setTimeout(() => {
          playlistNameInput.value.focus()
        }, 50)
      },
      defineCountName: (count) => {
        const sklonenie = (count, txt, cases = [2, 0, 1, 1, 1, 2]) => txt[
          (count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]]
        const name = sklonenie(count, ['композиция', 'композиции', 'композиций'])

        return `${count} ${name}`
      },
      chooseAudioTab: (item) => {
        audioTab.value.active = false
        item.active = true
        audioTab.value = item
      },
      removeAudio: (payload) => {
        store.dispatch('playlist/removeFromPlaylist',
          {
            playlistId: activeMusicPlaylist.value.id,
            audioId: payload.audioId,
            type: payload.type
          })
        setTimeout(() => {
          bus.emit({ event: PLAYLIST_SELECTED, data: { playlist: activeMusicPlaylist.value, replace: true } })
        }, 100)
      },
      defineCover: (playlist) => {
        if (playlist.cover !== null) {
          return `${process.env.VUE_APP_BASE_URL}/storage/${playlist.cover}`
        }
        if (playlist.coverUrl !== null) {
          return playlist.coverUrl
        }

        return require('@/assets/img/playlist_cap.jpg')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .section {
    display: grid;
    grid-template-columns: minmax(82rem, 1fr) 1fr;
    &__playlists {
      margin-top: 1rem;
      padding-right: 4rem;
      .cap {
        margin-top: 2rem;
        width: 100%;
        font-size: 1.2rem;
        color: $second-text-2;
        font-weight: 500;
        opacity: .7;
      }
      &_cont {

      }
      &_items {
        position: relative;
        max-height: 79vh;
        @include scrollbar;
        & .item {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 1.6rem;
          height: 10.6rem;
          border-radius: $radius;
          opacity: .55;
          transition: all ease-out .2s;
          margin-right: 1rem;
          &__title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3rem;
            &_preview {
              user-select: none;
              -webkit-user-drag: none;
              width: 9rem;
              height: 8.4rem;
              border-radius: $radius-20;
              //box-shadow: $shadow
            }
            &_name {
              user-select: none;
              font-size: 1.4rem;
              font-weight: 600;
              color: $white;
            }
          }
          &__controls {
            margin-right: .5rem;
            display: flex;
            align-items: center;
            gap: 1.5rem;
            &_remove {
              width: 2.4rem;
              height: 2.4rem;
              user-select: none;
              -webkit-user-drag: none;
              @include default-hover-active;
            }
            &_count {
              user-select: none;
              -webkit-user-drag: none;
              color: $white-70;
              font-size: 1.2rem;
              font-weight: 500;
              line-height: 1.8rem;
              background-color: rgba(44, 44, 44, 0.60);
              padding: 0.1rem 1rem;
              border-radius: .8rem;
            }
          }
          &:hover {
            transition: all ease-out .2s;
            cursor: pointer;
            background-color: $selected_dd;
            //box-shadow: $shadow;
            opacity: 1;
          }
        }
      }
    }
    &__tracks {
      position: relative;
      &_content {
        position: relative;
        z-index: 2;
        padding-left: 3rem;
        transform: translateX(1rem);
        .playlist-info {
          position: relative;
          display: flex;
          align-items: center;
          margin-top: .7rem;
          .cover {
            position: relative;
            .preview {
              position: relative;
              width: 14rem;
              height: 13rem;
              object-fit: cover;
              border-radius: 2.4rem;
              overflow: hidden;
              opacity: 0.6;
            }
          }
          .info {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin-left: 3rem;
            &__title {
              user-select: none;
              -webkit-user-drag: none;
              color: $white;
              font-size: 2rem;
              font-weight: 600;
              line-height: 2.4rem;
            }
            &__input {
              background-color: transparent;
              outline: none;
              border: none;
              color: $white;
              font-size: 2rem;
              font-weight: 600;
              line-height: 2.4rem;
            }
            &__genres {
              & .genre {
                display: inline-block;
                padding: 0.1rem 1rem;
                background-color: $selected_dd;
                color: $white-80;
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 1.8rem;
                border-radius: .8rem;
                margin-right: 0.4rem;
              }
            }
            &__description {
              color: $white-70;
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 2rem;
            }
            &__actions {
              display: flex;
              gap: 1rem;
              & .btn {
                color: $second-text-2;
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 2.4rem;
                @include default-hover-active;
              }
            }
          }
        }
        .cap {
          position: relative;
          color: $second-text-2;
          opacity: .7;
          font-size: 1.4rem;
          font-weight: 500;
        }
        .music {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: .5rem;
          height: 62vh;
          padding-right: .8rem;
          margin-top: 4.6rem;
          @include scrollbar-light;
        }
      }
      & .tabs {
        position: relative;
        margin-left: 4rem;
        width: 20rem;
        margin-top: 2.5rem;
        height: 4rem;
        user-select: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(53, 55, 57, 0.6);
        border-radius: 1.5rem;
        padding: .3rem;
        z-index: 2;
        &__tab {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          text-align: center;
          border-radius: 1.2rem;
          transition: all ease-out .3s;
          z-index: 2;
          opacity: 1;
          color: $second-text-2;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.8rem;
          position: relative;
          padding: 0 2.6rem;
          &:hover {
            cursor: pointer;
            opacity: .8;
          }
          &.active {
            font-weight: 600;
            color: $white-text;
            transition: all ease-out .3s;
            background-color: $selected_dd;
            z-index: 2;
            opacity: 1;
          }
        }
      }
      &_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 120%;
        height: 120vh;
        transform: translate(0, -10%);
        z-index: 1;
        background-color: $widget-bg;
      }
      & .music {
        margin-left: 4rem;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        height: 57vh;
        padding-right: .8rem;
        margin-top: 2.5rem;
        @include scrollbar-light;
      }
      & .sound {
        margin-left: 4rem;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        height: 57vh;
        padding-right: .8rem;
        margin-top: 2.5rem;
        @include scrollbar-light;
      }
      .playlist {
        position: absolute;
        top: 0;
        left: 2rem;
        width: 35rem;
        max-height: 40rem;
      }
      & .cap {
        margin-top: 2rem;
        margin-left: .5rem;
        width: 100%;
        font-size: 1.2rem;
        color: $second-text-2;
        font-weight: 500;
        opacity: .7;
        z-index: 2;
      }
    }
  }
</style>
