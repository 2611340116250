<template>
  <div @click.stop>
<!--    <img class="icon" @click.stop="openCredits()" :src="defineIcon()" alt="">-->
    <Transition name="d02">
      <div
        class="credits"
        :style="definePosition()"
      >
        <div class="credits__title">Информация о композиции</div>
        <div class="credits__content"> {{ audioCredits }}</div>
        <img @click.stop="$emit('action')" class="credits__close" src="@/assets/img/icon/ic_close_gray.svg" alt=""/>
      </div>
    </Transition>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  emits: ['action'],
  props: ['credits', 'clickX', 'clickY', 'isShow', 'translateX', 'translateY'],
  setup (props) {
    const audioCredits = computed(() => props.credits)
    const localClickX = computed(() => props.clickX)
    const localClickY = computed(() => props.clickY)

    // watch(audioCredits.value, (value) => {
    //   console.log(value)
    // })

    return {
      audioCredits,
      definePosition: () => {
        return `transform: translate(${localClickX.value - props.translateX}px,${localClickY.value - props.translateY}px)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .icon {
    margin-top: 0;
    width: 2.4rem;
    height: 2.4rem;
    -webkit-user-drag: none;
    &:hover {
      cursor: pointer;
      opacity: .7;
    }
    &:active {
      cursor: pointer;
      opacity: .5;
    }
  }
  .credits {
    position: fixed;
    top: 0;
    left: 0;
    padding: 2rem 2rem 1.7rem 2rem;
    width: 39.1rem;
    height: auto;
    background-color: $selected_dd;
    border-radius: $radius-20;
    box-shadow: $shadow;
    z-index: 3;
    &__title {
      color: $white-text;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    &__content {
      margin-top: 2rem;
      color: $white-80;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    &__close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      &:hover {
        cursor: pointer;
        opacity: .7;
      }
      &:active {
        cursor: pointer;
        opacity: .5;
      }
    }
    &:hover {
      cursor: default;
      user-select: text;
    }
  }
</style>
