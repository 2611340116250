<template>
  <div
    class="side-menu"
    @mouseover="mouseMenuOver()"
    @mouseleave="mouseMenuLeaveNav()"
  >
    <div class="side-menu__logo">
      <img src="@/assets/img/logo.svg" alt="">
    </div>
    <div class="menu-list">
      <ul v-for="item in menuList" :key="item.id">
        <li
          :class="{ active: item.active, open: item.alias === 'playlists' && isMenuHover && isPlaylistHover }"
          @click="choose(item)"
          @mouseover="mousePlaylistOver(item.alias)"
        >
          <img class="menu-list__icon" :src=icon(item) alt="">{{ item.name }}
        </li>
      </ul>
    </div>
    <Transition>
      <div
        v-if="isPlaylistHover && isMenuHover"
        class="side-menu__submenu"
      >
        <div class="side-menu__submenu_title">Мои плейлисты</div>
        <span v-if="playlists.length === 0">Нет плейлистов</span>
        <ul v-if="playlists.length !== 0" class="side-menu__submenu_items">
          <li @click="selectPlaylist(item)" v-for="item in playlists" :key="item.id">
            {{ item.name }}
          </li>
        </ul>
        <div class="side-menu__submenu_title mt-7">Любимые плейлисты</div>
        <span v-if="favoritePlaylists.length === 0">Нет плейлистов</span>
        <ul v-if="favoritePlaylists.length !== 0" class="side-menu__submenu_items">
          <li @click="selectPlaylist(item)" v-for="item in favoritePlaylists" :key="item.id">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </Transition>
    <div @click="openHelp()" class="help" :class="{ open: isHelpOpen }">
      <img src="@/assets/img/icon/ic_help.svg" alt="">
      <span>Помощь</span>
    </div>
  </div>
</template>

<script>
import useMainMenu from '@/modules/app/use/menu'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useEventBus } from '@vueuse/core'
import { PLAYLIST_SELECTED } from '@/modules/playlist/event'
import { APP_SIDE_HELP } from '@/modules/app/event'

export default {
  setup () {
    const store = useStore()
    const bus = useEventBus('playlist')
    const appBus = useEventBus('app')
    const playlists = computed(() => store.getters['playlist/playlists'])
    const favoritePlaylists = computed(() => store.getters['playlist/favoritePlaylists'])
    const isCreating = ref(false)
    const playlistName = ref('')

    const isPlaylistHover = ref(false)
    const isMenuHover = ref(false)
    const isHelpOpen = computed(() => store.getters['help/helpOpen'])

    return {
      isHelpOpen,
      favoritePlaylists,
      isMenuHover,
      isPlaylistHover,
      playlistName,
      isCreating,
      playlists,
      ...useMainMenu(),
      save: () => {
        if (playlistName.value !== '') {
          store.dispatch('playlist/createPlaylist', playlistName.value)
          playlistName.value = ''
        }
        isCreating.value = false
      },
      createTyping: () => { isCreating.value = true },
      removePlaylist: (item) => {
        store.dispatch('playlist/removePlaylist', item.id)
      },
      selectPlaylist: (item) => {
        bus.emit({ event: PLAYLIST_SELECTED, data: { playlist: item, replace: false } })
      },
      mousePlaylistOver: (alias) => {
        isPlaylistHover.value = alias === 'playlists'
      },
      mouseMenuOver: () => {
        // todo: Set true for playlists
        isMenuHover.value = false
      },
      mouseMenuLeaveNav: () => {
        isMenuHover.value = false
      },
      openHelp: () => {
        store.commit('help/setHelpOpen', !isHelpOpen.value)
        appBus.emit({ event: APP_SIDE_HELP })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';
  .side-menu {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 24rem;
    width: 24rem;
    background-color: $side-menu;
    user-select: none;
    &__logo {
      width: 10.4rem;
      height: 2.2rem;
      margin-top: 3.3rem;
      margin-left: 4rem;
      img {
        -webkit-user-drag: none;
      }
    }
    .menu-list {
      margin-top: 6rem;
      color: $white-text;
      margin-left: 2.6rem;
      &__icon {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1.5rem;
      }
      li {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        line-height: 2.4rem;
        padding: 2rem 1.2rem;
        font-weight: 500;
        transition: all ease-out .2s;
        opacity: .5;
        &.active {
          font-weight: 600;
          color: $primary;
          opacity: 1;
        }
        &.open {
          opacity: 1;
        }
        &:hover {
          cursor: pointer;
          opacity: 1;
          transition: all ease-out .2s;
        }
        &:active {
          cursor: pointer;
          opacity: 0.3;
          transition: all ease-out .2s;
        }
      }
    }
    .sub-list {
      margin-top: 4rem;
      margin-left: 4rem;
      &__title {
        text-transform: uppercase;
        color: $second-text-2;
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 500;
      }
      ul {
        margin-top: 1rem;
        list-style: none;
        margin-left: -2rem;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $white-text;
          font-size: 1.4rem;
          line-height: 2.4rem;
          font-weight: 500;
          margin-right: 2rem;
          padding: 1rem 1.2rem 1rem 2rem;
          border-radius: $radius;
          transition: all ease-out .2s;
          &:hover {
            transition: all ease-out .2s;
            cursor: pointer;
            background-color: $dark-base-bg-2;
            opacity: .7;
            img {
              opacity: 1;
            }
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
          img {
            opacity: 0;
            -webkit-user-drag: none;
            &:hover {
              cursor: pointer;
              opacity: .7;
            }
            &:active {
              cursor: pointer;
              opacity: .5;
            }
          }
        }
      }
      &__more {
        display: block;
        margin-top: 1.5rem;
        font-size: 1.4rem;
        color: $white-text;
      }
      .sub-list__add {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: $white-text;
        opacity: .8;
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
    }
    &__submenu {
      padding: 5.1rem 4rem;
      position: absolute;
      top: 0;
      left: 24rem;
      width: 30rem;
      background-color: $widget-bg;
      height: 100%;
      box-shadow: $shadow;
      z-index: 3;
      &_title {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 2rem;
        color: $white-text;
      }
      &_items {
        margin-top: 1rem;
        list-style: none;
        margin-left: -2rem;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $second-text-2;
          font-size: 1.4rem;
          line-height: 2.4rem;
          font-weight: 500;
          padding: 1.2rem 1rem 1.2rem 2rem;
          border-radius: $radius;
          transition: all ease-out .2s;
          &:hover {
            transition: all ease-out .2s;
            cursor: pointer;
            color: $white-text;
            img {
              opacity: 1;
            }
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
      }
      span {
        display: inline-block;
        margin-top: 1rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: #606060;
      }
    }
    .help {
      position: absolute;
      bottom: 2.8rem;
      display: flex;
      align-items: center;
      margin-left: 4rem;
      opacity: .55;
      img {
        width: 2.4rem;
        height: 2.4rem;
      }
      span {
        margin-left: 1.5rem;
        color: $white;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.4rem;
      }
      @include default-hover-active;
      &.open {
        opacity: 1;
      }
    }
  }
</style>
