<template>
  <AppMessage />
  <Transition mode="out-in">
    <component :is="layout + '-layout'" v-if="layout"></component>
  </Transition>
</template>

<script>
import { useRoute } from 'vue-router'
import AuthLayout from '@/modules/identity/layout/AuthLayout'
import { computed } from 'vue'
import LandingLayout from '@/modules/landing/layout/LandingLayout'
import LkLayout from '@/modules/app/layout/LkLayout'
import AppMessage from '@/modules/notification/components/AppMessage'
import AppLayout from '@/modules/app/layout/AppLayout'

export default {
  setup () {
    const route = useRoute()

    return {
      layout: computed(() => route.meta.layout)
    }
  },
  components: { AppMessage, LkLayout, AuthLayout, LandingLayout, AppLayout }
}
</script>
