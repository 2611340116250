<template>
  <div class="sale-card">
    <div class="sale-card__body">
      <div class="sale-card__title">{{ item.name }}</div>
      <div class="sale-card__content" v-html="parseContent(item)"></div>
      <div class="sale-card__btn">
        <AppButton color="primary" size="sm" textColor="primary" view="outline">Применить</AppButton>
      </div>
    </div>
    <div class="sale-card__offer">
      <div class="sale-card__offer_title">{{ item.percentage ? `${item.percentage}%` : item.amount  }}</div>
      <div class="sale-card__offer_text">до {{ formatDate(item.endAt) }}</div>
      <div class="sale-card__offer_shape"></div>
    </div>
    <img @click="$emit('action', {discountId: item.id})" class="sale-card__close" src="@/assets/img/icon/ic_close_gray.svg" alt=""/>
  </div>
</template>

<script>
import AppButton from '@/components/ui/button/AppButton'
import { formatDate } from '@/modules/shared/utils/formatDate'
export default {
  components: { AppButton },
  props: ['item'],
  emits: ['action'],
  setup (props) {
    return {
      formatDate,
      parseContent: (item) => {
        return item.description.replace('<promo>', `<span>«${item.number}»</span>`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .sale-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: $selected_dd;
    border-radius: $radius-20;
    box-shadow: $shadow;
    height: 22.4rem;
    min-height: 22.4rem;
    padding: 3rem 3rem;
    overflow: hidden;
    z-index: 1;
    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem;
    }
    &__title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: $white-text;
    }
    &__content {
      //margin-top: .5rem;
      width: 22.4rem;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $white-text;
      ::v-deep span{
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 2rem;
        color: $primary;
      }
    }
    &__btn {
      position: absolute;
      left: 3rem;
      bottom: 3rem;
      margin-top: 1rem;
    }
    &__offer {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_title {
        font-weight: 800;
        font-size: 3.6rem;
        line-height: 4.4rem;
        color: $white-text;
      }
      &_text {
        margin-top: .4rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.2rem;
        color: $white-text;
        opacity: .6;
      }
      &_shape {
        position: absolute;
        top: -3rem;
        right: -10rem;
        height: 22.4rem;
        width: 22.4rem;
        background-color: #3F3F3F;
        z-index: -1;
        border-top-left-radius: 70%;
        border-bottom-left-radius: 70%;
      }
    }
    &__close {
      -webkit-user-drag: none;
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 2.4rem;
      height: 2.4rem;
      @include default-hover-active
    }
  }
</style>
