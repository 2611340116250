<template>
  <div class="field">
    <input
      :id="inputId"
      type="radio"
      :value="inputValue"
      :checked="modelValue === inputValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <label :for="inputId">{{ inputPlaceholder }}</label>
    <span @click="$emit('update:modelValue', inputValue)" class="checkmark"></span>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  emits: ['update:modelValue'],
  props: ['modelValue', 'value', 'placeholder', 'label', 'error', 'isDisabled', 'id'],
  setup (props) {
    const inputModel = computed(() => props.modelValue)
    const inputValue = ref(props.value || null)
    const inputPlaceholder = ref(props.placeholder || '')
    const inputId = ref(props.id)

    return {
      inputId,
      inputValue,
      inputModel,
      inputPlaceholder
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .field {
    display: flex;
    align-items: center;
    position: relative;
    label {
      user-select: none;
      margin-left: 3rem;
      color: $white;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.4rem;
      @include default-hover-active;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      @include default-hover-active;
    }
    & .checkmark {
      position: absolute;
      top: .1rem;
      left: 0;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid $white;
      @include default-hover-active;
    }
    &:hover input ~ .checkmark {
      border: 1px solid $primary;
    }
    input:checked ~ .checkmark {
      background-color: transparent;
      border: 1px solid $primary;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background: $primary;
    }
  }
</style>
