import store from '@/modules/app/store'
import { useCentrifuge } from '@/modules/notification/use/centrifuge'
import { useEventBus } from '@vueuse/core'
import { TOKEN_UPDATED, TOKEN_VALID, USER_LOGOUT } from '@/modules/identity/event'

export function initLk () {
  const bus = useEventBus('auth')

  useCentrifuge()
  store.dispatch('auth/canInit')

  const checkTokenInteval = setInterval(() => {
    store.dispatch('auth/checkIsTokenValid')
  }, 240000)

  bus.on((event) => {
    if (event.event === TOKEN_VALID) {
      store.dispatch('workspace/loadWorkspace')
      store.dispatch('billing/loadBilling')
    }
    if (event.event === USER_LOGOUT) {
      clearInterval(checkTokenInteval)
    }
    if (event.event === TOKEN_UPDATED) {
      store.dispatch('billing/loadBilling')
    }
  })
}
