import { Howl, Howler } from 'howler'
import { computed } from 'vue'
import store from '@/modules/app/store'
import { useEventBus } from '@vueuse/core'
import { TRACK_SEEK_CHANGED } from '@/modules/music/event'

class MusicPlayer {
  constructor () {
    this.index = 0
    this.sound = null
    this.ctx = self
    // this.playList = computed(() => store.getters['music/tracks'])
    // this.activeTrack = computed(() => store.getters['music/activeTrack'])
    this.bus = useEventBus('music')
    this.startListen = 0
    this.lastTrack = null
    this.endListen = 0
    this.mode = 'music'
    Howler.volume(store.getters['music/masterVolume'])
  }

  setPlaylist (playList) {
    this.playList = playList
  }

  setActiveTrack (activeTrack) {
    this.activeTrack = computed(() => activeTrack)
  }

  play (isInit) {
    if (this.sound) {
      this.sound.stop()
      this.sound.unload()
    }
    this.sound = new Howl({
      xhr: {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + store.getters['auth/token']
        },
        withCredentials: true
      },
      src: [
        `${process.env.VUE_APP_BASE_URL}${this.activeTrack.value.track.src}`
      ],
      html5: true,
      // usingWebAudio: true,
      autoplay: false,
      volume: store.getters['music/musicVolume'],
      loop: store.getters['music/musicLoop'],
      format: ['mp3', 'webm']
    })
    this.sound.on('end', () => {
      if (!store.getters['music/isLoop']) {
        this.sound.loop = false
        this.forward()
      } else {
        this.sound.loop = true
        this.play()
      }
    })
    this.sound.on('play', () => {
      this.startListen = Date.now()
      this.lastTrack = this.activeTrack.value.track
    })
    this.sound.on('stop', () => {
      this.saveHistory()
    })
    this.sound.on('pause', () => {
      this.saveHistory()
      this.lastTrack = null
    })
    this.sound.on('loaderror', () => {
      this.sound.unload()
      this.forward()
    })
    this.sound.on('playerror', () => {
      this.sound.unload()
      this.forward()
    })
    this.sound.on('seek', () => {
      const percentage = Math.ceil((((this.sound.seek() / this.sound.duration()) * 100) || 0))
      this.bus.emit({
        event: TRACK_SEEK_CHANGED,
        data: { percentage, left: this.sound.seek() }
      })
    })

    store.commit('music/setActiveTrackLoading', this.playList.value[0])

    this.sound.on('load', () => {
      store.commit('music/setActiveTrackLoaded', this.playList.value[0])
    })

    if (!isInit) {
      this.sound.play()
    }

    const seekUpdater = setInterval(() => {
      const percentage = Math.ceil((((this.sound.seek() / this.sound.duration()) * 100) || 0))
      if (percentage >= 100) {
        clearInterval(seekUpdater)
      }
      this.bus.emit({
        event: TRACK_SEEK_CHANGED,
        data: { percentage, left: this.sound.seek() }
      })
    }, 1000)
  }

  pause () {
    if (this.sound) {
      this.sound.pause()
    }
  }

  stop () {
    if (this.sound) {
      this.sound.stop()
      this.sound.unload()
      store.commit('music/setActiveTrackStop')
    }
  }

  resume () {
    if (this.sound) {
      this.sound.play()
    }
  }

  forward () {
    if (this.sound) {
      this.sound.stop()
      this.sound.unload()
    }
    const playListLength = this.playList.value.length
    if (playListLength <= 1) {
      return
    }
    let index = this.playList.value.indexOf(this.activeTrack.value.track)
    if (index === -1) {
      index = 0
    }
    let shuffleIndex = null
    if (store.getters['music/isShuffle']) {
      shuffleIndex = this.shuffle(playListLength, index)
    } else {
      shuffleIndex = index + 1
    }
    if (playListLength > shuffleIndex) {
      store.commit('music/setActiveTrack', { track: this.playList.value[shuffleIndex] })
    } else {
      store.commit('music/setActiveTrack', { track: this.playList.value[0] })
    }
  }

  backward () {
    if (this.sound) {
      this.sound.stop()
      this.sound.unload()
    }
    const playListLength = this.playList.value.length
    if (playListLength <= 1) {
      return
    }
    const index = this.playList.value.indexOf(this.activeTrack.value.track)
    if (index === 0) {
      store.commit('music/setActiveTrack', { track: this.playList.value[this.playList.value.length - 1] })
    } else {
      store.commit('music/setActiveTrack', { track: this.playList.value[index - 1] })
    }
  }

  seek (percentage) {
    if (this.sound) {
      this.sound.seek(this.sound.duration() * percentage / 100)
    }
  }

  seekForward10s () {
    this.sound.seek(this.sound.seek() + 10)
  }

  seekBackward10s () {
    const seek = this.sound.seek() - 10 > 0 ? this.sound.seek() - 10 : 0
    this.sound.seek(seek)
  }

  volumeUp () {
    store.commit('music/setMasterVolume', store.getters['music/masterVolume'] + 0.1)
    Howler.volume(store.getters['music/masterVolume'])
  }

  volumeDown () {
    store.commit('music/setMasterVolume', store.getters['music/masterVolume'] - 0.1)
    Howler.volume(store.getters['music/masterVolume'])
  }

  masterMute () {
    if (store.getters['music/masterVolume'] * 100 === 0) {
      store.commit('music/setMuted', false)
      Howler.mute(false)
      this.volumeUp()
    } else {
      Howler.mute(store.getters['music/isMuted'])
    }
  }

  changeVolume (volume) {
    if (volume <= 0.01) {
      volume = 0
    }
    if (this.activeTrack.value.track !== null) {
      this.sound.volume(volume)
    }

    if (this.mode === 'scene') {
      store.commit('scene/setMusicVolume', volume)
    } else {
      store.commit('music/setTrackVolume', volume)
    }
  }

  changeMasterVolume (volume) {
    if (volume <= 0.01) {
      volume = 0
    }
    if (this.mode === 'scene') {
      store.commit('scene/setSceneVolume', volume)
    } else {
      store.commit('music/setMasterVolume', volume)
      Howler.volume(store.getters['music/masterVolume'])
    }
  }

  setMasterVolume (volume) {
    if (volume <= 0.01) {
      volume = 0
    }
    Howler.volume(volume)
  }

  setTrackVolume (volume) {
    if (volume <= 0.01) {
      volume = 0
    }
    if (this.activeTrack.value.track !== null) {
      this.sound.volume(volume)
    }
  }

  get isPlaying () {
    if (!this.sound) {
      return false
    }
    return this.sound.playing()
  }

  shuffle (max, currentIndex) {
    let shuffle = 0
    if (max > 1) {
      while (shuffle === currentIndex) {
        shuffle = Math.floor(Math.random() * max)
      }
    }
    return shuffle
  }

  stopAll () {
    Howler.stop()
  }

  setMode (mode) {
    this.mode = mode
  }

  getMode () {
    return this.mode
  }

  saveHistory () {
    if (this.lastTrack !== null) {
      this.endListen = Date.now()
      store.dispatch('music/saveMusicHistory', {
        track: this.lastTrack,
        duration: Math.floor((this.endListen - this.startListen) / 1000)
      })
    }
  }
}

const MusicPlayerFactory = (function () {
  let instance
  return {
    getInstance: function () {
      if (instance == null) {
        instance = new MusicPlayer()
        instance.constructor = null
      }
      return instance
    }
  }
})()

export default MusicPlayerFactory
