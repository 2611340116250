import { workspace } from '@/modules/workspace/repository/workspace'
import ApiClient from '@/modules/shared/utils/api'
import store from '@/modules/app/store'
import { useEventBus } from '@vueuse/core'
import { WORKSPACE_LOADED } from '@/modules/workspace/event'

const bus = useEventBus('workspace')

export default {
  namespaced: true,
  state: {
    workspace: null
  },
  mutations: {
  },
  actions: {
    async loadWorkspace ({ state, dispatch }) {
      try {
        const { data } = await ApiClient.get(workspace.workspace(store.getters['auth/user'].id))
        state.workspace = data.data
        bus.emit({ event: WORKSPACE_LOADED, data: state.workspace })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  },
  getters: {
    workspace (state) {
      return state.workspace
    }
  }
}
