import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { identity } from '@/modules/identity/repository/auth'
import router from '@/modules/app/router'
import { useEventBus } from '@vueuse/core'
import { TOKEN_UPDATED, TOKEN_VALID, USER_LOGOUT } from '@/modules/identity/event'
import ApiClient from '@/modules/shared/utils/api'

const ACCESS_TOKEN_KEY = 'access-token'
const REFRESH_TOKEN_KEY = 'refresh-token'
const FINGER_PRINT = 'fingerprint'

const bus = useEventBus('auth')

export default {
  namespaced: true,
  state () {
    return {
      user: null,
      jwtToken: null,
      accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
      refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
      fingerprint: localStorage.getItem(FINGER_PRINT) || ''
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    token (state) {
      return state.accessToken
    },
    isAuthenticated (_, getters) {
      return !!getters.token
    }
  },
  mutations: {
    saveAccessToken (state, token) {
      state.accessToken = token
      localStorage.setItem(ACCESS_TOKEN_KEY, token)
      bus.emit({ event: TOKEN_UPDATED, data: token })
    },
    saveRefreshToken (state, token) {
      state.refreshToken = token
      localStorage.setItem(REFRESH_TOKEN_KEY, token)
    },
    saveFingerPrint (state, fingerprint) {
      state.fingerprint = fingerprint
      localStorage.setItem(FINGER_PRINT, fingerprint)
    },
    logout (state) {
      state.accessToken = null
      state.refreshToken = null
      localStorage.removeItem(ACCESS_TOKEN_KEY)
      localStorage.removeItem(REFRESH_TOKEN_KEY)
    },
    parseToken (state, token) {
      if (token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        state.jwtToken = JSON.parse(jsonPayload)
        state.user = state.jwtToken.info
      }
    },
    clearUserData (state) {
      localStorage.setItem(ACCESS_TOKEN_KEY, '')
      localStorage.setItem(REFRESH_TOKEN_KEY, '')

      state.user = null
      state.jwtToken = null
      state.accessToken = ''
      state.refreshToken = ''
    }
  },
  actions: {
    async signUp ({ state, commit, dispatch }, payload) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_BASE_VERSION}/${identity.signUp}`
        await axios.post(url, { ...payload })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async login ({ state, commit, dispatch }, payload) {
      try {
        if (!state.fingerprint) {
          commit('saveFingerPrint', uuidv4())
        }
        const url = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_BASE_VERSION}/${identity.login}`
        const { data } = await axios.post(url, { ...payload, fingerprint: state.fingerprint })
        commit('saveAccessToken', data.data.access_token)
        commit('saveRefreshToken', data.data.refresh_token)
        commit('parseToken', data.data.access_token)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.response.data.error.message)
      }
    },
    async logout ({ state, commit, dispatch }) {
      bus.emit({ event: USER_LOGOUT })
      if (state.user) {
        try {
          const url = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_BASE_VERSION}/${identity.logout}`
          await axios.put(url, null,
            {
              headers: {
                'Content-Type': 'application/json',
                'Refresh-Token': state.refreshToken
              }
            })
          commit('clearUserData')
          await router.push({ name: 'signIn' })
        } catch (e) {
          dispatch('notification/setMessage', {
            value: e.response.data.message,
            type: 'danger'
          }, { root: true })
          throw new Error(e.response.data.error.message)
        }
      } else {
        await router.push({ name: 'signIn' })
      }
    },
    async restorePassword ({ state, commit, dispatch }, payload) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_BASE_VERSION}/${identity.restorePass}`
        const { data } = await axios.put(url, { ...payload })
        dispatch('notification/setMessage', {
          value: data.message,
          type: 'success'
        }, { root: true })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.response.data.error.message)
      }
    },
    async newPassword ({ state, commit, dispatch }, payload) {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_BASE_VERSION}/${identity.newPassword}`
        const { data } = await axios.put(url, { ...payload })
        dispatch('notification/setMessage', {
          value: data.message,
          type: 'success'
        }, { root: true })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.response.data.error.message)
      }
    },
    async lkUpdatePassword ({ state, commit, dispatch }, payload) {
      try {
        const { data } = await ApiClient.put(identity.updatePassword, { ...payload })
        dispatch('notification/setMessage', {
          value: data.message,
          type: 'success'
        }, { root: true })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.response.data.error.message)
      }
    },
    async refreshToken ({ state, commit, dispatch }) {
      try {
        if (!state.fingerprint) {
          commit('saveFingerPrint', uuidv4())
        }
        const url = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_BASE_VERSION}/${identity.refreshToken}`
        const { data } = await axios.post(url, { fingerprint: state.fingerprint },
          {
            headers: {
              'Content-Type': 'application/json',
              'Refresh-Token': state.refreshToken
            }
          })
        commit('saveAccessToken', data.data.access_token)
        commit('saveRefreshToken', data.data.refresh_token)
        commit('parseToken', data.data.access_token)
      } catch (e) {
        bus.emit({ event: USER_LOGOUT })
        commit('clearUserData')
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        await router.push({ name: 'signIn' })
        throw new Error(e.message)
      }
    },
    checkIsTokenValid ({ state, dispatch, commit }) {
      if (state.accessToken) {
        if (!state.jwtToken) {
          commit('parseToken', state.accessToken)
        }
        console.log(state.jwtToken.exp - (Math.round(Date.now() / 1000) + 300))
        if (state.jwtToken.exp <= (Math.round(Date.now() / 1000) + 300)) {
          dispatch('refreshToken')
        }
      } else {
        try {
          dispatch('refreshToken')
        } catch (e) {
          commit('clearUserData')
          router.push({ name: 'signIn' })
        }
      }
      return true
    },
    canInit ({ state }) {
      const checkTokenInterval = setInterval(() => {
        if (state.jwtToken) {
          if (state.jwtToken.exp >= (Math.round(Date.now() / 1000))) {
            clearInterval(checkTokenInterval)
            bus.emit({ event: TOKEN_VALID })
          }
        }
      }, 50)
    },

    async saveNickname ({ state, commit, dispatch }, nickname) {
      try {
        const body = {
          nickname
        }
        const { data } = await ApiClient.put(identity.updateNickname(state.user.id), body)
        dispatch('refreshToken')
        dispatch('notification/setMessage', {
          value: data.message,
          type: 'success'
        }, { root: true })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.response.data.error.message)
      }
    },
    async saveTimeZone ({ state, commit, dispatch }, timezone) {
      const body = {
        timezone
      }
      try {
        const { data } = await ApiClient.put(identity.updateTimezone(state.user.id), body)
        dispatch('refreshToken')
        dispatch('notification/setMessage', {
          value: data.message,
          type: 'success'
        }, { root: true })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.response.data.error.message)
      }
    }
  }
}
