const billingRoutes = [
  {
    path: '/subscription-ended',
    name: 'subscriptionEnded',
    meta: {
      layout: 'app',
      auth: true
    },
    component: () => import('@/modules/billing/pages/SubscriptionEnded')
  },
  {
    path: '/subscription-paid',
    name: 'subscriptionPaid',
    meta: {
      layout: 'app',
      auth: true
    },
    component: () => import('@/modules/billing/pages/SubscriptionPaid')
  }
]

export default billingRoutes
