import Landing from '@/modules/landing/pages/Landing'

const landingRoutes = [
  {
    path: '/',
    name: 'landing',
    meta: { layout: 'landing' },
    component: Landing
  }
]

export default landingRoutes
