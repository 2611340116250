import { useEventBus } from '@vueuse/core'
import ApiClient from '@/modules/shared/utils/api'
import { sound } from '@/modules/sound/repository/sound'
import { SOUND_ADDED_TO_PLAY, SOUNDS_LOADED } from '@/modules/sound/event'
import { shuffle } from '@/modules/shared/utils/shuffle'

const bus = useEventBus('sound')
const SOUNDS_VOLUME = 'sounds-volume'
const SOUND_SELECTED_CATEGORIES = 'sound-selected-categories'
const SOUND_SELECTED_TAGS = 'sound-selected-tags'

export default {
  namespaced: true,
  state: {
    activeSounds: [],
    soundsVolume: JSON.parse(localStorage.getItem(SOUNDS_VOLUME)) || [],
    sounds: [],
    favoriteSounds: [],
    tags: [],
    categories: [],
    favTags: [],
    favCategories: [],
    playlists: [],
    loop: true,
    selectedCategories: JSON.parse(localStorage.getItem(SOUND_SELECTED_CATEGORIES)) || [],
    selectedTags: JSON.parse(localStorage.getItem(SOUND_SELECTED_TAGS)) || [],
    selectedFavCategories: [],
    selectedFavTags: [],
    lastMusicSoundsTab: null
  },
  getters: {
    activeSounds (state) {
      return state.activeSounds
    },
    sounds (state) {
      return shuffle(state.sounds)
    },
    tags (state) {
      return state.tags
    },
    categories (state) {
      return state.categories
    },
    favTags (state) {
      return state.favTags
    },
    favCategories (state) {
      return state.favCategories
    },
    savedSoundsVolume (state) {
      return state.soundsVolume
    },
    selectedCategories (state) {
      return state.selectedCategories
    },
    selectedTags (state) {
      return state.selectedTags
    },
    selectedFavCategories (state) {
      return state.selectedFavCategories
    },
    selectedFavTags (state) {
      return state.selectedFavTags
    },
    lastMusicSoundsTab (state) {
      return state.lastMusicSoundsTab
    },
    favoriteSound (state) {
      return state.favoriteSounds
    }
  },
  mutations: {
    setSoundTracks (state, payload) {
      payload.forEach((sound) => {
        const volume = state.soundsVolume.find((item) => item.id === sound.id)
        sound.volume = (volume ? volume.volume : sound.volume) * 100
      })
      state.sounds = payload
    },
    setActiveSound (state, sound) {
      const findedIndex = state.activeSounds.findIndex((item) => item.sound.id === sound.sound.id)
      if (findedIndex !== -1) {
        state.activeSounds.splice(findedIndex, 1)
      } else {
        state.activeSounds.push(sound)
        bus.emit({ event: SOUND_ADDED_TO_PLAY, data: sound })
      }
    },
    setSoundTags (state, payload) {
      state.tags = payload
    },
    setSoundCategories (state, payload) {
      state.categories = payload
    },
    setFavoriteTags (state, payload) {
      state.favTags = payload
    },
    setFavoriteCategories (state, payload) {
      state.favCategories = payload
    },
    removeActiveSound (state, sound) {
      const index = state.activeSounds.findIndex((item) => item.sound.id === sound.sound.id)
      state.activeSounds.splice(index, 1)
    },
    clearActiveSound (state) {
      state.activeSounds = []
    },
    putVolumeToStorage (state, payload) {
      const volumes = state.soundsVolume
      const soundIndex = volumes.findIndex((item) => item.id === payload.id)
      if (soundIndex === -1) {
        volumes.push({ ...payload })
      } else {
        volumes[soundIndex].volume = payload.volume
      }
      localStorage.setItem(SOUNDS_VOLUME, JSON.stringify(volumes))
    },
    setSelectedCategories (state, payload) {
      state.selectedCategories = payload
      localStorage.setItem(SOUND_SELECTED_CATEGORIES, JSON.stringify(payload))
    },
    setSelectedTags (state, payload) {
      state.selectedTags = payload
      localStorage.setItem(SOUND_SELECTED_TAGS, JSON.stringify(payload))
    },
    addCategory (state, genre) {
      if (state.selectedCategories.find((item) => item.id === genre.id)) {
        state.selectedCategories.splice(state.selectedCategories.indexOf(genre), 1)
        localStorage.setItem(SOUND_SELECTED_CATEGORIES, JSON.stringify(state.selectedCategories))
        return
      }
      state.selectedCategories.push(genre)
      localStorage.setItem(SOUND_SELECTED_CATEGORIES, JSON.stringify(state.selectedCategories))
    },
    addTag (state, tag) {
      if (state.selectedTags.find((item) => item.id === tag.id)) {
        state.selectedTags.splice(state.selectedTags.indexOf(tag), 1)
        localStorage.setItem(SOUND_SELECTED_TAGS, JSON.stringify(state.selectedTags))
        return
      }
      state.selectedTags.push(tag)
      localStorage.setItem(SOUND_SELECTED_CATEGORIES, JSON.stringify(state.selectedTags))
    },
    addFavCategory (state, category) {
      if (state.selectedFavCategories.find((item) => item.id === category.id)) {
        state.selectedFavCategories.splice(state.selectedFavCategories.indexOf(category), 1)
        return
      }
      state.selectedFavCategories.push(category)
    },
    addFavTag (state, tag) {
      if (state.selectedFavTags.find((item) => item.id === tag.id)) {
        state.selectedFavTags.splice(state.selectedFavTags.indexOf(tag), 1)
        return
      }
      state.selectedFavTags.push(tag)
    },
    setSelectedFavCategories (state, payload) {
      state.selectedFavCategories = payload
    },
    setSelectedFavTags (state, payload) {
      state.selectedFavTags = payload
    },
    setLastMusicSoundsTab (state, payload) {
      state.lastMusicSoundsTab = payload
    }
  },
  actions: {
    async loadSoundTags ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          type: 'SOUND',
          locale: 'ru_RU',
          categories: payload.categories ? payload.categories.toString() : null,
          playlistId: payload.playlistId ? payload.playlistId : null,
          isFavorite: payload.isFavorite
        }
        const { data } = await ApiClient.get(sound.tags(), { params })
        if (payload.isFavorite) {
          commit('setFavoriteTags', data.data)
        } else {
          commit('setSoundTags', data.data)
        }
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadSoundCategories ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          statuses: 'ACTIVE',
          isFavorite: payload.isFavorite
        }
        const { data } = await ApiClient.get(sound.categories(), { params })
        if (payload.isFavorite) {
          commit('setFavoriteCategories', data.data)
        } else {
          commit('setSoundCategories', data.data)
        }
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadSound ({ state, commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          statuses: 'ACTIVE',
          search: payload.search,
          tags: payload.tags ? payload.tags.toString() : null,
          categories: payload.categories ? payload.categories.toString() : null,
          playlistId: payload.playlistId ? payload.playlistId : null
        }
        const { data } = await ApiClient.get(sound.sound(), { params })
        if (payload.playlistId && !payload.replace) {
          if (data.data.length === 0) {
            return
          }
        }
        commit('setSoundTracks', data.data)
        bus.emit({ event: SOUNDS_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadFavoriteSound ({ commit, dispatch }, payload) {
      try {
        const params = {
          statuses: 'ACTIVE',
          search: payload.search,
          tags: payload.tags ? payload.tags.toString() : null,
          categories: payload.categories ? payload.categories.toString() : null
        }
        const { data } = await ApiClient.get(sound.favorites(), { params })
        commit('setSoundTracks', data.data)
        bus.emit({ event: SOUNDS_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async addToFavorite ({ commit, dispatch }, track) {
      try {
        const payload = {
          audioType: 'SOUND'
        }
        const { data } = await ApiClient.post(sound.addToFavorite(track.id), payload)
        track.favoriteId = data.data.id
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async removeFromFavorite ({ commit, dispatch }, track) {
      try {
        await ApiClient.delete(sound.removeFromFavorite(track.favoriteId))
        track.favoriteId = null
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async saveMusicHistory ({ commit, dispatch }, payload) {
      try {
        if (payload.duration <= 5 || payload.duration > 900) {
          return
        }
        const body = {
          audioType: 'SOUND',
          duration: payload.duration
        }
        await ApiClient.post(sound.history(payload.track.id), body)
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  }
}
