<template>
  <div
    class="video"
    ref="videoContainer"
    :class="{ 'full-mode': isVideoFullMode }"
    @dblclick="fullScreen"
  >
    <video
      class="video-container"
      muted
      loop
      ref="video"
    >
      <source
        v-if="activeVideo.video"
        :src="videoSrc(activeVideo.video)"
        type="video/mp4"
      >
    </video>
    <div
      v-if="activeVideo.video"
      @click="playVideo()"
      :class="{ isShow: !activeVideo.video.isPlaying ? false : isVideoOutside || idle }"
      class="layout"
    >
      <div class="info">
        <div class="title">{{ activeVideo.video.title }}</div>
        <div class="author"> {{ activeVideo.video.author }}</div>
      </div>
      <div class="controls">
        <img
          @click.stop="addToFavorite(activeVideo.video)"
          class="controls__fav"
          :src="isFavorite(activeVideo.video)"
          alt=""
        >
        <div @click.stop class="relative">
          <img @click.stop="settings" class="controls__settings" :src="isSettingOpen()" alt="">
          <div
            :class="{ active: isQualityTooltipOpen }"
            class="controls__settings_tooltip">
            <ul>
              <li v-for="item in availableQuality"
                  :class="{ active: videoQuality === item.quality }"
                  @click="setQuality(item)"
                  :key="item.id"
              >{{ item.quality }}</li>
            </ul>
          </div>
        </div>
        <img @click.stop="fullScreen" class="controls__full" src="@/assets/img/icon/player/ic_full_screen.svg" alt="">
      </div>
      <img class="play-video" :src="isPlayingVideo()" alt="">
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useEventBus, useEventListener, useIdle, useMouseInElement } from '@vueuse/core'
import { VIDEO_RELOAD, VIDEO_SELECTED } from '@/modules/video/event'

export default {
  setup () {
    const bus = useEventBus('video')
    const store = useStore()
    const activeVideo = computed(() => store.getters['video/activeVideo'])
    const videoQuality = computed(() => store.getters['video/videoQuality'])
    const availableQuality = store.getters['video/availableQuality']
    const video = ref(null)
    const videoContainer = ref(null)
    const isQualityTooltipOpen = ref(false)
    const isVideoFullMode = ref(false)
    const { isOutside: isVideoOutside } = useMouseInElement(video)
    const { idle } = useIdle(2000)

    const startWatch = ref(null)
    const endWatch = ref(null)
    const lastActiveVideo = ref(null)

    const fullScreen = () => {
      if (!isVideoFullMode.value) {
        if (videoContainer.value.requestFullscreen) {
          videoContainer.value.requestFullscreen({ navigationUI: 'hide' })
        } else if (videoContainer.value.msRequestFullscreen) {
          videoContainer.value.msRequestFullscreen({ navigationUI: 'hide' })
        } else if (videoContainer.value.mozRequestFullScreen) {
          videoContainer.value.mozRequestFullScreen({ navigationUI: 'hide' })
        } else if (videoContainer.value.webkitRequestFullscreen) {
          videoContainer.value.webkitRequestFullscreen({ navigationUI: 'hide' })
        }
      } else {
        document.exitFullscreen()
      }

      isVideoFullMode.value = !isVideoFullMode.value
      video.value.controls = false
      isQualityTooltipOpen.value = false
    }
    const videoSrc = (video) => {
      return `${process.env.VUE_APP_BASE_URL}${video.src}_${videoQuality.value}`
    }

    const saveVideoHistory = () => {
      store.dispatch('video/saveVideoHistory', {
        video: lastActiveVideo.value,
        duration: Math.floor((endWatch.value - startWatch.value) / 1000)
      })
    }

    useEventListener(video, 'play', () => {
      if (startWatch.value !== null) {
        endWatch.value = Date.now()
        saveVideoHistory()
      }
      startWatch.value = Date.now()
      lastActiveVideo.value = activeVideo.value.video
    })

    useEventListener(video, 'stop', () => {
      endWatch.value = Date.now()
      startWatch.value = null
    })

    useEventListener(video, 'pause', () => {
      endWatch.value = Date.now()
      saveVideoHistory()
      startWatch.value = null
    })

    bus.on((event) => {
      if (event.event === VIDEO_RELOAD) {
        video.value.load()
      }
      if (event.event === VIDEO_SELECTED) {
        video.value.load()
        video.value.play()
      }
    })

    document.addEventListener('fullscreenchange', (e) => {
      isVideoFullMode.value = !!document.fullscreenElement
    })

    return {
      idle,
      isQualityTooltipOpen,
      availableQuality,
      videoQuality,
      isVideoFullMode,
      isVideoOutside,
      video,
      videoContainer,
      activeVideo,
      videoSrc,
      playVideo: () => {
        if (!activeVideo.value.video.isPlaying) {
          store.commit('video/setActiveVideoPlaying')
          video.value.play()
        } else {
          store.commit('video/setActiveVideoStop')
          video.value.pause()
        }
      },
      isPlayingVideo () {
        if (!activeVideo.value.video.isPlaying) {
          return require('@/assets/img/icon/player/ic_play_video.svg')
        } else {
          return require('@/assets/img/icon/player/ic_stop_music.svg')
        }
      },
      settings: () => { isQualityTooltipOpen.value = !isQualityTooltipOpen.value },
      isFavorite: (video) => {
        if (video.favoriteId !== null) {
          return require('@/assets/img/icon/player/ic_favorite.svg')
        }
        return require('@/assets/img/icon/player/ic_favorite_empty.svg')
      },
      addToFavorite: (video) => {
        if (video.favoriteId === null) {
          store.dispatch('video/addToFavorite', { video })
        } else {
          store.dispatch('video/removeFromFavorite', { video })
        }
      },
      fullScreen,
      setQuality: (quality) => {
        store.commit('video/setVideoQuality', quality)
        video.value.load()
        video.value.play()
        isQualityTooltipOpen.value = !isQualityTooltipOpen.value
      },
      isSettingOpen: () => {
        if (isQualityTooltipOpen.value) {
          return require('@/assets/img/icon/player/ic_settings_active.svg')
        } else {
          return require('@/assets/img/icon/player/ic_settings.svg')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .video {
    user-select: none;
    position: relative;
    width: 100%;
    min-height: 29.2rem;
    max-height: 32rem;
    background-color: $dark-base-bg-2;
    border-radius: $radius-20;
    overflow: hidden;
    &.full-mode {
      //position: absolute;
      //top: 0;
      //left: 0;
      //width: 100vw;
      //height: 100vh;
      //max-height: 100vh;
      //z-index: 3;
      //border-radius: 0;
      border-radius: 0;
    }
    .video-container {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .preview {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: 1;
      filter: brightness(60%) contrast(90%);
    }
    .layout {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      transition: all ease-out .3s;
      opacity: 1;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 10%, transparent 60%);
      &.isShow {
        transition: all ease-out .3s;
        opacity: 0;
      }
      .play-video {
        -webkit-user-drag: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3.3rem;
        height: 3.3rem;
        transform: translate(-50%, -50%);
        z-index: 3;
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
      .info {
        position: absolute;
        left: 2.4rem;
        bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: .7rem;
        .title {
          color: $white-text;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 2rem;
        }
        .author {
          color: $white-text;
          opacity: .65;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
      .controls {
        position: absolute;
        right: 2.4rem;
        bottom: 2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        &__fav {
          -webkit-user-drag: none;
          transition: opacity ease-out .2s;
          width: 2.4rem;
          height: 2.4rem;
          opacity: 0.55;
          margin-top: -0.2rem;
          scale: 1.1;
          &:hover {
            cursor: pointer;
            transition: opacity ease-out .2s;
            opacity: 1;
          }
          &:active {
            cursor: pointer;
            transition: opacity ease-out .2s;
            opacity: .5;
          }
        }
        &__settings {
          width: 2.4rem;
          height: 2.4rem;
          transition: opacity ease-out .2s;
          opacity: 0.55;
          -webkit-user-drag: none;
          &:hover {
            cursor: pointer;
            transition: opacity ease-out .2s;
            opacity: 1;
          }
          &:active {
            transition: opacity ease-out .2s;
            cursor: pointer;
            opacity: .5;
          }
          &_tooltip {
            display: none;
            position: absolute;
            bottom: 3.4rem;
            right: -75%;
            box-shadow: $shadow;
            z-index: 2;
            background-color: $selected_dd;
            border-radius: $radius;
            padding: .7rem 1.6rem;
            cursor: default;
            ul {
              list-style: none;
              li {
                color: $white-text;
                font-size: 1.4rem;
                font-weight: 500;
                padding: .3rem 0;
                text-align: end;
                transition: all ease-out .2s;
                &.active {
                  color: $primary;
                }
                &:hover {
                  cursor: pointer;
                  opacity: .7;
                }
                &:active {
                  cursor: pointer;
                  opacity: .5;
                }
              }
            }
            &.active {
              display: flex;
            }
          }
        }
        &__full {
          width: 2.4rem;
          height: 2.4rem;
          opacity: 0.55;
          -webkit-user-drag: none;
          transition: opacity ease-out .2s;
          &:hover {
            transition: opacity ease-out .2s;
            cursor: pointer;
            opacity: 1;
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
