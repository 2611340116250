<template>
  <div class="top-menu" :class="{ expanded: isSceneExpanded }">
    <div class="left-side">
      <div class="top-menu__title" :class="{ expanded: isSceneExpanded }"> {{ activeMenu.name }}</div>
    </div>
    <div class="top-menu__actions">
      <div class="chat_icon">
        <img @click="openChat()" :src="chatIcon()" alt="">
        <div class="badge" v-if="isChatNewMessage"></div>
      </div>
      <img src="@/assets/img/icon/top/ic_notify.svg" alt="">
      <img @click.prevent="isOpen = !isOpen" :src=profileIcon() alt="">
      <div v-if="isOpen" ref="profileDrop" class="profile-action-card">
        <ul>
          <li><img src="@/assets/img/icon/profile/ic_mail.svg" alt="">{{ user.email }}</li>
          <li @click="toProfile()" ><img src="@/assets/img/icon/profile/ic_profile.svg" alt="">Профиль</li>
          <li @click="toProfile()"><img src="@/assets/img/icon/profile/ic_tariff.svg" alt="">Подписка</li>
          <li @click="toProfile()"><img src="@/assets/img/icon/profile/ic_ref.svg" alt="">Реф. ссылка</li>
          <li @click="logout"><img src="@/assets/img/icon/profile/ic_logout.svg" alt="">Выйти</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import useMainMenu from '@/modules/app/use/menu'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { onClickOutside, useEventBus } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { CHAT_SCROLL_TO_LAST } from '@/modules/help/event'

export default {
  setup () {
    const store = useStore()
    const router = useRouter()

    const helpBus = useEventBus('help')
    const isOpen = ref(false)
    const isChatOpen = computed(() => store.getters['help/chatOpen'])
    const profileDrop = ref(null)
    const isSceneExpanded = ref(false)
    const user = store.getters['auth/user']
    const isChatNewMessage = computed(() => store.getters['help/isChatNewMessage'])

    onClickOutside(
      profileDrop,
      () => {
        setTimeout(() => { isOpen.value = false }, 1)
      }
    )

    return {
      isChatNewMessage,
      isSceneExpanded,
      user,
      profileDrop,
      isOpen,
      profileIcon: () => {
        return isOpen.value
          ? require('@/assets/img/icon/top/ic_user_active.svg')
          : require('@/assets/img/icon/top/ic_user.svg')
      },
      chatIcon: () => {
        return isChatOpen.value
          ? require('@/assets/img/icon/top/ic_chat_active.svg')
          : require('@/assets/img/icon/top/ic_chat.svg')
      },
      logout: () => store.dispatch('auth/logout'),
      ...useMainMenu(),
      toProfile: () => {
        router.push({ name: 'profile' })
        isOpen.value = false
      },
      openChat: () => {
        store.commit('help/setChatOpen', !store.getters['help/chatOpen'])
        setTimeout(() => {
          helpBus.emit({ event: CHAT_SCROLL_TO_LAST })
        }, 50)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .top-menu {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 4rem 0 4rem;
    z-index: 10;
    transform: translateX(0);
    &.expanded {
      width: 90%;
      transform: translateX(11.1%);
    }
    & .left-side {
      display: flex;
      justify-items: flex-start;
      align-items: center;
    }
    &__title {
      min-width: 11rem;
      font-size: 2.2rem;
      line-height: 2.4rem;
      font-weight: 600;
      color: $white-text;
      user-select: none;transition: all ease-out 0.5s;
      &.expanded {
        opacity: 0;
        transition: all ease-out 0s;
      }
    }
    &__actions {
      position: relative;
      user-select: none;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      color: $white-text;
      gap: 1.5rem;
      transition: all ease-out .2s;
      & .chat_icon {
        position: relative;
        img {
          width: 2.4rem;
          height: 2.4rem;
        }
        & .badge {
          position: absolute;
          top: -.1rem;
          right: -.1rem;
          background-color: $primary;
          width: 1.3rem;
          height: 1.3rem;
          border-radius: 50%;
          border: .3rem solid $dark-base-bg-2;
        }
      }
      img:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: all ease-out .2s;
      }
      img:active {
        cursor: pointer;
        opacity: 0.5;
        transition: all ease-out .2s;
      }
    }
  }
  .profile-action-card {
    position: absolute;
    top: 4.5rem;
    right: 0;
    background-color: $selected_dd;
    width: 23rem;
    height: auto;
    padding: 1.5rem 2rem;
    border-radius: $radius-20;
    box-shadow: $shadow;
    z-index: 10;
    ul {
      user-select: none;
      list-style: none;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 500;
      color: $white-text;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      li {
        position: relative;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        border-radius: $radius;
        transition: all ease-out .2s;
        max-width: 19rem;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        img {
          user-select: none;
          -webkit-user-drag: none;
          margin-right: 1rem;
          width: 2.8rem;
          height: 2.8rem;
        }
        &:hover {
          cursor: pointer;
          background-color: $dark-base-bg-2;
          transition: all ease-out .2s;
        }
      }
    }
  }
</style>
