export const SCENES_LOADED = 'scenesLoaded'
export const SCENE_CARD_LOADED = 'sceneCardLoad'
export const SCENE_TAGS_LOADED = 'sceneTagsLoaded'
export const SCENES_SERVICE_LOADED = 'scenesServiceLoaded'
export const SCENE_SELECTED = 'sceneSelected'
export const SCENE_FAVORITE_LOADED = 'sceneFavoriteLoaded'
export const SCENE_VIDEO_RELOAD = 'sceneReloadVideo'
export const SCENE_VIDEO_SELECTED = 'sceneSelectedVideo'
export const SCENE_EXPANDED = 'sceneExpanded'
export const SCENE_COLLAPSED = 'sceneCollapsed'
export const SCENE_PLAYING = 'scenePlaying'
export const SCENE_PAUSE = 'scenePause'
export const SCENE_RESUME = 'sceneResume'
export const SCENE_STOP = 'sceneStop'
export const SCENE_VOLUME_CHANGE = 'sceneVolumeChange'
export const SCENE_ADDED_TO_FAVORITE = 'sceneAddedToFavorite'
export const SCENE_REMOVED_FROM_FAVORITE = 'sceneRemovedFromFavorite'
