<template>
  <div class="modal">
    <div
      ref="card"
      class="card"
      :style="{ width: mWidth+'rem', height: mHeight+'rem' }"
    >
      <div class="card__title">{{ mTitle }}
        <span @click="$emit('close')" style="height: 2.4rem; width: 2.4rem" class="card__close"/>
      </div>
      <div class="card__body">
        <img :src="url" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
  emits: ['close'],
  props: ['title', 'width', 'height', 'url'],
  setup (props, { emit }) {
    const mTitle = ref(props.title)
    const mWidth = ref(props.width)
    const mHeight = ref(props.height)
    const mUrl = computed(() => props.url)

    const card = ref(null)

    onClickOutside(
      card,
      (event) => {
        setTimeout(() => {
          emit('close')
        }, 10)
      }
    )

    return {
      mUrl,
      card,
      mTitle,
      mWidth,
      mHeight
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 36, 39, 0.80);
  z-index: 100;
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: $radius-20;
    box-shadow: $shadow;
    overflow: hidden;
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $white;
      font-size: 1.8rem;
      font-weight: 700;
    }
    &__close {
      position: absolute;
      top: 3rem;
      right: 2rem;
      background-image: url("@/assets/img/icon/ic_close_gray.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 1.8rem;
      height: 1.8rem;
      z-index: 103;
      @include default-hover-active;
    }
    &__body {
      img {
        user-select: none;
        -webkit-user-drag: none;
        position: relative;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 10rem);
        max-width: calc(100vw - 10rem);
        object-fit: contain;
        z-index: 102;
      }
    }
  }
}
</style>
