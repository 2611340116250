<template>
  <div
    class="video"
    ref="videoContainer"
    :class="{ 'full-mode': isVideoFullMode }"
    @dblclick="fullScreen"
  >
    <video
      class="video-container"
      muted
      loop
      ref="video"
      :poster="defineSceneCover(activeScene.scene)"
    >
      <source
        v-if="activeVideo.video"
        :src="videoSrc(activeVideo.video)"
        type="video/mp4"
      >
    </video>
    <div
      v-if="activeVideo.video"
      @click="playScene()"
      :class="{ isShow: !activeScene.isPlaying ? false : isVideoOutside || idle }"
      class="layout"
    >
      <div class="info" :class="{ expanded: isExpand }">
        <div class="title">{{ activeScene.scene.title }}</div>
        <div class="genres">
          <div
            class="genre"
            v-for="item in activeScene.scene.tags.split(',')"
            :key="item"
          >{{ capitalize(item) }}</div>
        </div>
        <div class="description"> {{ activeScene.scene.description }}</div>
      </div>
      <div class="controls">
        <div
          @click.stop
          @mouseover="mouseVolumeOver()"
          @mouseleave="mouseVolumeLeave()"
          class="relative"
        >
          <img
            @click.stop
            class="controls__volume"
            src="@/assets/img/icon/player/ic_volume.svg"
            alt=""
          >
          <Transition>
            <div v-if="isVolumeHovered" class="controls__volume_scene">
              <span>S</span>
              <AppVerticalSlider
                class="vt-slider"
                color="primary"
                @seekMouseMove="sceneVolumeMove()"
                @mouseup="sceneVolumeMove()"
                v-model="activeScene.scene.masterVolume"
              />
            </div>
          </Transition>
        </div>
        <img
          @click.stop="addToFavorite(activeScene.scene)"
          class="controls__fav"
          :src="isFavorite(activeScene.scene)"
          alt=""
        >
        <div @click.stop class="relative">
          <img @click.stop="settings" class="controls__settings" :src="isSettingOpen()" alt="">
          <div
            :class="{ active: isQualityTooltipOpen }"
            class="controls__settings_tooltip">
            <ul>
              <li v-for="item in availableQuality"
                  :class="{ active: videoQuality === item.quality }"
                  @click="setQuality(item)"
                  :key="item.id"
              >{{ item.quality }}</li>
            </ul>
          </div>
        </div>
        <img @click.stop="fullScreen" class="controls__full" src="@/assets/img/icon/player/ic_full_screen.svg" alt="">
      </div>
      <img class="play-video" :src="isPlayingScene()" alt="">
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useEventBus, useEventListener, useIdle, useMouseInElement } from '@vueuse/core'
import {
  SCENE_COLLAPSED,
  SCENE_EXPANDED,
  SCENE_VIDEO_RELOAD,
  SCENE_VIDEO_SELECTED,
  SCENE_VOLUME_CHANGE
} from '@/modules/scene/event'
import { capitalize } from '@/modules/shared/utils/capitalize'
import AppVerticalSlider from '@/components/ui/slider/AppVerticalSlider'
import { roundFloat } from '@/modules/shared/utils/roundFloat'

export default {
  components: { AppVerticalSlider },
  props: ['isExpand'],
  setup () {
    const bus = useEventBus('scene')
    const store = useStore()
    const activeVideo = computed(() => store.getters['scene/activeVideo'])
    const activeScene = computed(() => store.getters['scene/activeScene'])
    const videoQuality = computed(() => store.getters['video/videoQuality'])
    const availableQuality = store.getters['video/availableQuality']
    const video = ref(null)
    const videoContainer = ref(null)
    const isQualityTooltipOpen = ref(false)
    const isVideoFullMode = ref(false)
    const { isOutside: isVideoOutside } = useMouseInElement(video)
    const { idle } = useIdle(2000)

    const isVolumeHovered = ref(false)

    const startWatch = ref(null)
    const endWatch = ref(null)
    const lastActiveVideo = ref(null)

    const fullScreen = () => {
      if (!isVideoFullMode.value) {
        if (videoContainer.value.requestFullscreen) {
          videoContainer.value.requestFullscreen({ navigationUI: 'hide' })
        } else if (videoContainer.value.msRequestFullscreen) {
          videoContainer.value.msRequestFullscreen({ navigationUI: 'hide' })
        } else if (videoContainer.value.mozRequestFullScreen) {
          videoContainer.value.mozRequestFullScreen({ navigationUI: 'hide' })
        } else if (videoContainer.value.webkitRequestFullscreen) {
          videoContainer.value.webkitRequestFullscreen({ navigationUI: 'hide' })
        }
      } else {
        document.exitFullscreen()
      }

      isVideoFullMode.value = !isVideoFullMode.value
      video.value.controls = false
      isQualityTooltipOpen.value = false
    }
    const videoSrc = (video) => {
      return `${process.env.VUE_APP_BASE_URL}${video.src}_${videoQuality.value}`
    }

    const saveVideoHistory = () => {
      store.dispatch('video/saveVideoHistory', {
        video: lastActiveVideo.value,
        duration: Math.floor((endWatch.value - startWatch.value) / 1000)
      })
    }

    useEventListener(video, 'play', () => {
      if (startWatch.value !== null) {
        endWatch.value = Date.now()
        saveVideoHistory()
      }
      startWatch.value = Date.now()
      lastActiveVideo.value = activeVideo.value.video
      activeVideo.value.isPlaying = true
    })

    useEventListener(video, 'stop', () => {
      endWatch.value = Date.now()
      startWatch.value = null
      activeVideo.value.isPlaying = false
    })

    useEventListener(video, 'pause', () => {
      endWatch.value = Date.now()
      saveVideoHistory()
      startWatch.value = null
      activeVideo.value.isPlaying = false
    })

    bus.on((event) => {
      if (event.event === SCENE_VIDEO_RELOAD) {
        video.value.load()
      }
      if (event.event === SCENE_VIDEO_SELECTED) {
        video.value.load()
        video.value.play()
      }
      if (event.event === SCENE_VOLUME_CHANGE) {
        if (activeScene.value.scene.masterVolume !== store.getters['scene/volume']) {
          // activeScene.value.scene.masterVolume = store.getters['scene/volume']
        }
      }
      if (event.event === SCENE_EXPANDED) {
        if (activeVideo.value.isPlaying) {
          video.value.play()
        } else {
          video.value.pause()
        }
      }
      if (event.event === SCENE_COLLAPSED) {
        if (activeVideo.value.isPlaying) {
          video.value.play()
        } else {
          video.value.pause()
        }
      }
    })

    document.addEventListener('fullscreenchange', (e) => {
      isVideoFullMode.value = !!document.fullscreenElement
    })

    return {
      isVolumeHovered,
      activeScene,
      idle,
      isQualityTooltipOpen,
      availableQuality,
      videoQuality,
      isVideoFullMode,
      isVideoOutside,
      video,
      videoContainer,
      activeVideo,
      videoSrc,
      playScene: () => {
        switch (activeScene.value.status) {
          case 'ready':
            store.commit('scene/setActiveScenePlaying')
            video.value.play()
            break
          case 'stop':
            store.commit('scene/setActiveScenePlaying')
            video.value.play()
            break
          case 'play':
            store.commit('scene/setActiveScenePause')
            video.value.pause()
            break
          case 'pause':
            store.commit('scene/setActiveSceneResume')
            video.value.play()
            break
        }
      },
      isPlayingScene () {
        if (!activeScene.value.isPlaying) {
          return require('@/assets/img/icon/player/ic_play_video.svg')
        } else {
          return require('@/assets/img/icon/player/ic_stop_music.svg')
        }
      },
      settings: () => { isQualityTooltipOpen.value = !isQualityTooltipOpen.value },
      isFavorite: (scene) => {
        if (scene.favoriteId !== null) {
          return require('@/assets/img/icon/player/ic_favorite.svg')
        }
        return require('@/assets/img/icon/player/ic_favorite_empty.svg')
      },
      addToFavorite: (scene) => {
        if (scene.favoriteId === null) {
          store.dispatch('scene/addToFavorite', scene)
        } else {
          store.dispatch('scene/removeFromFavorite', scene)
        }
      },
      fullScreen,
      setQuality: (quality) => {
        store.commit('video/setVideoQuality', quality)
        video.value.load()
        video.value.play()
        isQualityTooltipOpen.value = !isQualityTooltipOpen.value
      },
      isSettingOpen: () => {
        if (isQualityTooltipOpen.value) {
          return require('@/assets/img/icon/player/ic_settings_active.svg')
        } else {
          return require('@/assets/img/icon/player/ic_settings.svg')
        }
      },
      defineSceneCover: (item) => {
        return `${process.env.VUE_APP_BASE_URL}/storage${item.cover}`
      },
      capitalize,
      mouseVolumeOver: () => {
        isVolumeHovered.value = true
      },
      mouseVolumeLeave: () => {
        isVolumeHovered.value = false
      },
      sceneVolumeMove: () => {
        const volume = roundFloat(
          activeScene.value.scene.masterVolume / 100 <= 0.01 ? 0 : activeScene.value.scene.masterVolume / 100,
          2
        )
        if (volume !== store.getters['scene/volume']) {
          store.commit('scene/setSceneVolume', volume)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .video {
    user-select: none;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $dark-base-bg-2;
    border-radius: $radius-20;
    overflow: hidden;
    &.full-mode {
      border-radius: 0;
    }
    .video-container {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .preview {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: 1;
      filter: brightness(60%) contrast(90%);
    }
    .layout {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      transition: all ease-out .3s;
      opacity: 1;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 10%, transparent 60%);
      &.isShow {
        transition: all ease-out .3s;
        opacity: 0;
      }
      .play-video {
        -webkit-user-drag: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3.3rem;
        height: 3.3rem;
        transform: translate(-50%, -50%);
        z-index: 3;
        &:hover {
          cursor: pointer;
          opacity: .7;
        }
        &:active {
          cursor: pointer;
          opacity: .5;
        }
      }
      .info {
        width: 100%;
        position: absolute;
        left: 2.4rem;
        bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: .7rem;
        .title {
          color: $white-text;
          font-weight: 600;
          font-size: 2rem;
          line-height: 2.4rem;
        }
        .genres {
          display: flex;
          align-items: center;
          margin-top: .5rem;
          & .genre {
            padding: 0.1rem 1rem;
            background-color: $selected_dd;
            color: $white-70;
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.8rem;
            border-radius: .8rem;
            margin-right: 5rem;
          }
        }
        .description {
          margin-top: .5rem;
          color: $white-80;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 2rem;
          max-width: 70%;
        }
      }
      .controls {
        position: absolute;
        right: 2.4rem;
        bottom: 2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        &__volume {
          -webkit-user-drag: none;
          width: 2.8rem;
          height: 2.8rem;
          opacity: 0.55;
          @include white-hover-active;
          &_scene {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: absolute;
            bottom: 3.5rem;
            left: -.7rem;
            background-color: $selected_dd;
            border-radius: $radius;
            height: 12rem;
            width: 4rem;
            padding: 1rem 1.5rem;
            box-shadow: $shadow;
            span {
              position: absolute;
              top: 1rem;
              left: 50%;
              transform: translateX(-50%);
              font-weight: 600;
              font-size: 1.4rem;
              line-height: 1.8rem;
              margin-bottom: 1rem;
              color: $white-text;
            }
            .vt-slider {
              width: 8rem;
              margin-top: 2.2rem;
            }
          }
        }
        &__fav {
          -webkit-user-drag: none;
          transition: opacity ease-out .2s;
          width: 2.4rem;
          height: 2.4rem;
          opacity: 0.55;
          margin-top: -0.2rem;
          scale: 1.1;
          &:hover {
            cursor: pointer;
            transition: opacity ease-out .2s;
            opacity: 1;
          }
          &:active {
            cursor: pointer;
            transition: opacity ease-out .2s;
            opacity: .5;
          }
        }
        &__settings {
          width: 2.4rem;
          height: 2.4rem;
          transition: opacity ease-out .2s;
          opacity: 0.55;
          -webkit-user-drag: none;
          &:hover {
            cursor: pointer;
            transition: opacity ease-out .2s;
            opacity: 1;
          }
          &:active {
            transition: opacity ease-out .2s;
            cursor: pointer;
            opacity: .5;
          }
          &_tooltip {
            display: none;
            position: absolute;
            bottom: 3.4rem;
            right: -75%;
            box-shadow: $shadow;
            z-index: 2;
            background-color: $selected_dd;
            border-radius: $radius;
            padding: .7rem 1.6rem;
            cursor: default;
            ul {
              list-style: none;
              li {
                color: $white-text;
                font-size: 1.4rem;
                font-weight: 500;
                padding: .3rem 0;
                text-align: end;
                transition: all ease-out .2s;
                &.active {
                  color: $primary;
                }
                &:hover {
                  cursor: pointer;
                  opacity: .7;
                }
                &:active {
                  cursor: pointer;
                  opacity: .5;
                }
              }
            }
            &.active {
              display: flex;
            }
          }
        }
        &__full {
          width: 2.4rem;
          height: 2.4rem;
          opacity: 0.55;
          -webkit-user-drag: none;
          transition: opacity ease-out .2s;
          &:hover {
            transition: opacity ease-out .2s;
            cursor: pointer;
            opacity: 1;
          }
          &:active {
            cursor: pointer;
            opacity: .5;
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
