<template>
  <div>
    <div class="section__header">
      <div class="tags-header">
        <div class="tags-header__title">Звуки</div>
        <div @click="clearFilter()" class="tags-header__action">Сбросить</div>
      </div>
      <div class="section__search">
        <AppSearch class="search" v-model="search" placeholder="Поиск"/>
        <div class="tabs">
          <div
            class="tabs__tab"
            v-for="tab in tabList"
            :key="tab.id"
            :class="{ active: tab.id === soundTab.id }"
            @click="chooseTab(tab)"
          >{{ tab.name }}</div>
        </div>
      </div>
      <div class="genre-block">
        <AppTagsLine
          v-if="soundTab.alias === 'all'"
          :_tags="categories"
          :_selected-tags="selectedCategories"
          @choose="chooseCategory"
          empty="Нет доступных категорий"
        />
        <AppTagsLine
          v-if="soundTab.alias === 'favorite'"
          :_tags="favCategories"
          :_selected-tags="selectedFavCategories"
          @choose="chooseFavCategory"
          empty="Нет доступных категорий"
        />
      </div>
      <div class="tags-block">
        <AppTagsLine
          v-if="soundTab.alias === 'all'"
          :_tags="tags"
          :_selected-tags="selectedTags"
          @choose="chooseTag"
          empty="Нет доступных тэгов"
        />
        <AppTagsLine
          v-if="soundTab.alias === 'favorite'"
          :_tags="favTags"
          :_selected-tags="selectedFavTags"
          @choose="chooseFavTag"
          empty="Нет доступных тэгов"
        />
      </div>
    </div>
    <div class="section__tracks">
      <div v-if="sounds.length > 0" class="sound scrollable scrollbar">
        <AppSoundTrack
          :_tracks="sounds"
          translate-x="410"
          translate-y="27"
        />
      </div>
      <AppPlaylistCard class="playlist" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import AppSearch from '@/components/ui/search/AppSearch'
import { idsFromObject } from '@/modules/shared/utils/idsFromObject'
import AppTagsLine from '@/components/ui/tags/AppTagsLine'
import AppPlaylistCard from '@/modules/playlist/components/AppPlaylistCard'
import AppSoundTrack from '@/modules/sound/components/AppSoundTrack'
import { watchDebounced } from '@vueuse/core'

export default {
  components: { AppSoundTrack, AppPlaylistCard, AppTagsLine, AppSearch },
  setup () {
    const store = useStore()

    const categories = computed(() => store.getters['sound/categories'])
    const tags = computed(() => store.getters['sound/tags'])
    const favCategories = computed(() => store.getters['sound/favCategories'])
    const favTags = computed(() => store.getters['sound/favTags'])

    const selectedCategories = computed(() => store.getters['sound/selectedCategories'])
    const selectedTags = computed(() => store.getters['sound/selectedTags'])
    const selectedFavCategories = computed(() => store.getters['sound/selectedFavCategories'])
    const selectedFavTags = computed(() => store.getters['sound/selectedFavTags'])

    const sounds = computed(() => store.getters['sound/sounds'])
    const favoriteSounds = computed(() => store.getters['sound/favoriteSound'])

    const search = ref('')
    const tabList = [
      { id: 0, name: 'Все', active: true, alias: 'all' },
      { id: 1, name: 'Любимые', active: false, alias: 'favorite' }
    ]
    const lastTab = ref(store.getters['sound/lastMusicSoundsTab'] ?? tabList[0])
    const soundTab = ref(lastTab)

    watchDebounced(search, () => {
      if (search.value === '') {
        if (soundTab.value.alias === 'favorite') {
          store.dispatch('sound/loadFavoriteSound', {
            tags: idsFromObject(selectedFavTags.value),
            categories: idsFromObject(selectedFavCategories.value)
          })
        } else {
          store.dispatch('sound/loadSound', {
            tags: idsFromObject(selectedTags.value),
            categories: idsFromObject(selectedFavCategories.value)
          })
        }
      } else {
        if (soundTab.value.alias === 'favorite') {
          store.dispatch('sound/loadFavoriteSound', { search: search.value })
        } else {
          store.dispatch('sound/loadSound', { search: search.value })
        }
      }
    }, { debounce: 1000, maxWait: 5000 })

    return {
      categories,
      tags,
      favCategories,
      favTags,
      selectedCategories,
      selectedTags,
      selectedFavCategories,
      selectedFavTags,
      sounds,
      favoriteSounds,
      tabList,
      soundTab,
      search,
      clearFilter: () => {
        if (soundTab.value.alias === 'favorite') {
          store.commit('sound/setSelectedFavTags', [])
          store.commit('sound/setSelectedFavCategories', [])
          store.dispatch('sound/loadFavoriteSound', {})
        } else {
          store.commit('sound/setSelectedTags', [])
          store.commit('sound/setSelectedCategories', [])
          store.dispatch('sound/loadSound', {})
        }
      },
      chooseTab: (tab) => {
        search.value = ''
        if (tab.alias === 'favorite' && favoriteSounds.value.length === 0) {
          store.dispatch('sound/loadFavoriteSound', {
            tags: idsFromObject(selectedFavTags.value),
            categories: idsFromObject(selectedFavCategories.value)
          })
        }
        if (tab.alias === 'all') {
          store.dispatch('sound/loadSound', {
            categories: idsFromObject(selectedCategories.value),
            tags: idsFromObject(selectedTags.value)
          })
        }
        soundTab.value.active = false
        tab.active = true
        soundTab.value = tab
        store.commit('sound/setLastMusicSoundsTab', tab)
      },
      chooseCategory: (genre) => {
        store.commit('sound/addCategory', genre.tag)
        store.dispatch('sound/loadSound', {
          tags: idsFromObject(selectedTags.value),
          categories: idsFromObject(selectedCategories.value)
        })
        store.commit('playlist/setActiveSoundPlaylist', null)
      },
      chooseTag: (tag) => {
        store.commit('sound/addTag', tag.tag)
        store.dispatch('sound/loadSound', {
          tags: idsFromObject(selectedTags.value),
          categories: idsFromObject(selectedCategories.value)
        })
        store.commit('playlist/setActiveSoundPlaylist', null)
      },
      chooseFavCategory: (genre) => {
        store.commit('sound/addFavCategory', genre.tag)
        store.dispatch('sound/loadFavoriteSound', {
          tags: idsFromObject(selectedFavTags.value),
          categories: idsFromObject(selectedFavCategories.value)
        })
        store.commit('playlist/setActiveSoundPlaylist', null)
      },
      chooseFavTag: (tag) => {
        store.commit('sound/addFavTag', tag.tag)
        store.dispatch('sound/loadFavoriteSound', {
          tags: idsFromObject(selectedFavTags.value),
          categories: idsFromObject(selectedFavCategories.value)
        })
        store.commit('playlist/setActiveSoundPlaylist', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .section {
    &__header {
      & .tags-header {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__title {
          color: $white;
          font-size: 1.4rem;
          font-weight: 600;
          line-height: 2.4rem;
          user-select: none;
        }
        &__action {
          color: $second-text-2;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 2.2rem;
          @include default-hover-active;
        }
      }
      & .genre-block {
        margin-top: 1.4rem;
      }
      & .tags-block {
        position: relative;
        width: 100%;
        margin-top: .8rem;
      }
    }
    &__search {
      display: flex;
      align-items: center;
      border-radius: $radius;
      margin-top: 1.6rem;
      & .search {
        width: 100%;
      }
      & .tabs {
        position: relative;
        margin-left: 1rem;
        width: 20rem;
        height: 4rem;
        user-select: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(53, 55, 57, 0.6);
        border-radius: 1rem;
        padding: .3rem;
        z-index: 2;
        &__tab {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          text-align: center;
          border-radius: .8rem;
          transition: all ease-out .3s;
          z-index: 2;
          opacity: 1;
          color: $second-text-2;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.8rem;
          position: relative;
          padding: 0 2.6rem;
          &:hover {
            cursor: pointer;
            opacity: .8;
          }
          &.active {
            font-weight: 600;
            color: $white-text;
            transition: all ease-out .3s;
            background-color: $selected_dd;
            z-index: 2;
            opacity: 1;
          }
        }
      }
    }
    &__tracks {
      position: relative;
      margin-right: -1.5rem;
      & .sound {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        height: 61vh;
        padding-right: .8rem;
        margin-top: 3rem;
        @include scrollbar-dark;
      }
      & .playlist {
        position: absolute;
        left: 4rem;
        top: 0;
        width: 35rem;
        max-height: 40rem;
      }
    }
  }
</style>
