export const TRACKS_LOADED = 'tracksLoaded'
export const TRACK_CHANGED = 'trackChanged'
export const TRACK_PAUSED = 'trackPaused'
export const TRACK_RESUMED = 'trackResumed'
export const TRACK_INIT = 'trackInit'
export const TRACK_STOP = 'trackStop'
export const TRACK_PLAY = 'trackPlay'
export const TRACK_NEXT = 'trackNext'
export const TRACK_PREV = 'trackPrev'
export const TRACK_VOLUME_CHANGED = 'trackVolumeChanged'
export const TRACK_SEEK_CHANGED = 'trackSeekChanged'
export const MASTER_VOLUME_CHANGED = 'masterVolumeChanged'
export const SCROLL_TO_TRACK = 'scrollToTrack'
export const OPEN_PLAYLIST_MODAL = 'openPlaylistModal'
export const ARTIST_SELECTED = 'artistSelected'
export const TRACK_ADDED_TO_FAVORITE = 'trackAddedToFavorite'
export const TRACK_REMOVED_FROM_FAVORITE = 'trackRemovedFromFavorite'
