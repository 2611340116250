import { createApp } from 'vue'
import App from './App.vue'
import router from './modules/app/router'
import store from './modules/app/store'
import '@/assets/css/theme.scss'

const app = createApp(App)
  .use(store)
  .use(router)

app.mount('#app')
