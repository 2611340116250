import ApiClient from '@/modules/shared/utils/api'
import { help } from '@/modules/help/repository'
import { ALL_MESSAGES_LOADED, CHAT_OPENED } from '@/modules/help/event'
import { useEventBus } from '@vueuse/core'

const bus = useEventBus('help')

const message = {
  author: {
    id: 0
  },
  content: 'Здравствуйте!\n' +
    'Напишите ваш вопрос, идею или\nзамечание и мы ответим вам.',
  createdAt: null,
  id: 0,
  isLast: false
}

export default {
  namespaced: true,
  state: {
    tickets: [],
    ticketMessages: [],
    isChatOpen: false,
    isHelpOpen: false,
    isImgModalOpen: false,
    defaultMessage: {
      author: {
        id: 0
      },
      content: 'Здравствуйте!\n' +
        'Напишите ваш вопрос, идею или\nзамечание и мы ответим вам.',
      createdAt: null,
      id: 0,
      isLast: false
    },
    lastChatBlockDate: null,
    isChatNewMessage: false
  },
  getters: {
    helpOpen (state) {
      return state.isHelpOpen
    },
    chatOpen (state) {
      return state.isChatOpen
    },
    imgModalOpen (state) {
      return state.isImgModalOpen
    },
    tickets (state) {
      return state.tickets
    },
    messages (state) {
      return state.ticketMessages
    },
    dateTickets (state) {
      const groups = state.tickets.reduce((groups, ticket) => {
        const date = ticket.createdAt.split(' ')[0]
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(ticket)
        return groups
      }, {})

      return Object.keys(groups).map((date) => {
        return {
          date,
          tickets: groups[date]
        }
      })
    },
    defaultMessage (state) {
      return state.defaultMessage
    },
    lastChatBlockDate (state) {
      return state.lastChatBlockDate
    },
    isChatNewMessage (state) {
      return state.isChatNewMessage
    }
  },
  mutations: {
    setHelpOpen (state, value) {
      state.isHelpOpen = value
    },
    setChatOpen (state, value) {
      state.isChatOpen = value
      if (value) {
        bus.emit({ event: CHAT_OPENED })
      }
    },
    setImgModal (state, value) {
      state.isImgModalOpen = value
    },
    setTickets (state, payload) {
      state.tickets = payload
    },
    setTicketMessages (state, payload) {
      state.ticketMessages = payload
    },
    setLastChatBlockDate (state, payload) {
      state.lastChatBlockDate = payload
    },
    ticketMessages (state) {
      state.ticketMessages.unshift(message)
      return state.ticketMessages
    },
    setChatNewMessage (state, payload) {
      state.isChatNewMessage = payload
    }
  },
  actions: {
    async sendRightsViolation ({ state, commit, dispatch }, payload) {
      try {
        const { data } = await ApiClient.post(help.rights, { ...payload })
        dispatch('notification/setMessage', {
          value: data.message,
          type: 'success'
        }, { root: true })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.response.data.error.message)
      }
    },
    async sendTicketMessage ({ commit, dispatch }, payload) {
      try {
        const formData = new FormData()
        const body = {
          content: payload.content ?? null
        }
        if (payload.img) {
          formData.append('img', payload.img)
        }
        formData.append('data', JSON.stringify(body))
        await ApiClient.post(help.sendMessage, formData)
        dispatch('loadTicketMessages', {})
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async sendRating ({ commit, dispatch }, payload) {
      try {
        const body = {
          rating: payload.rating
        }
        await ApiClient.put(help.sendRating(payload.ticketId), body)
        dispatch('loadTicketMessages', {})
        dispatch('notification/setMessage', {
          value: 'Спасибо за Ваш отзыв!',
          type: 'success'
        }, { root: true })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadTicketMessages ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          search: payload.search ?? null
        }
        const { data } = await ApiClient.get(help.messages, { params })
        commit('setTicketMessages', data.data)
        bus.emit({ event: ALL_MESSAGES_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    },
    async loadUserTickets ({ commit, dispatch }, payload) {
      try {
        const params = {
          name: 'DESC',
          search: payload.search ?? null
        }
        const { data } = await ApiClient.get(help.tickets, { params })
        commit('setTickets', data.data)
        bus.emit({ event: ALL_MESSAGES_LOADED })
      } catch (e) {
        dispatch('notification/setMessage', {
          value: e.response.data.message,
          type: 'danger'
        }, { root: true })
        throw new Error(e.message)
      }
    }
  }
}
