<template>
  <div class="controls__active_sounds">
    <img @click="isOpen = !isOpen" :src="defineEq()" alt="">
    <Transition>
      <div v-show="isOpen" class="sounds">
        <div class="sounds__title">Управление активными звуками<span @click="isOpen = !isOpen" class="close"></span></div>
        <div class="cap" v-if="activeSounds.length === 0">Нет активных звуков</div>
        <div class="sounds__items scrollable scrollbar">
          <div
            v-for="item in activeSounds" :key="item.sound.id"
            class="sounds__items_item"
          >
            <img class="icon" src="@/assets/img/icon/player/ic_headphone.svg" alt="">
            <div class="title"><div class="title__value">{{ item.sound.title }}</div></div>
            <AppSlider
              class="volume"
              color="primary"
              :id="item.sound.id"
              v-model="item.sound.volume"
            />
            <img @click="stopSound(item.sound)" class="remove" src="@/assets/img/icon/ic_close_gray.svg" alt="">
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import AppSlider from '@/components/ui/slider/AppSlider'
import SoundPlayerFactory from '@/modules/sound/use/soundPlayer'

export default {
  components: { AppSlider },
  setup () {
    const store = useStore()
    const soundPlayer = SoundPlayerFactory.getInstance()

    const activeSounds = computed(() => store.getters['sound/activeSounds'])
    const isOpen = ref(false)

    return {
      isOpen,
      activeSounds,
      stopSound: (sound) => {
        sound = { sound, isPlaying: true, volume: sound.volume, howl: null }
        store.commit('sound/setActiveSound', sound)
        soundPlayer.pause(sound)
      },
      defineEq: () => {
        if (isOpen.value) {
          return require('@/assets/img/icon/player/ic_eq_active.svg')
        } else {
          return require('@/assets/img/icon/player/ic_eq.svg')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/theme.scss';

  .controls__active_sounds {
    position: relative;
    user-select: none;
    img {
      width: 2.4rem;
      height: 2.4rem;
      -webkit-user-drag: none;
      &:hover {
        cursor: pointer;
        opacity: .7;
      }
      &:active {
        cursor: pointer;
        opacity: .5;
      }
    }
    & .sounds {
      position: absolute;
      right: 0;
      bottom: 3.4rem;
      width: 36.2rem;
      height: 30rem;
      box-shadow: $shadow;
      background-color: $selected_dd;
      padding: 2rem .8rem 2rem 2rem;
      border-radius: $radius;
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: $white-text;
        .close {
          margin-right: 1.4rem;
          @include close;
        }
      }
      .cap {
        font-size: 1.2rem;
        color: $second-text-2;
        font-weight: 500;
        margin-top: 2.1rem;
        opacity: .7;
      }
      &__items {
        margin-top: 2.1rem;
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        max-height: 22rem;
        min-height: 22rem;
        @include scrollbar;
        &_item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & .title {
            margin-left: .8rem;
            color: $white-text;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.8rem;
            width: 16.4rem;
            overflow: hidden;
            margin-right: .8rem;
            &__value {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          & .icon {
            width: 2.4rem;
            height: 2.4rem;
            -webkit-user-drag: none;
          }
          & .remove {
            margin-left: 1.1rem;
            margin-right: 1.1rem;
            width: 1.8rem;
            height: 1.8rem;
            flex: 0;
            @include default-hover-active;
          }
          & .volume {
            width: 8.8rem;
          }
        }
      }
    }
  }
</style>
